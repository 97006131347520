import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {DevExpressProviders} from "../../_services/devexpress.providers";
import {servers} from "../../shared/configuration";
import {DyehouseProviders} from "../../_services/dyehouse.providers";
import {CheckBarcodeDTO} from "../../_models/CheckBarcodeDTO";
import {DryVehicleModel} from "../../_models/dyehouse/dryVehicle.model";
import {DryVechileDTOModel} from "../../_models/dyehouse/DryVechileDTO.model";

@Component({
  selector: 'app-before-dyehouse',
  templateUrl: './before-dyehouse.component.html',
  styleUrls: ['./before-dyehouse.component.scss']
})
export class BeforeDyehouseComponent implements OnInit {
  @ViewChild('inp') private inp: ElementRef;

  checkBarcode: CheckBarcodeDTO = new CheckBarcodeDTO();
  dry: DryVechileDTOModel = new DryVechileDTOModel();
  popupVisible: boolean;

  constructor(
    private dyeApi: DyehouseProviders
  ) {
  }

  BarcodeKaydet(): void {
    this.dyeApi.checkBarcodeForBefore(this.checkBarcode).subscribe(x => {
      this.dry = x;
      this.checkBarcode = new CheckBarcodeDTO();
    });
  }

  ngOnInit() {
    this.inp.nativeElement.focus();
  }

  onKeydown($event: KeyboardEvent) {
    // @ts-ignore
    if (event.key === "Enter") {
      this.BarcodeKaydet();
    }
  }

  openPopUp(): void {
    this.popupVisible = true;
  }

  afterClosePop(e: boolean): void {
    this.popupVisible = e;
  }
}
