/--BASE API URL TUTULACAK --/;
export const servers = {
  postmanMock: 'https://d48b8626-bed7-4bb1-a9ac-3207b967b945.mock.pstmn.io',
  test: 'https://18b5ef35-5c1a-4dca-a17e-2de7ad1a979d.mock.pstmn.io',

  meters: 'http://192.168.1.119:8000',
  //meters: 'http://192.168.1.67:8000',

  // real: 'http://192.168.1.34:44302/api', //** CANLI
  real: 'https://pakdemoapi.atasayarservis.com/api', //** DEV
  // real: 'http://localhost:5004/api' //** DEV
}
