import {ChangeDetectionStrategy, Component, enableProdMode, OnInit, ViewChild} from '@angular/core';
import {DxDataGridComponent, DxDataGridModule} from 'devextreme-angular';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import {DevExpressProviders} from "../../_services/devexpress.providers";
import {endpoints} from "../../shared/endpoints";
import * as ExcelJS from "exceljs/dist/exceljs.min.js";
import saveAs from 'file-saver';
import {CellRange, exportDataGrid} from 'devextreme/excel_exporter'
import {IdNameModel} from "../../_models/idNameModel";
import {PackageProviders} from "../../_services/package.providers";

import * as XLSX from 'xlsx';
import {ReportSackModel} from "../../_models/reportSackModel";
import {DatePipe} from "@angular/common";
import {ToastrService} from "ngx-toastr";
import {User} from "../../_models";
import {BehaviorSubject} from "rxjs";
import {MsgResponseDTO} from "../../_models/DTO/MsgResponseDTO";


if (!/localhost/.test(document.location.host)) {
  enableProdMode();
}

@Component({
  selector: 'app-dispatch-raport',
  templateUrl: './dispatch-raport.component.html',
  styleUrls: ['./dispatch-raport.component.scss'],
  providers: [DatePipe]

})
export class DispatchRaportComponent implements OnInit {
  models: ReportSackModel[] = [];

  // @ts-ignore
  @ViewChild('gridContainer', {static: false}) priceDataGrid: DxDataGridComponent;
  dataSource: any;
  reportDate: string = new Date().toString();
  staticSource: any;
  companies: IdNameModel[] = [];

  filterValue: Array<any>;
  fileName = '';
  arr: string[] = [];

  constructor(private devApi: DevExpressProviders,
              private packageProviders: PackageProviders,
              private datePipe: DatePipe, private toastr: ToastrService,) {
    this.reportDate = this.datePipe.transform(this.reportDate, 'yyyy-MM-dd');
    this.fileName = 'Pak-Ekizceli Sevkiyat Raporu' + '.xlsx';
  }


  exportexcel2(): void {
    this.packageProviders.readSackPackageByCompanyAndDate(this.reportDate).subscribe(veri => {
      // @ts-ignore
      if (veri.row.length == 0) {

        this.toastr.error('Belirtmiş olduğunuz güne ait herhangi bir sevkiyat bulunamamıştır', 'HATA', {
          timeOut: 3000
        });
      } else {
        // @ts-ignore
        veri.row.forEach(element => {
          if (this.arr.find(x => x == element.firmaNo) == undefined) {
            this.arr.push(element.firmaNo);
          }
        })
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        this.arr.forEach(element => {
          let s: ReportSackModel[] = [];
          // @ts-ignore
          s = veri.row.filter(a => a.firmaNo == element);
          let sheetName = s[0].firma.substring(0, 30);
          // @ts-ignore
          let totalSum = veri.totalSum.filter(a => a.firmaNo == element);
          totalSum.forEach(a => {
            let newS: ReportSackModel = new ReportSackModel();
            newS.pattern = "Toplam Metre : " + a.metre.toFixed(2) + " Mt , Ağırlık : " + a.weight + " , Kupon Sayısı : " + a.cuponCount
            newS.firmaNo = a.firmaNo;
            s.push(newS)
          })
          // @ts-ignore
          let sacSum = veri.sacSum.filter(a => a.firmaNo == element);
          sacSum.forEach(a => {
            let newS: ReportSackModel = new ReportSackModel();
            newS.pattern = "Çuval " + a.sackNo + " (Toplam Metre : " + a.metre.toFixed(2) + " Mt , Ağırlık : " + a.weight + " , Kupon Sayısı : " + a.cuponCount + ")"
            newS.firmaNo = a.firmaNo;
            s.push(newS)
          })

          // @ts-ignore
          let patternSum = veri.patternSum.filter(a => a.firmaNo == element);
          let ne: ReportSackModel = new ReportSackModel();
          ne.pattern = "DESEN - METRE - AĞIRLIK - KUPON SAYISI";
          ne.firmaNo = element;
          s.push(ne);
          patternSum.forEach(a => {
            let newS: ReportSackModel = new ReportSackModel();
            newS.pattern = a.pattern;
            newS.meters = a.metre.toFixed(2);
            newS.weight = a.weight;
            newS.cuponCount = a.cuponCount;
            newS.firmaNo = a.firmaNo;
            s.push(newS)
          })
          s.forEach(
            e => {
              delete e.firmaNo;
              delete e.firma;
              delete e.siparisNo;
              delete e.siparisTarihi;
              delete e.createTime;
              delete e.sendOrderId;
              delete e.id;
              delete e.uIq;
            }
          )
          let sonVeri: any[] = [];
          s.forEach(a =>
            sonVeri.push({
              'Çuval No': a.sackNo,
              'Desen': a.pattern,
              'Metraj': a.meters,
              'Ağırlık': a.weight,
              'Kupon Sayısı': a.cuponCount
            })
          )
          let ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(sonVeri)
          XLSX.utils.book_append_sheet(wb, ws, sheetName);

        });
        XLSX.writeFile(wb, this.fileName);
      }


    });
  }


  async exportexcel(): Promise<void> {
    this.packageProviders.readSackPackageByCompanyAndDate(this.reportDate).subscribe(async veri => {
      // console.log(veri);

      const msg: MsgResponseDTO = await this.packageProviders
        .softSettingsByDes("Sevk raporunda çuval toplamları hesaplansın mı ?").toPromise()

      // @ts-ignore
      const wb: XLSX.WorkBook = XLSX.utils.book_new();


      let final: any[] = [];
      veri.forEach(data => {
        let sheetName = data.companyName.substring(0, 30);

        data.sack.forEach(async sacks => {
          final.push({
            'Desen':
              'Çuval Numarası  - ' + sacks.sackNo +
              ' | Kupon Sayısı - ' + sacks.packageCount +
              ' | Metre - ' + sacks.sackMeter +
              ' | Agirlik - ' + sacks.weight +
              ' | Cuval Kimligi - ' + sacks.sackId
          })

          sacks.cupons.forEach(cups => {
            final.push({
              'Desen': cups.pattern,
              'Metre': cups.meters,
              'Kupon Sayisi': cups.count,
            });
          })
          if (msg.msg == "Evet") {

            final.push({'Desen': '                                          Çuval toplamı'})
            final.push({
              'Metre': 'Desen',
              'Kupon Sayisi': 'Metre',
              '-': 'Kupon Sayısı'
            })
            sacks.cuponSummaries.forEach(cupSum => {
              final.push({
                'Metre': cupSum.pattern,
                'Kupon Sayisi': cupSum.sum,
                '-': cupSum.count
              })
            })
          }
          final.push({'Desen': '---------------------------------------------------------------------------------------------------------'})
        })

        final.push({'Desen': '------------------------------------------ Toplamlar -------------------------------------------------'})
        final.push({
          'Metre': 'Desen',
          'Kupon Sayisi': 'Metre',
          '-': 'Kupon Sayısı'
        })
        data.totalSummary.forEach(total => {
          final.push({
            'Metre': total.pattern,
            'Kupon Sayisi': total.sum,
            '-': total.count
          })
        })

        final.push({'Kupon Sayisi': '-------------------Toplam--------------------'})
        final.push({
          'Kupon Sayisi': data.finalSum.meterSum,
          '-': data.finalSum.cuponsum
        })

        let ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(final)
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
        final = [];
      })
      XLSX.writeFile(wb, this.fileName);
      // if (veri.length == 0) {
      //
      //   this.toastr.error('Belirtmiş olduğunuz güne ait herhangi bir sevkiyat bulunamamıştır', 'HATA', {
      //     timeOut: 3000
      //   });
      // } else {
      //   // @ts-ignore
      //   veri.row.forEach(element => {
      //     if (this.arr.find(x => x == element.firmaNo) == undefined) {
      //       this.arr.push(element.firmaNo);
      //     }
      //   })
      //   const wb: XLSX.WorkBook = XLSX.utils.book_new();
      //   this.arr.forEach(element => {
      //     let s: ReportSackModel[] = [];
      //     // @ts-ignore
      //     s = veri.row.filter(a => a.firmaNo == element);
      //     let sheetName = s[0].firma.substring(0, 30);
      //     // @ts-ignore
      //     let totalSum = veri.totalSum.filter(a => a.firmaNo == element);
      //     totalSum.forEach(a => {
      //       let newS: ReportSackModel = new ReportSackModel();
      //       newS.pattern = "Toplam Metre : " + a.metre.toFixed(2) + " Mt , Ağırlık : " + a.weight + " , Kupon Sayısı : " + a.cuponCount
      //       newS.firmaNo = a.firmaNo;
      //       s.push(newS)
      //     })
      //     // @ts-ignore
      //     let sacSum = veri.sacSum.filter(a => a.firmaNo == element);
      //     sacSum.forEach(a => {
      //       let newS: ReportSackModel = new ReportSackModel();
      //       newS.pattern = "Çuval " + a.sackNo + " (Toplam Metre : " + a.metre.toFixed(2) + " Mt , Ağırlık : " + a.weight + " , Kupon Sayısı : " + a.cuponCount + ")"
      //       newS.firmaNo = a.firmaNo;
      //       s.push(newS)
      //     })
      //     console.log("*********1")
      //
      //     // @ts-ignore
      //     let patternSum = veri.patternSum.filter(a => a.firmaNo == element);
      //     let ne: ReportSackModel = new ReportSackModel();
      //     ne.pattern = "DESEN - METRE - AĞIRLIK - KUPON SAYISI";
      //     ne.firmaNo = element;
      //     s.push(ne);
      //     patternSum.forEach(a => {
      //       console.log("*********2")
      //       let newS: ReportSackModel = new ReportSackModel();
      //       newS.pattern = a.pattern;
      //       newS.meters = a.metre.toFixed(2);
      //       newS.weight = a.weight;
      //       newS.cuponCount = a.cuponCount;
      //       newS.firmaNo = a.firmaNo;
      //       s.push(newS)
      //     })
      //     s.forEach(
      //       e => {
      //         delete e.firmaNo;
      //         delete e.firma;
      //         delete e.siparisNo;
      //         delete e.siparisTarihi;
      //         delete e.createTime;
      //         delete e.sendOrderId;
      //         delete e.id;
      //         delete e.uIq;
      //       }
      //     )
      //     let sonVeri: any[] = [];
      //     s.forEach(a =>
      //       sonVeri.push({
      //         'Çuval No': a.sackNo,
      //         'Desen': a.pattern,
      //         'Metraj': a.meters,
      //         'Ağırlık': a.weight,
      //         'Kupon Sayısı': a.cuponCount
      //       })
      //     )
      //     let ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(sonVeri)
      //     XLSX.utils.book_append_sheet(wb, ws, sheetName);
      //
      //   });
      //   XLSX.writeFile(wb, this.fileName);
      // }


    });
  }

  ngOnInit() {
    var currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));


    this.dataSource = this.devApi.readForDevX('id', endpoints.report.getSackRaport.path + '/' + currentUserSubject.value.role);
  }

  onExporting(e) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('CountriesPopulation');
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      topLeftCell: {row: 2, column: 1}
    }).then(function (dataGridRange) {
      const headerRow = worksheet.getRow(1);
      worksheet.mergeCells(1, 1, 1, 10);
      headerRow.getCell(1).value = 'Ekizceli Sevk Raporu';
      headerRow.getCell(1).font = {name: 'Segoe UI Light', size: 22};
      headerRow.getCell(1).alignment = {horizontal: 'center'};
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(new Blob([buffer], {type: 'application/octet-stream'}), 'PakEkizceliRapor.xlsx');
      });
    });
  }


}
