import {Component, OnInit, ViewChild} from '@angular/core';
import {DevExpressProviders} from "../_services/devexpress.providers";
import {endpoints} from "../shared/endpoints";
import {exportDataGrid} from "devextreme/excel_exporter";
import {DxDataGridComponent} from "devextreme-angular";
import * as ExcelJS from "exceljs/dist/exceljs.min.js";
import saveAs from 'file-saver';
import {BehaviorSubject} from "rxjs";
import {User} from "../_models";

@Component({
  selector: 'app-cupons-by-customers-report',
  templateUrl: './cupons-by-customers-report.component.html',
  styleUrls: ['./cupons-by-customers-report.component.scss']
})
export class CuponsByCustomersReportComponent implements OnInit {
  @ViewChild('targetDataGrid') dataGrid: DxDataGridComponent;

  searchTimeoutOption = 1000;
  searchModeOption: string = "contains";
  searchExprOption: string = "name";
  selectDataSource: any;
  dataSource: any;

  constructor(private devApi: DevExpressProviders) {
  }

  ngOnInit() {
    var currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));

    this.selectDataSource = this.devApi.readForDevX('', endpoints.clients.getAllCustomers.path+'/'+currentUserSubject.value.role);
  }

  getReport(a: any) {
    this.dataSource = this.devApi.readForDevX(
      '', endpoints.report.CuponsByCustomersReport.path + '/' + a.value
    );
  }

  onToolbarPreparing(event) {
    event.toolbarOptions.items.unshift({},
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'fas fa-filter',
          title: 'Filtre Sıfırla',
          onClick: this.clearSearchPanel.bind(this)
        }
      });
  }
  clearSearchPanel() {
    this.dataGrid.instance.clearFilter();
  }

  onExporting(e) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('CountriesPopulation');
    exportDataGrid({ component: e.component, worksheet: worksheet, topLeftCell: { row: 2, column: 1 } }).then(function (dataGridRange) { const headerRow = worksheet.getRow(1); worksheet.mergeCells(1, 1, 1, 10); headerRow.getCell(1).value = 'Pak-Ekizceli Boyahane Raporu 2'; headerRow.getCell(1).font = { name: 'Segoe UI Light', size: 22 }; headerRow.getCell(1).alignment = { horizontal: 'center' };  }).then(function () { workbook.xlsx.writeBuffer().then(function (buffer) { saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'PakEkizceliRapor.xlsx'); }); });
  }

}
