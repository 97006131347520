import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {PackageProviders} from 'src/app/_services/package.providers';
import {ToastrService} from 'ngx-toastr';
import {FormBuilder} from '@angular/forms';
import {Client} from 'src/app/_models/client.model';
import {OrderDetail} from 'src/app/_models/order-detail.model';
import {subOrder, Meter} from 'src/app/_models/sub-order.model';
import {Package, PackageDetailModel, SubDetail} from '../../_models/package.model';
import {PrintProviders} from '../../_services/print.service';
import {Barcode} from "../../_models/dyehouse/outgoingBarcode.model";
import 'rxjs/add/observable/interval'
import {Observable} from "rxjs";
import {MatDialog} from '@angular/material';
import {LoadingComponent} from 'src/app/components/loading/loading.component';
import {assertNumber} from "@angular/core/src/render3/assert";
import {getSortHeaderNotContainedWithinSortError} from "@angular/material/sort/typings/sort-errors";
import {ReadMeters} from "../../_models/readMeters";
import {CheckMeterDTO} from "../../_models/checkMeterDTO";

@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.scss']
})
export class PackageComponent implements OnInit {
  @ViewChild('search') searchElement: ElementRef;
  //#region :: define value
  clients: Client[] = [];
  subOrders: subOrder[] = [];
  packageArea: subOrder[] = [];
  packageModel: Package = new Package();
  packageSubDetailModel: SubDetail = new SubDetail();
  packageTestModel: SubDetail = new SubDetail();
  packageSubDetailModelArray: SubDetail[] = [];
  packageDetailModel: PackageDetailModel = new PackageDetailModel();
  descArea = '';
  barcodeString: string;
  meterType: Meter = new Meter();
  backupMeter: string;
  isShowDetail = false;
  barcodeArray: Barcode[] = [];
  barcode: Barcode = new Barcode();
  getDataByService: any;
  meters: any;
  metersArea: number;
  extraMetersArea: number;
  readMeter: ReadMeters = new ReadMeters();
  focusStatus = true;
  private checkMeterControl: boolean;
  private checkMeter: CheckMeterDTO = new CheckMeterDTO();
  private cuponType = "";

// Ek Metre
  popAdditionalMeter: boolean = false;
  additionalMeter: any = 0;

  //#endregion
  private popupVisible: boolean;

  constructor(
    private fb: FormBuilder,
    private api: PackageProviders,
    private toastr: ToastrService,
    private printApi: PrintProviders,
    public dialog: MatDialog,
  ) {
  }

  timerId;

  async ngOnInit() {
    this.getDevice().then(r => {
      this.timerId = setInterval(() => this.getMeters(), 1000);
    });

  }

  ngOnDestroy() {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  }

  openPopUp(): void {
    this.popupVisible = true;
  }

  afterClosePop(e: boolean): void {
    this.popupVisible = e;
  }

  /**
   *Her barkod okutulduğunda koşullara uygun olup olmadığını kontrol ediyor
   * @param event
   */

  keyDownFunction(event) {
    this.barcode = new Barcode();
    const barcode = this.searchElement.nativeElement.value;

    this.api.checkCuponsForPackageComponent(barcode).subscribe(data => {
      this.getDataByService = data;
      if (this.getDataByService[0].toLocaleLowerCase() != 'ok') {
        this.toastr.warning(data, 'UYARI', {
          timeOut: 3000
        });
      }

      if (this.getDataByService[0].toLocaleLowerCase() === 'ok') {

        let addtionalPopCheck: boolean = true;

        if (this.getDataByService[1].toLocaleLowerCase() === 'cift sarım') {

          if (this.barcodeArray.length === 0) {
            this.cuponType = 'Cift Sarım';
            this.barcode.BarcodeDetail = barcode;
            this.barcodeArray.push(this.barcode);
            this.packageSubDetailModel.id = +this.getDataByService[2];
            // this.packageSubDetailModel.additionalMeters = this.additionalMeter;
            this.packageSubDetailModelArray.push(this.packageSubDetailModel);
            this.searchElement.nativeElement.value = '';
          } else if (this.barcodeArray.length === 1) {

            if (barcode === this.barcodeArray[0].BarcodeDetail) {
              this.toastr.warning('Bu kupon daha önceden okutulmuştur.', 'UYARI', {
                timeOut: 3000
              });
              this.searchElement.nativeElement.value = '';
              addtionalPopCheck = false;
            } else {
              this.barcode.BarcodeDetail = barcode;
              this.barcodeArray.push(this.barcode);
              this.packageTestModel.id = +this.getDataByService[2];
              // this.packageTestModel.additionalMeters = this.additionalMeter;
              this.packageSubDetailModelArray.push(this.packageTestModel);
              this.searchElement.nativeElement.value = '';

            }

          } else if (this.barcodeArray.length > 1) {
            this.toastr.warning('Çift sarımlarda maksimum iki barkod okutulabilir', 'UYARI', {
              timeOut: 3000
            });
            this.searchElement.nativeElement.value = '';
            addtionalPopCheck = false;
          }

        } else {
          if (this.barcodeArray.length === 0) {
            this.cuponType = 'Tek Sarim'
            this.barcode.BarcodeDetail = barcode;
            this.barcodeArray.push(this.barcode);
            this.packageSubDetailModel.id = +this.getDataByService[2];
            // this.packageSubDetailModel.additionalMeters = this.additionalMeter;
            this.packageSubDetailModelArray.push(this.packageSubDetailModel);
            this.searchElement.nativeElement.value = '';

          } else {
            this.toastr.warning('Tek sarımlarda maksimum bir barkod okutulabilir', 'UYARI', {
              timeOut: 3000
            });
            this.searchElement.nativeElement.value = '';
            addtionalPopCheck = false;
          }

        }
        // (this.cuponType == 'Tek Sarim' && this.barcodeArray.length === 0)
        // ||
        if (addtionalPopCheck)
          if (this.getDataByService[3] == "true")
            this.popAdditionalMeter = true;


      } else {
        this.searchElement.nativeElement.value = '';
      }
    });


  }


  // apiden max min girilebilecek metre kontrol ediliyor
  // dönğüş türleri şu şekilde : 1 büyük girildi , -1 küçük girildi , 0 sorun yok


  async checkMeterfunc(cuponType: string): Promise<number> {
    await this.api.getCheckMeter(cuponType).then(data => {
      this.checkMeter = data
    });

    if (this.checkMeter.max === 0) {
      return 0;
    }

    if (this.packageModel.meter > this.checkMeter.max) {
      this.toastr.error(
        'Maxsimum girebileceginiz metrenin üstünde giriş yaptınız.Maximum girebileceginiz metre:' + this.checkMeter.max,
        'Metre Uyarısı', {timeOut: 4000})
      return 1;
    } else if (this.packageModel.meter < this.checkMeter.min) {
      this.toastr.error(
        'Maxsimum girebileceginiz metrenin altında giriş yaptınız.Minimum girebileceginiz metre:' + this.checkMeter.min,
        'Metre Uyarısı', {timeOut: 4000})
      return -1;
    } else {
      return 0;
    }
  }

  /***
   * Paket oluştur butonuna basınca çalışan fonksiyon
   */
  async clickCreatePackage(): Promise<void> {
    if (this.metersArea == undefined) this.metersArea = 0;
    if (this.extraMetersArea == undefined) this.extraMetersArea = 0;

    if (this.metersArea + this.extraMetersArea != 0 && this.barcodeArray.length >= 1) {
      await this.setPackageModelForAPI();
      if (await this.checkMeterfunc(this.cuponType) !== 0) {
        return;
      }
      await this.createPackagesAPI();

    } else {
      this.toastr.warning('Gerekli Alanları Doldurmalısınız!', 'Hata', {
        timeOut: 3000
      });
    }
    setTimeout(() => {
      this.searchElement.nativeElement.focus();
    }, 300);
  }


  /***
   * createPackagesAPI servisine gönderilecek modeli doldurur
   */
  async setPackageModelForAPI() {
    this.packageModel = new Package();
    this.packageModel.definition = this.descArea;
    this.metersArea = +(this.metersArea.toString().replace(',', '.'));
    this.packageModel.meter = 0;
    if (this.extraMetersArea != undefined && this.extraMetersArea != 0 && this.extraMetersArea != 0) {
      this.extraMetersArea = +(this.extraMetersArea.toString().replace(',', '.'))
      this.packageModel.meter = +(this.metersArea + this.extraMetersArea).toFixed(2);
    } else {
      this.packageModel.meter = this.metersArea;
    }
    this.barcodeString = 'data:image/jpeg;base64,';
    this.packageModel.orderSubDetails = this.packageSubDetailModelArray;
  }

  /***
   * createPackageAPI servisi çağırılır. Dönüş verisi olarak paket detayını alır.
   */
  async createPackagesAPI() {
    this.showLoading();

    this.api.createPackagesAPI(this.packageModel).then(data => {
      if (data != "x") {
        this.isShowDetail = true; // kupon detayı ve barkod yazdırı göstermek için tutuluyor.
        this.packageDetailModel = data;
        this.barcodeString = this.barcodeString + this.packageDetailModel.arr;

        this.descArea = '';
        // this.metersArea = 0;
        this.extraMetersArea = 0;

        this.toastr.success('Paketleme işlemi başarı gerçekleştirilmiştir', 'BAŞARILI', {
          timeOut: 1000
        });
        this.packageModel.orderSubDetails.forEach((x) => {
          x.additionalMeters = "0";
        })
        this.packageModel.orderSubDetails.splice(0);
        this.packageModel = null;
        this.packageArea.splice(0);
        this.packageSubDetailModelArray.forEach((x) => {
          x.additionalMeters = "0";
        })
        this.packageSubDetailModelArray.splice(0);
        this.barcodeArray.splice(0)
        console.log("iç")
        console.log(this.packageArea)
        console.log(this.packageSubDetailModelArray)
      }
    });
    await this.closeLoading();
    this.searchElement.nativeElement.focus();
    console.log("dış")
    console.log(this.packageArea)
    console.log(this.packageSubDetailModelArray)
  }

  /***
   * Ek Metre Alanı.
   */
  addtionalMeterSaveButton(): void {
    console.log('this.additionalMeter')
    console.log(this.additionalMeter)
    if (this.additionalMeter == 0) {
      this.toastr.error('Metre girilmesi zorunldur.')
    } else {
      // this.packageSubDetailModelArray
      // .filter(x => x.id === +(this.getDataByService[2]))[0].additionalMeters = this.additionalMeter;
      this.packageSubDetailModelArray[this.packageSubDetailModelArray.length - 1].additionalMeters
        = this.additionalMeter;

      this.popAdditionalMeter = false;
      this.additionalMeter = 0;
    }
  }

  /***
   * Paketleme alanından elemanı kaldırır. kupon lsitesine geri ekler
   */
  removeOnPackageArea(pArea) {
    // start :paketleme alanına eklenen verileri  kaldırır
    const index = this.barcodeArray.indexOf(pArea, 0);
    if (index > -1) {
      this.barcodeArray.splice(index, 1);
      this.packageSubDetailModelArray.splice(index, 1);
    }
  }

  printBarcode() {
    // this.printApi.printBarcode('printBarcode');
    this.api.printPackageAPI(this.packageDetailModel).subscribe(data => {
    });

    this.searchElement.nativeElement.focus();
  }


  async getDevice() {
    this.readMeter = await this.api.getReadMeters();
  }

  async getMeters() {
    let finalMeter: number = 0;
    // @ts-ignore
    this.api.getMeters(this.readMeter.ip + ":" + this.readMeter.port).subscribe(data => {

      this.meterType = data
      var meter = parseFloat(this.meterType.meter).toFixed(2);

      let parts: string[] = meter.split('.')
      let decimalPart: number = +parts[parts.length - 1].substr(1, 1);

      if (decimalPart < 5)
        finalMeter = +(parts[0] + '.' + parts[parts.length - 1].substr(0, 1) + '0');

      else if (decimalPart > 5) {
        let deci: number = +(parts[1]) + (10 - decimalPart);
        if (deci == 100) {
          deci = 0;
          parts[0] = (+(parts[0]) + 1).toString();
          finalMeter = +(+(parts[0]) + '.' + deci);
        } else
          finalMeter = +(+(parts[0]) + '.' + deci);
      } else if (decimalPart == 5)
        finalMeter = +meter;
      this.metersArea = finalMeter;
    })


  }

  showLoading() {
    const dialogRef = this.dialog.open(LoadingComponent, {
      disableClose: true,
      width: '500px',
      data: {}
    });
  }

  async closeLoading() {
    this.dialog.closeAll();
    this.searchElement.nativeElement.focus();
  }
}
