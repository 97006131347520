import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { ROUTING } from "../shared/routing";
import { endpoints } from '../shared/endpoints';
import { ToastrService } from 'ngx-toastr';
import { Barcode } from '../_models/dyehouse/barcode.model';
import { throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ControlDotService {
  header: HttpHeaders;
  private currentUserSubject;

  constructor(
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.setHeader();
  }

  setHeader() {
    this.currentUserSubject = (JSON.parse(localStorage.getItem('currentUser')));
    if (this.currentUserSubject) {
      this.header = new HttpHeaders()
        .set('Authorization', `Bearer ${(this.currentUserSubject.token)}`)
        .set('Content-Type', 'application/json');
    } else {
      this.router.navigateByUrl(ROUTING.LOGIN);
    }
  }

  firstControlDotValidBarcode(barcode) {
    return this.http.get(endpoints.controlDot.checkCuponForCheckPoint1.path, {
      headers: this.header,
      params: { barcode: barcode }
    }).pipe(
      map((data: string) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('getVehicle hata!');
      })
    );
  }

  firstControlDotPostBarcode(barcodes: number[]): Observable<any> {
    return this.http.post<number[]>(
      endpoints.controlDot.UpdateCuponsCheckPoint1.path,
      barcodes,
      { headers: this.header }
    ).pipe(
      catchError(error => {
        this.toastr.error(error, 'Hata!', {
          timeOut: 3000
        });
        return throwError('addNewTicket hata!');
      })
    );
  }
}
