import {Component, OnInit, ViewChild} from '@angular/core';
import {DevExpressProviders} from "../../_services/devexpress.providers";
import {endpoints} from "../../shared/endpoints";
import {servers} from "../../shared/configuration";
import {DxiColumnComponent} from "devextreme-angular/ui/nested";

@Component({
  selector: 'app-vehicle-algorithm',
  templateUrl: './vehicle-algorithm.component.html',
  styleUrls: ['./vehicle-algorithm.component.scss']
})
export class VehicleAlgorithmComponent implements OnInit {
  @ViewChild('abc') abc: DxiColumnComponent;
  dataSource : any;
  startEditAction = 'click';
  selectTextOnEditStart = true;
  floorSource: any;
  altFloorSource: any;
  colorSource: any;
  floorLengthSource: any;
  mahicneMeterSource: any;

  constructor(
    private devApi : DevExpressProviders
  ) {
    this.dataSource = this.devApi.readForDevX('id',servers.real+'/vehicleAlgorithm');
    this.floorSource = this.devApi.readForDevX('id', servers.real+'/vehicleAlgorithm/getFloor');
    this.altFloorSource = this.devApi.readForDevX('id', servers.real+'/vehicleAlgorithm/getAltFloor');
    this.colorSource = this.devApi.readForDevX('id', servers.real+'/vehicleAlgorithm/getColor');
    this.floorLengthSource = this.devApi.readForDevX('id', servers.real+'/vehicleAlgorithm/getFloorLength');
    this.mahicneMeterSource = this.devApi.readForDevX('id', servers.real+'/vehicleAlgorithm/getMachineMeter');
  }

  ngOnInit() :void{

  }
}
