import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';

declare let pdfMake: any;

@Injectable()
export class PrintProviders {
  constructor() { }
  printBarcode(elementId) {
    const printContent = document.getElementById(elementId);
    const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
    WindowPrt.document.write(printContent.innerHTML);
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();

    //WindowPrt.close();
  }
}
