import {Component, OnInit, ViewChild} from '@angular/core';
import {DevExpressProviders} from "../../_services/devexpress.providers";
import {endpoints} from "../../shared/endpoints";
import {exportDataGrid} from "devextreme/excel_exporter";
import * as ExcelJS from "exceljs/dist/exceljs.min.js";
import saveAs from 'file-saver';
import {DxDataGridComponent} from "devextreme-angular/ui/data-grid";

@Component({
  selector: 'app-dyehouse-missings-report',
  templateUrl: './dyehouse-missings-report.component.html',
  styleUrls: ['./dyehouse-missings-report.component.scss']
})
export class DyehouseMissingsReportComponent implements OnInit {
  @ViewChild('targetDataGrid') dataGrid: DxDataGridComponent;
  private dataSource: any;

  constructor(private devApi: DevExpressProviders) {
  }

  ngOnInit() {
    this.dataSource = this.devApi.readForDevX('', endpoints.report.getOutgoinMissingReport.path);
  }

  onExporting(e) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('CountriesPopulation');
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      topLeftCell: {row: 2, column: 1}
    }).then(function (dataGridRange) {
      const headerRow = worksheet.getRow(1);
      worksheet.mergeCells(1, 1, 1, 10);
      headerRow.getCell(1).value = 'Pak-Ekizceli Boyahane Raporu';
      headerRow.getCell(1).font = {name: 'Segoe UI Light', size: 22};
      headerRow.getCell(1).alignment = {horizontal: 'center'};
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(new Blob([buffer], {type: 'application/octet-stream'}), 'PakEkizceliRapor.xlsx');
      });
    });
  }
  onToolbarPreparing(event) {
    event.toolbarOptions.items.unshift({},
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'fas fa-filter',
          title: 'Filtre Sıfırla',
          onClick: this.clearSearchPanel.bind(this)
        }
      });
  }
  clearSearchPanel() {
    this.dataGrid.instance.clearFilter();
  }
}
