export class ReportSackModel{
  sendOrderId: number;
  id: number;
  firma: string;
  firmaNo: string;
  siparisNo: string;
  siparisTarihi: Date;
  createTime: Date;
  sackNo: number;
  pattern: string;
  meters: number;
  weight: number;
  cuponCount: number;
  uIq : number;
}
