import {Component, ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';
import {CarWeight} from "../../_models/dyehouse/car-weight.model";
import {FormBuilder, FormControl} from "@angular/forms";
import {Observable} from "rxjs";
import {DyehouseProviders} from "../../_services/dyehouse.providers";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {map, startWith} from "rxjs/operators";
import {PackageProviders} from "../../_services/package.providers";
import {sackWeightModel} from "../../_models/sack-detail.model";

@Component({
  selector: 'app-sack-weight',
  templateUrl: './sack-weight.component.html',
  styleUrls: ['./sack-weight.component.scss']
})
export class SackWeightComponent implements OnInit {
  @ViewChild('search') searchElement: ElementRef;
 netWeight: any;
 sackId: number;
 sackName: string = "";
 sackModel: sackWeightModel = new sackWeightModel();

  constructor(private fb: FormBuilder,
              private api: PackageProviders,
              private toastr: ToastrService,
              private router: Router,
              private zone: NgZone) {
  }

  ngOnInit() {
    // ağırlık çekme
    // getSackByBarcode
    // UpdateSackWeight --> id ve weight
  }

  getSackFromBarcode(event) {
   this.api.getSackByBarcode(event.target.value).subscribe(data => {
     if (data) {
       this.sackModel = data;
       this.sackId = this.sackModel.id;
       this.netWeight = this.sackModel.weight;
       this.toastr.success('Çuval Bilgileri Alındı!', 'Bilgi!', {
         timeOut: 3000
       });
     }
     else {
       this.toastr.error('Çuval Bulunamadı!', 'Hata!', {
         timeOut: 3000
       });
     }

   });

  }

  onClickSubmit() {
    this.sackModel.weight = this.netWeight;
    this.api.updateSackWeight(this.sackModel.id,this.sackModel.weight).subscribe(data => {
        this.toastr.success('Çuval Ağırlığı Güncellendi!', 'Bilgi!', {
          timeOut: 3000
        });
       this.sackModel = new sackWeightModel();
       this.sackId = 0;
       this.sackName = "";
       this.netWeight = "";
    })
  }

}
