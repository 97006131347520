import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {allServiceProviders} from "../../_services/allService.provider";
import {CollectiveMethod} from "../../_models/CollectiveMethot.model";
import {endpoints} from "../../shared/endpoints";
import {DevExpressProviders} from "../../_services/devexpress.providers";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-make-second-quality-pop',
  templateUrl: './make-second-quality-pop.component.html',
  styleUrls: ['./make-second-quality-pop.component.scss'],
  providers: [allServiceProviders]
})
export class MakeSecondQualityPopComponent implements OnInit, AfterViewInit {
  @Input() popVisible: boolean;
  @Output() popupState = new EventEmitter<boolean>();
  @ViewChild('input') input: ElementRef;
  barcode: string;
  reasons: any;
  selectedReasons: any;
  msg: string='';

  constructor(private api: allServiceProviders,
              private dev: DevExpressProviders,
              private toast: ToastrService
  ) {
  }

  ngOnInit() {

  }

  ngAfterViewInit()
    :
    void {
    // if (this.input != undefined) {
    //   this.input.nativeElement.focus();
    // }
    this.reasons = this.dev.readForDevX('id', endpoints.secondQuality.Reasons.path);
  }

  async save(): Promise<void> {
    if (this.selectedReasons == null || this.selectedReasons.id == null) {
      this.toast.error('Sebep seçmek zorunludur.')
      return
    } else if (this.selectedReasons.name == 'Diğer' && (this.msg === '' || this.msg == null)) {
      this.toast.error('Diğer Seçildiginde Açıklama Zorunludur')
      return
    }
    let c = new CollectiveMethod();
    c.method = 2;
    c.path = endpoints.secondQuality.path + "?barcode=" + this.barcode + '&des=' + this.msg + '&ReasonsId=' + this.selectedReasons.id;
    c.successMessage = 'Ürünün Durumu Degiştirildi.'
    await this.api.collectiveV2(c);
    this.popupState.emit(false);
  }

  popCloseEvent() {
    this.selectedReasons=null;
    this.barcode = '';
    this.msg='';
    this.popupState.emit(false);
  }

  test(e: any) {
    this.selectedReasons = e;
  }
}
