import { Component, OnInit, ViewChild } from '@angular/core';
import { endpoints } from 'src/app/shared/endpoints';
import { DevExpressProviders } from 'src/app/_services/devexpress.providers';
import { DxDataGridComponent } from 'devextreme-angular';
import { DyehouseProviders } from 'src/app/_services/dyehouse.providers';
import * as ExcelJS from "exceljs/dist/exceljs.min.js";
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import {BehaviorSubject} from "rxjs";
import {User} from "../../_models";

@Component({
  selector: 'app-dyehouse-report-2',
  templateUrl: './dyehouse-report-2.component.html',
  styleUrls: ['./dyehouse-report-2.component.scss']
})
export class DyehouseReport2Component implements OnInit {
  @ViewChild('targetDataGrid') dataGrid: DxDataGridComponent;

  storage2: string = "storage2";
  dataSource: any;

  constructor(private devApi: DevExpressProviders) { }

  ngOnInit() {
    var currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));

    this.dataSource = this.devApi.readForDevX('', endpoints.report.getOutGoingReport2.path+'/'+currentUserSubject.value.role);
  }

  onToolbarPreparing(event) {
    event.toolbarOptions.items.unshift({},
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'fas fa-filter',
          title: 'Filtre Sıfırla',
          onClick: this.clearSearchPanel.bind(this)
        }
      });
  }

  clearSearchPanel() {
    this.dataGrid.instance.clearFilter();
  }

  onExporting(e) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('CountriesPopulation');
    exportDataGrid({ component: e.component, worksheet: worksheet, topLeftCell: { row: 2, column: 1 } }).then(function (dataGridRange) { const headerRow = worksheet.getRow(1); worksheet.mergeCells(1, 1, 1, 10); headerRow.getCell(1).value = 'Pak-Ekizceli Boyahane Raporu 2'; headerRow.getCell(1).font = { name: 'Segoe UI Light', size: 22 }; headerRow.getCell(1).alignment = { horizontal: 'center' };  }).then(function () { workbook.xlsx.writeBuffer().then(function (buffer) { saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'PakEkizceliRapor.xlsx'); }); });
  }
}
