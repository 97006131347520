import { Component, OnInit } from '@angular/core';
import {DevExpressProviders} from "../../_services/devexpress.providers";
import {servers} from "../../shared/configuration";
import {endpoints} from "../../shared/endpoints";

@Component({
  selector: 'app-sack-algorithm',
  templateUrl: './sack-algorithm.component.html',
  styleUrls: ['./sack-algorithm.component.scss']
})
export class SackAlgorithmComponent implements OnInit {
  dataSource : any;
  startEditAction = 'click';
  selectTextOnEditStart = true;
  patternSource: any;
  clientsSource: any;

  constructor(
    private devApi : DevExpressProviders
  ) {
    this.dataSource = this.devApi.readForDevX('id',endpoints.sackAlgorithm.sackAlgorithm.path);
    this.patternSource = this.devApi.readForDevX('id', endpoints.sackAlgorithm.getPattern.path);
    this.clientsSource = this.devApi.readForDevX('id', endpoints.sackAlgorithm.getClients.path);
  }

  ngOnInit() {
  }

}
