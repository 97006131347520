import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DyehouseReport2Component } from './dyehouse-report-2.component';
import { DxDataGridModule } from 'devextreme-angular';

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule
  ],
  declarations: [DyehouseReport2Component]
})
export class DyehouseReport2Module { }
