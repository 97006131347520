import { Component, OnInit } from '@angular/core';
import {DevExpressProviders} from "../../_services/devexpress.providers";
import {servers} from "../../shared/configuration";

@Component({
  selector: 'app-mobile-device-modules',
  templateUrl: './mobile-device-modules.component.html',
  styleUrls: ['./mobile-device-modules.component.scss']
})
export class MobileDeviceModulesComponent implements OnInit {

  dataSource : any;
  deviceSource : any;
  moduleSource : any;
  startEditAction: string = "click";
  selectTextOnEditStart: boolean = true;
  constructor(
    private devApi : DevExpressProviders
  ) {
    this.dataSource = this.devApi.readForDevX('id',servers.real+'/mobilDeviceModules/devx');
    this.deviceSource = this.devApi.readForDevX('id',servers.real+'/mobileDevices/devx');
    this.moduleSource = this.devApi.readForDevX('id',servers.real+'/MobilModules/devx');
  }

  ngOnInit() {
  }

}
