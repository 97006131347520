import { Component, ElementRef, OnInit, ViewChild, NgZone } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { CarWeight } from 'src/app/_models/dyehouse/car-weight.model';
import { DyehouseProviders } from 'src/app/_services/dyehouse.providers';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ROUTING } from 'src/app/shared/routing';
import { map, startWith } from "rxjs/operators";
import { Observable } from "rxjs";
import 'rxjs/add/observable/interval'

@Component({
  selector: 'app-car-weight',
  templateUrl: './car-weight.component.html',
  styleUrls: ['./car-weight.component.scss']
})
export class CarWeightComponent implements OnInit {
  @ViewChild('search') searchElement: ElementRef;
  carWeightModel: CarWeight[] = [];
  carWeight: CarWeight = new CarWeight();
  vehicleControl = new FormControl();
  netWeight: number;
  filteredVehicleName: Observable<CarWeight[]>;
  code: any;
  carName: string;

  constructor(private fb: FormBuilder,
    private apiDyehouse: DyehouseProviders,
    private toastr: ToastrService,
    private router: Router,
    private zone: NgZone) {
    this.carWeight = new CarWeight();

  }

  ngOnInit() {
    this.getVehicleWithWeights();
    setTimeout(() => {
      this.filteredVehicleName = this.vehicleControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterC(value))
      );
    }, 300)
  }


  getVehicleWithWeights() {
    this.apiDyehouse.getVehicleWeightsWithVehicle().subscribe(data => {
      this.carWeightModel = data;
    });
  }


  setFormValueToModel() {
    //this.carWeight.code = this.carWeightForm.get('carName').value;
    //this.carWeight.fullWeight = this.carWeightForm.get('carFullWeight').value;
  }

  private _filterC(value: string): CarWeight[] {
    const filterValue = value.toLowerCase();
    return this.carWeightModel.filter(option => option.code.toLowerCase().indexOf(filterValue) === 0);
  }

  onClickSubmit() {
    let myData = this.carName
    let datas;
    let bool;
    this.filteredVehicleName.subscribe(data => {
      datas = data;
    })
    datas.forEach(element => {
      if (myData == element.code) {
        bool = true
      }
    });

    if (bool && this.netWeight) {
      this.setFormValueToModel();
      this.apiDyehouse.updateNetWeight(myData, this.netWeight).subscribe(data => {
        this.getVehicleWithWeights(); // güncellenmesi için tekrar çağırdık.
        this.toastr.success('Araç ağırlığı kayıt edilmiştir.', 'BAŞARILI', {
          timeOut: 3000
        });
        this.vehicleControl.setValue("");
        this.netWeight = null;
        this.ngOnInit();
        this.zone.runOutsideAngular(() => {
          this.ngOnInit();
        })
      });
    }
    else {
      this.toastr.warning('Alanları düzgün doldurduğunuzdan emin olunuz.', 'Uyarı', {
        timeOut: 3000
      });
    }


  }

  valueValidation() {
    let myData = this.carName
    let datas;
    let bool;
    this.filteredVehicleName.subscribe(data => {
      datas = data;
    })
    datas.forEach(element => {
      if (myData == element.code) {
        bool = true
      }
    });

    if (bool != true) {
      this.vehicleControl.setValue("");
    }
  }
}
