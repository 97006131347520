import {DyehouseMissingsReportComponent} from "../dyehouse/dyehouse-missings-report/dyehouse-missings-report.component";

export enum ROUTING {
  LOGIN = 'login',
  LOGOUT = 'logout',
  FORGOT_PASSWORD = 'forgot-password',
  REQUEST_LIST = 'request-list',
  VEHICLE_ALGORITHM = 'vehicle-algorithm',
  READ_METERS = 'read-meters',
  PRINTER = 'printer',
  MOBILE_DEVICES = 'mobile-devices',
  MOBILE_DEVICE_MODULES = 'mobile-device-modules',
  CLIENT = 'client',
  SEND_DYHOUSE = 'send-dyhouse',
  BEFORE_DYEHOUSE = 'before-dyehouse',
  BEFORE_DYEHOUSE_LIST = 'before-dye-list',
  SEARCH_VEHICLE_FOR_DYEHOUSE = 'search-vehicle-for-dyehouse',
  SEND_DYE_HOUSE_READY = 'send-dye-house-ready',
  COME_BACK_TO_DYEHOUSE = 'come-back-to-dyehouse',
  FILL_THE_SACK = 'fill-the-sack',
  PACKAGE = 'package',
  CAR_WEIGHT = 'car-weight',
  ORDER_DETAIL = 'order-detail',
  DISPATCH = 'dispatch',
  DISPATCH_RAPORT = 'dispatch-raport',
  READYFOR_SHIPPING_REPORT = 'ready-for-shipping-report',
  REPORT = 'report',
  SACK_WEIGHT = 'sack-weight',
  DYEHOUSE_REPORT = 'dyehouse-report',
  DYEHOUSE_REPORT2 = 'dyehouse-report2',
  DYEHOUSE_LOG = 'dyehouse-log',
  BACK_FROM_DYEHOUSE = 'back-from-dyehouse',
  DYEHOUSE_MISSINGS_REPORT = 'dyehouse-missings-report',
  NOT_GOING_OUTGOING_REPORT = 'not-going-outgoing-report',
  IN_OUTGOIN_CUPONS_REPORT = 'in-outgoin-cupons-report',
  FIRST_CONTROL_DOT = 'first-control-dot',
  CUPONS_BY_CUSTOMERS_REPORT = 'cupons-by-customers-report',
  BOSS_SCREEN = 'boss',
  SACK_ALGORITM = "sack-algorithm",
  MAKE_SACK = "make-sack",
  CLOSE_SACK = "close-sack",
  SACKS_IN_PROGRESSS = "sacks-in-progress",
  GENERAL_RAPORT = 'general-raport',
  SECOND_QULITY_REPORT = 'second-quality-report',
  SECOND_QULITY_REPORT_DETAIL = 'second-quality-report-detail',
  MAKE_SECOND_QUALITY_SACK = 'make-second-quality-sack',
  VEHICLE_WEIGHT = 'vehicle-weight',
}
