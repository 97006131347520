import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Barcode, OutgoingBarcode } from 'src/app/_models/dyehouse/outgoingBarcode.model';
import { ControlDotService } from '../control-dot.service';

@Component({
  selector: 'app-first-control-dot',
  templateUrl: './first-control-dot.component.html',
  styleUrls: ['./first-control-dot.component.scss']
})
export class FirstControlDotComponent implements OnInit {
  @ViewChild('search') searchElement: ElementRef;
  checkedScanBarkod = false;
  barcodeArray: Barcode[] = [];
  barcode: Barcode = new Barcode();
  getColorByService: any;
  comeDyhouseModel: OutgoingBarcode;
  carName = '';


  constructor(
    private toastr: ToastrService,
    private api: ControlDotService,
  ) { }

  ngOnInit() {
  }

  checkScanBarcode(e) {
    this.checkedScanBarkod = !this.checkedScanBarkod;
    if (e.target.checked) {
      this.searchElement.nativeElement.style.display = 'block';
      setTimeout(() => { // this will make the execution after the above boolean has changed
        this.searchElement.nativeElement.focus();
      }, 0);
    } else if (!e.target.checked) {
      setTimeout(() => { // this will make the execution after the above boolean has changed
        this.searchElement.nativeElement.style.display = 'none';
      }, 0);
    }
  }

  keyDownFunction(event) {
    this.barcode = new Barcode();
    const barcode = this.searchElement.nativeElement.value;
    if (this.barcodeArray.length != 0) {
      let isTrueOrFalse: boolean = true;
      this.barcodeArray.forEach(item => {
        if (barcode === item.BarcodeDetail) {
          this.toastr.warning('Bu kupon daha önceden okutulmuştur.', 'UYARI', {
            timeOut: 3000
          });
          this.searchElement.nativeElement.value = '';
          isTrueOrFalse = false;
        }
      })
      if (isTrueOrFalse) {
        this.api.firstControlDotValidBarcode(barcode).subscribe(data => {
          this.getColorByService = data;
          if (this.getColorByService[0].toLocaleLowerCase() != 'ok') {
            this.toastr.warning(data, 'UYARI', {
              timeOut: 3000
            });
          }
          if (this.getColorByService[0].toLocaleLowerCase() === 'ok') {
            this.barcode.BarcodeDetail = barcode;
            this.barcode.id = this.getColorByService[1];
            this.barcodeArray.push(this.barcode);
            this.searchElement.nativeElement.value = '';
          } else {
            this.searchElement.nativeElement.value = '';
          }
        });
      }
    } else {
      this.api.firstControlDotValidBarcode(barcode).subscribe(data => {
        this.getColorByService = data;
        if (this.getColorByService[0].toLocaleLowerCase() != 'ok') {
          this.toastr.warning(data, 'UYARI', {
            timeOut: 3000
          });
        }
        if (this.getColorByService[0].toLocaleLowerCase() === 'ok') {
          this.barcode.BarcodeDetail = barcode;
          this.barcode.id = this.getColorByService[1];
          this.barcodeArray.push(this.barcode);
          this.searchElement.nativeElement.value = '';
        } else {
          this.searchElement.nativeElement.value = '';
        }
      });
    }
  }

  cleanBarcodeArray(barcode) {
    const index = this.barcodeArray.indexOf(barcode, 0);
    if (index > -1) {
      this.barcodeArray.splice(index, 1);
    }
  }

  focusOutFunction() {
    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.searchElement.nativeElement.focus();
    }, 0);
  }

  onClickSubmit() {
    this.setFormValueToModel();
    if (this.barcodeArray.length > 0) {
      this.api.firstControlDotPostBarcode(this.x).subscribe(data => {
        this.toastr.success('Oluşturulan kuponlar kontrol edildi.', 'BAŞARILI', {
          timeOut: 3000
        });
        this.ngOnInit();
        this.barcodeArray = [];
      });
    } else {
      this.toastr.warning('Önce barkod okutmalısınız.', 'UYARI', {
        timeOut: 3000
      });
    }
  }

  x: number[] = [];
  setFormValueToModel() {
    for (let index = 0; index < this.barcodeArray.length; index++) {
      const element = this.barcodeArray[index];
      this.x.push(element.id);
    }
  }
}
