import { Component, OnInit } from '@angular/core';
import {DevExpressProviders} from "../../_services/devexpress.providers";
import {servers} from "../../shared/configuration";

@Component({
  selector: 'app-read-meters',
  templateUrl: './read-meters.component.html',
  styleUrls: ['./read-meters.component.scss']
})
export class ReadMetersComponent implements OnInit {

  dataSource : any;
  startEditAction: string = "click";
  selectTextOnEditStart: boolean = true;
  constructor(
    private devApi : DevExpressProviders
  ) {
    this.dataSource = this.devApi.readForDevX('id',servers.real+'/readmeters');
  }

  ngOnInit() {
  }

}
