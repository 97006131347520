import {Component, OnInit} from '@angular/core';
import {allServiceProviders} from "../../_services/allService.provider";
import {CollectiveMethod} from "../../_models/CollectiveMethot.model";
import {endpoints} from "../../shared/endpoints";
import {ToastrService} from "ngx-toastr";
import {checkForSecondQualitySackDTO} from "../../_models/DTO/checkForSecondQualitySackDTO";
import {MakeSecondQualitySackDTO} from "../../_models/DTO/makeSecondQualitySackDTO";
import {enterView} from "@angular/core/src/render3/state";
import {MsgResponseDTO} from "../../_models/DTO/MsgResponseDTO";


@Component({
  selector: 'app-make-second-quality-sack',
  templateUrl: './make-second-quality-sack.component.html',
  styleUrls: ['./make-second-quality-sack.component.scss'],
  providers: [allServiceProviders]
})
export class MakeSecondQualitySackComponent implements OnInit {
  clients: any;
  makeSendOrder: MakeSecondQualitySackDTO;
  barcodeText: string;

  constructor(private api: allServiceProviders,
              private toas: ToastrService) {
    this.makeSendOrder = new MakeSecondQualitySackDTO();
  }

  async ngOnInit() {
    let c = new CollectiveMethod();
    c.method = 1;
    c.path = endpoints.clients.path;
    this.clients = await this.api.collectiveV2(c);
  }

  async CheckBarcode(): Promise<void> {
    // Check Null
    if (this.barcodeText == null) {
      this.toas.error('Barkod boş girilemez.')
      return;
    }

    // Check Dubliceted
    if (this.makeSendOrder.barcodes != undefined
      && this.makeSendOrder.barcodes.filter(x => x.barcode == this.barcodeText).length != 0) {
      this.toas.error('Bu kupon zaten okutulmuş.')
      this.barcodeText = '';
      return;
    }

    // Check Api
    let c = new CollectiveMethod()
    c.method = 1
    c.path = endpoints.secondQuality.checkForSecondQualitySack.path + '?rawBarcode=' + this.barcodeText;
    let keep = await this.api.collectiveV2(c);
    console.log(keep);
    if (keep.status) {
      if (this.makeSendOrder.barcodes == undefined) {
        this.makeSendOrder.barcodes = [];
      }
      this.makeSendOrder.barcodes.push(keep);
    } else {
      this.toas.error(keep.msg)
    }
    this.barcodeText = '';
  }

  removePackage(p: checkForSecondQualitySackDTO): void {
    const index = this.makeSendOrder.barcodes.indexOf(p, 0);
    if (index > -1) {
      this.makeSendOrder.barcodes.splice(index, 1);
    }
  }

  async saveButton(): Promise<void> {
    console.log(this.makeSendOrder)
    if (this.makeSendOrder.barcodes == undefined || this.makeSendOrder.barcodes.length == 0) {
      this.toas.error("Paket barkodu okutulması zorunludur.")
      return;
    } else if (this.makeSendOrder.clientId == undefined) {
      this.toas.error("Müşteri seçilmesi zorunludur.")
      return;
    } else if (this.makeSendOrder.sackNo == undefined) {
      this.toas.error("Çuval Numarası girilmesi zorunludur.")
      return;
    }
    let c = new CollectiveMethod()
    c.method = 2;
    c.path = endpoints.secondQuality.makeSecondQualitySack.path
    c.jsonData = this.makeSendOrder;
    c.successMessage = 'Çuval ve Sevkiyat Başarı ile Oluşturuldu.'
    const res: MsgResponseDTO = await this.api.collectiveV2(c);
    if (!res.status) {
      // this.toas.error();
      console.log('Hata');
    } else {
      this.makeSendOrder = new MakeSecondQualitySackDTO();
    }
  }
}
