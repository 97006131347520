import {Component, OnInit} from '@angular/core';
import {DevExpressProviders} from "../../_services/devexpress.providers";
import {servers} from "../../shared/configuration";
import {Router} from "@angular/router";
import {DyehouseProviders} from "../../_services/dyehouse.providers";

@Component({
  selector: 'app-before-dye-list',
  templateUrl: './before-dye-list.component.html',
  styleUrls: ['./before-dye-list.component.scss']
})
export class BeforeDyeListComponent implements OnInit {
  dataSource: any;
  kupon = false;
  x:boolean;

  constructor(
    private devApi: DevExpressProviders,
    private dyehouseProviders: DyehouseProviders,
  ) {
    this.dataSource = this.devApi.readForDevX('id', servers.real + '/DryVehicleComponent');
    this.gitmeyenKuponlar = this.gitmeyenKuponlar.bind(this);
    this.gitmeyenKuponlar2 = this.gitmeyenKuponlar2.bind(this);
  }

  ngOnInit() {
  }

  gitmeyenKuponlar(veri): void {
    this.dyehouseProviders.carBarcode(veri,true)
      .subscribe();

  }
  gitmeyenKuponlar2(veri): void {
    this.dyehouseProviders.carBarcode(veri,false)
      .subscribe();

  }

  demo(x) {
  }
}
