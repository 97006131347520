export enum ROLETYPE {
  ADMIN = 1,
  USER = 2
}

export function roleTypeName(roleId: ROLETYPE): string {
  switch (roleId) {
    case ROLETYPE.ADMIN:
      return 'Admin';
      break;
    case ROLETYPE.USER:
      return 'Kullanıcı';
      break;
  }
}
