export class OutgoingBarcode {
  code: string;
  vehicleId: number;
  orderSubDetails: Barcode[];
  clientId: number;
  isReturned?: boolean;
}

export class Barcode {
  BarcodeDetail: string;
  id: number;
}


