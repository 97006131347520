import {HttpHeaders, HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {endpoints} from '../shared/endpoints';
import {catchError, debounceTime, map} from 'rxjs/operators';
import {AuthenticationService} from './authentication.service';
import {ToastrService} from 'ngx-toastr';
import {Client} from '../_models/client.model';
import {OrderDetail} from '../_models/order-detail.model';
import {Color} from '../_models/color.model';
import {Meter, subOrder} from '../_models/sub-order.model';
import {Package, PackageDetailModel} from '../_models/package.model';
import {SackClient} from '../_models/sackClient.model';
import {Key} from '../_models/clients-package.model';
import {SendSack} from '../_models/send-sack.model';
import {ROUTING} from "../shared/routing";
import {servers} from "../shared/configuration";
import {sackWeightModel} from "../_models/sack-detail.model";
import {ReportSackModel} from "../_models/reportSackModel";
import {ReadMeters} from "../_models/readMeters";
import {MakeSackDto} from "../_models/DTO/makeSack.dto";
import {closeSackDto} from "../_models/DTO/closeSack.dto";
import {SendOrderDTO} from "../_models/DTO/sendOrderDTO";
import {CheckMeterDTO} from "../_models/checkMeterDTO";
import {MsgResponseDTO} from "../_models/DTO/MsgResponseDTO";


@Injectable()
export class PackageProviders {
  header: HttpHeaders;
  private currentUserSubject;

  constructor(private http: HttpClient,
              private router: Router,
              private authenticationService: AuthenticationService,
              private toastr: ToastrService) {
    this.setHeader();
  }

  setHeader() {
    this.currentUserSubject = (JSON.parse(localStorage.getItem('currentUser')));
    if (this.currentUserSubject) {
      this.header = new HttpHeaders()
        .set('Authorization', `Bearer ${(this.currentUserSubject.token)}`)
        .set('Content-Type', 'application/json');
    } else {
      this.router.navigateByUrl(ROUTING.LOGIN);
    }

  }

  getClient(): Observable<Client[]> {
    return this.http.get(endpoints.orderDetails.getClients.path,
      {headers: this.header}).pipe(
      map((data: Client[]) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('getClient hata!');
      })
    );
  }

  getSackByBarcode(barcode): Observable<sackWeightModel> {
    return this.http.get(endpoints.sacks.getSackByBarcode.path,
      {headers: this.header, params: {barcode: barcode}}).pipe(
      map((data: sackWeightModel) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('getSackFromBarcode hata!');
      })
    );
  }

  getAllClientCodes(): Observable<Client[]> {
    return this.http.get(endpoints.clients.getAllClientCodes.path,
      {headers: this.header}).pipe(
      map((data: Client[]) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('getClient hata!');
      })
    );
  }

  /***
   * paketleme sayfasında kuponları çekme servisi
   * @param id: sipariş kalemi id'si
   */
  getByClientNotCompletedDetail(id): Observable<OrderDetail[]> {
    return this.http.get(endpoints.orderDetails.getClientNotCompletedDetail.path,
      {headers: this.header, params: {id: id}}).pipe(
      map((data: OrderDetail[]) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('getClientDetail hata!');
      })
    );
  }

  /***
   * Çuvallama sayfasında gösterilecek cariler
   */
  getSackClient(): Observable<SackClient[]> {
    return this.http.get(endpoints.sacks.getSacksClient.path,
      {headers: this.header}).pipe(
      map((data: SackClient[]) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('getSackClient hata!');
      })
    );
  }

  /***
   * Çuvallama sayfasındaki renkleri çekme fonksiyonu
   */
  getColor(): Observable<Color[]> {
    return this.http.get(endpoints.color.getColors.path,
      {headers: this.header}).pipe(
      map((data: Color[]) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('getColor hata!');
      })
    );
  }

  /***
   * Çuvallama sayfasında seçilen cari id'sine göre gösterilecek paketler
   * @param id:sunucuya gönderilecek cari id'si
   */
  getPackageByClientId(id): Observable<Key[]> {
    return this.http.get(endpoints.sacks.getPackageByClientId.path,
      {headers: this.header, params: {id: id}}).pipe(
      map((data: Key[]) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('SubOrderDetail hata!');
      })
    );
  }

  /***
   * Paketleme sayfasında seçilen sipariş kalemi id'sine göre getirilecek kuponlar
   * @param id: sunucuya gönderilecek sipariş kalemi id'si
   */
  getSubDetailCuponById(id): Observable<subOrder[]> {
    return this.http.get(endpoints.orderSubDetails.getSubDetailCuponById.path,
      {headers: this.header, params: {id: id}}).pipe(
      map((data: subOrder[]) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('SubOrderDetail hata!');
      })
    );
  }


  updateSackWeight(id, weight) {
    return this.http.get(endpoints.sacks.updateSackWeight.path, {
      headers: this.header,
      params: {id: id, weight: weight}
    }).pipe(
      map((data: sackWeightModel) => {
        return data;
      }), catchError(error => {
        this.toastr.error('Çuval Güncellenirken Sorun Oluştu!', 'HATA', {
          timeOut: 3000
        });
        return throwError('updateSackWeight hata!');
      })
    );
  }

  /***
   * Kuponları paketleme servisi
   * @param packageModel: Sunucuya gönderielcek model
   */
  async createPackagesAPI(packageModel: Package): Promise<any> {
    return await this.http.post<PackageDetailModel>(
      endpoints.package.createPackage.path,
      packageModel,
      {headers: this.header}
    ).pipe(
      catchError(error => {
        this.toastr.error('Bir Sorun Oluştu!', 'HATA', {
          timeOut: 3000
        });
        console.log('Hata:', error);
        return "x";
      })
    ).toPromise();
  }

  printPackageAPI(Package: PackageDetailModel): Observable<any> {
    return this.http.post<PackageDetailModel>(
      endpoints.package.printPackage.path,
      Package,
      {headers: this.header}
    ).pipe(
      catchError(error => {
        this.toastr.error('Bir Sorun Oluştu!', 'HATA', {
          timeOut: 3000
        });
        console.log('Hata:', error);
        return "x";
        // return throwError('addNewTicket hata!');
      })
    );
  }


  /***
   * Paketleri çuvallama servisi
   * @param sendSackModel: sunucuya gönderilecek çuval modeli
   */
  createSacksAPI(sendSackModel: SendSack): Observable<any> {
    return this.http.post<SendSack>(
      endpoints.sacks.createSacks.path,
      sendSackModel,
      {headers: this.header}
    ).pipe(
      catchError(error => {
        console.log('error', error.message);
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return "x";
      })
    );
  }

  postMakeSack(barcode: string): Observable<MakeSackDto> {
    return this.http.post<MakeSackDto>(
      endpoints.sacks.makeSack.path + '?packageBarcode=' + barcode,
      null,
      {headers: this.header}
    ).pipe(
      catchError(error => {
        console.log('error', error.message);
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('addNewTicket hata!');
      })
    );
  }

  postCloseSack(barcode: number): Observable<closeSackDto> {
    return this.http.post<closeSackDto>(
      endpoints.sacks.closeSack.path + '?sackId=' + barcode,
      null,
      {headers: this.header}
    ).pipe(
      catchError(error => {
        console.log('error', error.message);
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('addNewTicket hata!');
      })
    );
  }

  inCorrectPackage(id): Observable<any> {
    return this.http.post(endpoints.sacks.inCorrectPackage.path,
      id, {headers: this.header}).pipe(
      map((data: any) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('inCorrectPackage hata!');
      })
    )
  }

  async getCheckMeter(type: string): Promise<CheckMeterDTO> {
    return this.http.get<CheckMeterDTO>(endpoints.package.checkMeter.path + '/' + type, {
      headers: this.header,
    }).toPromise();
  }

  checkCuponsForPackageComponent(barcode) {
    return this.http.get(endpoints.package.checkCuponsForPackageComponent.path, {
      headers: this.header,
      params: {barcode: barcode}
    }).pipe(
      map((data: string) => {
        return data;
      }), catchError(error => {
        this.toastr.warning("Böyle Bir Barkod Bulunamadı", 'UYARI', {
          timeOut: 3000
        });
        return throwError('getOutgoingClients hata!');
      })
    );
  }

  checkCuponsForSackComponent(barcode, client) {
    return this.http.get(endpoints.sacks.checkCuponsForSackComponent.path, {
      headers: this.header,
      params: {barcode: barcode, client: client}
    }).pipe(
      map((data: string) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('getOutgoingClients hata!');
      })
    );
  }

  // getMeters() {
  //let headers: HttpHeaders = new HttpHeaders();
  //headers = headers.append('Content-Type', 'application/json');
  //headers = headers.append('Origin', '*');
  //headers = headers.append('Host', 'http://.168.1.119:8000');
  //headers = headers.append('Access-Control-Allow-Headers', 'http://.168.1.119:8000')
  // headers = headers.append('Accept', '*/*');
  /** headers = headers.append('Accept-Encoding', 'gzip, deflate, br');
   headers = headers.append('Connection', 'keep-alive');
   headers = headers.append('Access-Control-Request-Headers', 'Content-Type, Authorization');
   **/
  //let meters: any;
  //return this.http.get(servers.meters,
  //{headers: headers}).pipe(
  //map((data: any) => {
  //meters = data;
  //return data;
  //}), catchError(error => {
  //if (error == "OK")
  //{
  //return meters;
  //}
  //else {
  //  return throwError(error + ' ::getMeters hata!');
  //}

  //})
  //);
  //}

  getMeters(device: string): Observable<Meter> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    // return this.http.get(servers.meters, {
    //   headers: headers,
    //   params: {}
    // }).pipe(
    //   map((data: Meter) => {
    //     return data;
    //   })
    // );
    return this.http.get<Meter>(device, {headers: headers});
  }

  async getReadMeters(): Promise<ReadMeters> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    // return this.http.get(servers.meters, {
    //   headers: headers,
    //   params: {}
    // }).pipe(
    //   map((data: Meter) => {
    //     return data;
    //   })
    // );

    return await this.http.get<ReadMeters>(endpoints.package.getReadMeters.path, {headers: headers}).toPromise();
  }

  readSackPackageByCompanyAndDate(date): Observable<SendOrderDTO[]> {
    return this.http.get(endpoints.report.getSackCompaniesByDateAndCompanyId.path + date + '/1',
      {headers: this.header}).pipe(
      map((data: SendOrderDTO[]) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('getClient hata!');
      })
    );
  }

  softSettingsByDes(des: string): Observable<MsgResponseDTO> {
    return this.http.get(endpoints.softSettings.byDes.path +'?des='+ des  ,
      {headers: this.header}).pipe(
      map((data: MsgResponseDTO) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('getClient hata!');
      })
    );
  }


  printSackNo(id: number, sackNumber: number): Observable<any> {
    return this.http.post<any>(endpoints.print.printSackNo.path
      + '?sackNumber=' + sackNumber + '&sackId=' + id,
      null,
      {headers: this.header})
      .pipe(map((data: any) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {timeOut: 3000});
        return throwError('printSackNo hata!');
      }));
  }

  printSackTicket(id: number): Observable<any> {
    return this.http.post<any>(endpoints.print.printSackTicket.path
      + '?sackId=' + id, null, {headers: this.header})
      .pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          this.toastr.error(error, 'HATA', {
            timeOut: 3000
          });
          return throwError('printSackNo hata!');
        })
      );
  }

  getTransferSAck(sourceSack: number, destSack: number) {
    return this.http.get<any>(endpoints.sacks.transferSack.path
      + '?sourceSack=' + sourceSack + '&destSack=' + destSack,
      {headers: this.header})
      .pipe(
        map((data: any) => {
          return data;
        }), catchError(error => {
          this.toastr.error(error, 'HATA', {
            timeOut: 3000
          });
          return throwError('transferSack hata!');
        })
      );
  }
}
