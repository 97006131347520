export class SendSack {
  weight: number;
  package: SendingPackage[];
  clientId: number;
}

export class SendingPackage {
  id: number;
}

export class GetSack {
//   arr: "iVBORw0KGgoAAAANSUhEUgAAADkAAAAoCAYAAABEklK7AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAMISURBVGhDhcsBigQxDAPB+f+n906QApGEuMHIbuHvn18myMDfXLP3mP4Cp+9b2vFyfOeaswz8zTV7j+kvcPq+pR0vx3euOcvA31yz95j+AqfvW9rxcnznmrMM/M01e4/pL3D6vqUdL8d3rjnLwN9cs/eY/gKn71va8XJ855qzDPzNNXuP6S9w+r6lHS/Hd645y8DfXLP3mP4Cp+9b2vFyfOeaswz8zTV7j+kvcPq+pR0vx3euOcvA31yz95j+AqfvW9rxcnznmrMM/M01e4/pL3D6vqUdL8d3rjnLwN9cs/eY/gKn71va8XJ855qzDPzNNXuP6S9w+r6lHS/Hd645y8DfXLP3mP4Cp+9b2vFyfOeaswz8zTV7j+kvcPq+pR0vx3euOcvA31yz95j+AqfvW9rxcnznmrMM/M01e4/pL3D6vqUdL8d3rjnLwN9cs/eY/gKn71va8XJ855qzDPzNNXuP6S9w+r6lHS/Hd645y8DfXLP3mP4Cp+9b2vFyfOeaswz8zTV7j+kvcPq+pR0vx3euOcvA31yz95j+AqfvW9rxcnznmrMM/M01e4/pL3D6vqUdL8d3rjnLwN9cs/eY/gKn71va8XJ855qzDPzNNXuP6S9w+r6lHS/Hd645y8DfXLP3mP4Cp+9b2vFyfOeaswz8zTV7j+kvcPq+pR0vx3euOcvA31yz95j+AqfvW9rxcnznmrMM/M01e4/pL3D6vqUdL8d3rjnLwN9cs/eY/gKn71va8XJ855qzDPzNNXuP6S9w+r6lHS/Hd645y8DfXLP3mP4Cp+9b2vFyfOeaswz8zTV7j+kvcPq+pR0vx3euOcvA31yz95j+AqfvW9rxcnznmrMM/M01e4/pL3D6vqUdL8d3rjnLwN9cs/eY/gKn71va8XJ855qzDPzNNXuP6S9w+r6lHS/Hd645y8DfXLP3mP4Cp+9b2vFyfOeaswz8zTV7j+kvcPq+pR0vx3euOcvA31yz95j+AqfvW9rxcnznmrMM/M01e4/pL3D6vqUdL8d3ft/3+wNlc2rLNY+7GgAAAABJRU5ErkJggg=="
// barcode: "1172"
// clientName: "HANEFİ AYDOĞAN - AYDOĞAN MEFRUŞAT"
// count: 1
// sackId: 1172
// sackNumber: 5
// spcode: null
// weight: nul

arr: string;
 barcode: string;
 clientName: string;
}
