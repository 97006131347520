﻿import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home';
import {AdminComponent} from './admin';
import {LoginComponent} from './login';
import {AuthGuard} from './_helpers';
import {NgModule} from '@angular/core';
import {ROUTING} from './shared/routing';
import {RequestListComponent} from './requests/request-list/request-list.component';
import {ROLETYPE} from './shared/role-type.dictionary';
import {SendDyhouseComponent} from './dyehouse/send-dyhouse/send-dyhouse.component';
import {ComeBackToDyeHouseComponent} from './dyehouse/come-back-to-dye-house/come-back-to-dye-house.component';
import {FillTheSackComponent} from './sack/fill-the-sack/fill-the-sack.component';
import {PackageComponent} from './sack/package/package.component';
import {CarWeightComponent} from './dyehouse/car-weight/car-weight.component';
import {OrderDetailComponent} from './requests/order-detail/order-detail.component';
import {DispatchComponent} from './dispatch/dispatch/dispatch.component';
import {DispatchRaportComponent} from "./dispatch/dispatch-raport/dispatch-raport.component";
import {ReportComponent} from "./report/report.component";
import {SackWeightComponent} from "./sack/sack-weight/sack-weight.component";
import {DyehouseReportComponent} from './dyehouse/dyehouse-report/dyehouse-report.component';
import {DyehouseReport2Component} from './dyehouse/dyehouse-report-2/dyehouse-report-2.component';
import {FirstControlDotComponent} from './control-dot/first-control-dot/first-control-dot.component';
import {ReadyForShippingReportComponent} from "./dispatch/ready-for-shipping-report/ready-for-shipping-report.component";
import {NotGoingOutgoingReportComponent} from "./dyehouse/not-going-outgoing-report/not-going-outgoing-report.component";
import {InOutgoinCuponsReportComponent} from "./dyehouse/in-outgoin-cupons-report/in-outgoin-cupons-report.component";
import {CuponsByCustomersReportComponent} from "./cupons-by-customers-report/cupons-by-customers-report.component";
import {VehicleAlgorithmComponent} from "./dyehouse/vehicle-algorithm/vehicle-algorithm.component";
import {BeforeDyehouseComponent} from "./dyehouse/before-dyehouse/before-dyehouse.component";
import {BeforeDyeListComponent} from "./dyehouse/before-dye-list/before-dye-list.component";
import {SearchVehicleForDyehouseComponent} from "./dyehouse/search-vehicle-for-dyehouse/search-vehicle-for-dyehouse.component";
import {ReadMetersComponent} from "./admin/read-meters/read-meters.component";
import {PrinterComponent} from "./admin/printer/printer.component";
import {BossComponent} from "./admin/boss/boss.component";
import {SendDyeHouseReadyComponent} from "./dyehouse/send-dye-house-ready/send-dye-house-ready.component";

import {MobileDevicesComponent} from "./sofSettings/mobile-devices/mobile-devices.component";
import {MobileDeviceModulesComponent} from "./sofSettings/mobile-device-modules/mobile-device-modules.component";
import {ClientComponent} from "./sofSettings/client/client.component";
import {DyehouseLogComponent} from "./dyehouse/dyehouse-log/dyehouse-log.component";
import {BackFromDyehouseComponent} from "./dyehouse/back-from-dyehouse/back-from-dyehouse.component";
import {DyehouseMissingsReportComponent} from "./dyehouse/dyehouse-missings-report/dyehouse-missings-report.component";
import {SackAlgorithmComponent} from "./sofSettings/sack-algorithm/sack-algorithm.component";
import {MakeSackComponent} from "./sack/make-sack/make-sack.component";
import {CloseSackComponent} from "./sack/close-sack/close-sack.component";
import {SacksInProgressComponent} from "./sack/sacks-in-progress/sacks-in-progress.component";
import {GeneralRaportComponent} from "./report/general-raport/general-raport.component";
import {SecondQualityReportComponent} from "./requests/second-quality-report/second-quality-report.component";
import {SecondQualityReportDetailComponent} from "./requests/second-quality-report-detail/second-quality-report-detail.component";
import {MakeSecondQualitySackComponent} from "./sack/make-second-quality-sack/make-second-quality-sack.component";
import {VehicleWeightComponent} from "./dyehouse/vehicle-weight/vehicle-weight.component";


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard]
  }, {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
    data: {roles: [ROLETYPE.ADMIN]}
  }, {
    path: 'login',
    component: LoginComponent
  }, {
    path: ROUTING.REQUEST_LIST,
    component: RequestListComponent
  }, {
    path: ROUTING.SEND_DYHOUSE,
    component: SendDyhouseComponent
  }, {
    path: ROUTING.COME_BACK_TO_DYEHOUSE,
    component: ComeBackToDyeHouseComponent
  }, {
    path: ROUTING.FILL_THE_SACK,
    component: FillTheSackComponent
  }, {
    path: ROUTING.PACKAGE,
    component: PackageComponent
  }, {
    path: ROUTING.CAR_WEIGHT,
    component: CarWeightComponent
  }, {
    path: ROUTING.SACK_WEIGHT,
    component: SackWeightComponent
  }, {
    path: ROUTING.ORDER_DETAIL + '/:orderId' + '/:isOrderStatuNew',
    component: OrderDetailComponent
  }, {
    path: ROUTING.DISPATCH,
    component: DispatchComponent
  }, {
    path: ROUTING.DISPATCH_RAPORT,
    component: DispatchRaportComponent
  }, {
    path: ROUTING.READYFOR_SHIPPING_REPORT,
    component: ReadyForShippingReportComponent
  }, {
    path: ROUTING.DYEHOUSE_REPORT,
    component: DyehouseReportComponent
  }, {
    path: ROUTING.BOSS_SCREEN,
    component: BossComponent
  }, {
    path: ROUTING.DYEHOUSE_REPORT2,
    component: DyehouseReport2Component
  }, {
    path: ROUTING.DYEHOUSE_LOG,
    component: DyehouseLogComponent
  }, {
    path: ROUTING.BACK_FROM_DYEHOUSE,
    component: BackFromDyehouseComponent
  }, {
    path: ROUTING.BACK_FROM_DYEHOUSE,
    component: BackFromDyehouseComponent
  }, {
    path: ROUTING.DYEHOUSE_MISSINGS_REPORT,
    component: DyehouseMissingsReportComponent
  }, {
    path: ROUTING.NOT_GOING_OUTGOING_REPORT,
    component: NotGoingOutgoingReportComponent
  }, {
    path: ROUTING.IN_OUTGOIN_CUPONS_REPORT,
    component: InOutgoinCuponsReportComponent
  }, {
    path: ROUTING.FIRST_CONTROL_DOT,
    component: FirstControlDotComponent
  }, {
    path: ROUTING.REPORT,
    component: ReportComponent
  }, {
    path: ROUTING.CUPONS_BY_CUSTOMERS_REPORT,
    component: CuponsByCustomersReportComponent
  }, {
    path: ROUTING.VEHICLE_ALGORITHM,
    component: VehicleAlgorithmComponent
  }, {
    path: ROUTING.READ_METERS,
    component: ReadMetersComponent
  }, {
    path: ROUTING.PRINTER,
    component: PrinterComponent
  }, {
    path: ROUTING.MOBILE_DEVICES,
    component: MobileDevicesComponent
  }, {
    path: ROUTING.SACK_ALGORITM,
    component: SackAlgorithmComponent
  }, {
    path: ROUTING.MAKE_SACK,
    component: MakeSackComponent
  }, {
    path: ROUTING.CLOSE_SACK,
    component: CloseSackComponent
  }, {
    path: ROUTING.SACKS_IN_PROGRESSS,
    component: SacksInProgressComponent
  }, {
    path: ROUTING.MOBILE_DEVICE_MODULES,
    component: MobileDeviceModulesComponent
  }, {
    path: ROUTING.CLIENT,
    component: ClientComponent
  }, {
    path: ROUTING.BEFORE_DYEHOUSE,
    component: BeforeDyehouseComponent
  }, {
    path: ROUTING.BEFORE_DYEHOUSE_LIST,
    component: BeforeDyeListComponent
  }, {
    path: ROUTING.SEARCH_VEHICLE_FOR_DYEHOUSE,
    component: SearchVehicleForDyehouseComponent
  }, {
    path: ROUTING.SEND_DYE_HOUSE_READY,
    component: SendDyeHouseReadyComponent
  }, {
    path: ROUTING.GENERAL_RAPORT,
    component: GeneralRaportComponent
  }, {
    path: ROUTING.SECOND_QULITY_REPORT,
    component: SecondQualityReportComponent
  }, {
    path: ROUTING.SECOND_QULITY_REPORT_DETAIL + '/:orderDetailId',
    component: SecondQualityReportDetailComponent
  }, {
    path: ROUTING.MAKE_SECOND_QUALITY_SACK,
    component: MakeSecondQualitySackComponent
  }, {
    path: ROUTING.VEHICLE_WEIGHT,
    component: VehicleWeightComponent
  },
  // otherwise redirect to home
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {enableTracing: false})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

