import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpRequest} from '@angular/common/http';
import 'rxjs/add/operator/map';
import {AuthenticationService} from './authentication.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {loadMessages, locale} from 'devextreme/localization';
// @ts-ignore
import trMessages from 'node_modules/devextreme/localization/messages/tr.json';
import {servers} from '../shared/configuration';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import {endpoints} from '../shared/endpoints';
import {Observable} from "rxjs";
import {ReadMeters} from "../_models/readMeters";
import DevExpress from "devextreme";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";

@Injectable()
export class DevExpressProviders {
  header: HttpHeaders;
  private currentUserSubject;
  dataSource: any;
  sales: ReadMeters[] = [];

  constructor(private http: HttpClient,
              private router: Router,
              private authenticationService: AuthenticationService,
              private toastr: ToastrService) {
    this.setHeader();
  }

  getSales() {
    return this.sales
  }

  setHeader() {
    this.currentUserSubject = (JSON.parse(localStorage.getItem('currentUser')));
    if (this.currentUserSubject) {
      this.header = new HttpHeaders()
        .set('Authorization', `Bearer ${(this.currentUserSubject.token)}`)
        .set('Access-Control-Allow-Origin', '*')
        .set('Content-Type', 'application/json');
    }

  }

  readAllModels(ext: string): Observable<any[]> {
    // @ts-ignore
    return this.http.get(ext, {headers: this.header});
  }


  readForDevX(primaryKey: string, ext: string) {
    this.setHeader();
    const tokenCheck = this.header.get('Authorization');
    loadMessages(trMessages);
    locale(navigator.language);
    this.dataSource = AspNetData.createStore({
      key: primaryKey,
      loadUrl: ext,
      insertUrl: ext,
      updateUrl: ext,
      onBeforeSend(method, ajaxOptions) {
        ajaxOptions.headers = {Authorization: tokenCheck };
        ajaxOptions.xhrFields = {withCredentials: false};

      }
    });
    return this.dataSource;
  }

  readForDevXPivot(primaryKey: string, ext: string, fs: any[]) {
    this.setHeader();

    const tokenCheck = this.header.get('Authorization');
    loadMessages(trMessages);
    locale(navigator.language);
    this.dataSource = new PivotGridDataSource({
      store: AspNetData.createStore({
        key: primaryKey,
        loadUrl: ext,
        insertUrl: ext,
        updateUrl: ext,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.headers = {Authorization: tokenCheck};
          ajaxOptions.xhrFields = {withCredentials: false};
        }
      }),
      fields: fs,

    });
    return this.dataSource;
  }

  getOrderDetail(primaryKey: string, orderId) {
    const tokenCheck = this.header.get('Authorization');
    loadMessages(trMessages);
    locale(navigator.language);
    this.dataSource = AspNetData.createStore({
      key: primaryKey,
      loadUrl: endpoints.orders.getSubOrders.path + orderId,
      onBeforeSend(method, ajaxOptions) {
        ajaxOptions.headers = {Authorization: tokenCheck};
        ajaxOptions.xhrFields = {withCredentials: false};

      }
    });
    return this.dataSource;
  }

  getOrders(primaryKey: string, id) {
    const tokenCheck = this.header.get('Authorization');
    loadMessages(trMessages);
    locale(navigator.language);
    this.dataSource = AspNetData.createStore({
      key: primaryKey,
      loadUrl: endpoints.orders.getOrdersByStatus.path + id,
      onBeforeSend(method, ajaxOptions) {
        ajaxOptions.headers = {Authorization: tokenCheck};
        ajaxOptions.xhrFields = {withCredentials: false};

      }
    });
    return this.dataSource;
  }

  sendStorageRequest = async (storageKey, dataType, method, selected, data) => {
    const tokenCheck = this.header.get('Authorization');
    let url = endpoints.devxStorage.bossScreenPivot.path + storageKey + "/" + this.authenticationService.currentUserValue.id + "/" + selected;
    // @ts-ignore
    let req: HttpRequest = new HttpRequest(method, url, {
      headers: new HttpHeaders()
        .set('Accept', 'text/html')
        .set('Content-Type', 'text/html'),
      responseType: dataType
    });

    if (data) {
      await this.http.post(url, data, {headers: this.header}).toPromise();

    }
    return await this.http.get(url, {headers: this.header}).toPromise();
    // return this.http.request(req).toPromise();
  }

  postOne(ext: string, obje: any): Observable<any[]> {
    return this.http.post<any[]>(ext, obje, {headers: this.header});
  }
}
