import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {PackageProviders} from "../../_services/package.providers";
import {MakeSackDto} from "../../_models/DTO/makeSack.dto";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-make-sack',
  templateUrl: './make-sack.component.html',
  styleUrls: ['./make-sack.component.scss']
})
export class MakeSackComponent implements OnInit {
  @ViewChild('search') searchElement: ElementRef;
  private makeSackDto: MakeSackDto;
  private search: FormControl= new FormControl((''));

  constructor(
    private toastr: ToastrService,
    private api: PackageProviders,
  ) {

  }

  ngOnInit() {
    this.searchElement.nativeElement.focus();
  }

  keyDownFunction(e: any): void {
    this.makeSackDto=null;
    const barcode = this.searchElement.nativeElement.value;
    this.searchElement.nativeElement.value ='';
    if (barcode === null || barcode === undefined || barcode === '') {
      this.toastr.warning('Barkod Alanı Boş Bırakılamaz', 'UYARI', {
        timeOut: 3000
      });
      this.makeSackDto=null;
      return
    }
    this.api.postMakeSack(barcode).subscribe(data => {
      debounceTime(500),
      this.makeSackDto = data;
      if (this.makeSackDto.errorRes != null) {
        this.toastr.warning(this.makeSackDto.errorRes, 'UYARI', {
          timeOut: 3000
        });
        this.makeSackDto=null;
        this.searchElement.nativeElement.value = '';
        return

      }else {
        this.toastr.success("Paket Çuvallandı. ", 'Başarılı', {
          timeOut: 3000
        });
        this.searchElement.nativeElement.value = '';
        this.searchElement.nativeElement.focus();
      }
    })
  }
}
