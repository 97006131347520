import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {endpoints} from '../shared/endpoints';
import {catchError, map, tap} from 'rxjs/operators';
import {AuthenticationService} from './authentication.service';
import {ToastrService} from 'ngx-toastr';
import {DispatchClients} from '../_models/clients-dispatch.model';
import {DispatchOrder} from '../_models/orderId-dispatch.model';
import {DispatchKey, DispatchSack} from '../_models/dispatch-sack.model';
import {SendDispatch} from '../_models/send-dispatch.model';
import {ROUTING} from "../shared/routing";


@Injectable()
export class DispatchProviders {
  header: HttpHeaders;
  private currentUserSubject;

  constructor(private http: HttpClient,
              private router: Router,
              private authenticationService: AuthenticationService,
              private toastr: ToastrService) {
    this.setHeader();
  }

  setHeader() {
    this.currentUserSubject = (JSON.parse(localStorage.getItem('currentUser')));
    if (this.currentUserSubject) {
      this.header = new HttpHeaders()
        .set('Authorization', `Bearer ${(this.currentUserSubject.token)}`)
        .set('Content-Type', 'application/json');
    } else {
      this.router.navigateByUrl(ROUTING.LOGIN);
    }

  }

  getSentOrderClients(): Observable<DispatchClients[]> {
    return this.http.get(endpoints.sentOrders.getSentOrderClients.path, {headers: this.header}).pipe(
      map((data: DispatchClients[]) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('DispatchClients hata!');
      })
    );
  }

  getOrderByClientId(id): Observable<DispatchOrder[]> {
    return this.http.get(endpoints.sentOrders.getOrderByClientId.path,
      {headers: this.header, params: {id: id}}).pipe(
      map((data: DispatchOrder[]) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('SubOrderDetail hata!');
      })
    );
  }

  getSackByOrderId(id): Observable<DispatchSack[]> {
    return this.http.get(endpoints.sentOrders.getSackByOrderId.path,
      {headers: this.header, params: {id: id}}).pipe(
      map((data: DispatchSack[]) => {

        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('SubOrderDetail hata!');
      })
    );
  }

  createDispatchAPI(dispatchModel: SendDispatch): Observable<any> {
    return this.http.post<SendDispatch>(
      endpoints.sentOrders.createSentOrder.path,
      dispatchModel,
      {headers: this.header}
    ).pipe(
      catchError(error => {
        if (error == "OK") {
          return "s";
        }
        this.toastr.error(error, 'Hata!', {
          timeOut: 3000
        });
        return "x";
        // return throwError('addNewTicket hata!');
      })
    );
  }
}
