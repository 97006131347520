import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
interface DialogData {
  text: string;
}
@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  @ViewChild('myerr') myErrorText: any;

  errorText: string;

  constructor(public dialogRef: MatDialogRef<ErrorComponent>,@Inject(MAT_DIALOG_DATA) public data: DialogData,) { }

  ngOnInit() {
    this.errorText = this.data.text;
    this.myErrorText.nativeElement.focus();
  }

  close() {
    this.dialogRef.close();
  }

  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.keyCode === 13) {

    }
}

}
