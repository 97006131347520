import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {ToastrService} from "ngx-toastr";
import {PackageProviders} from "../../_services/package.providers";

interface DialogData {
  sackId: string;
}

@Component({
  selector: 'app-transfer-sack',
  templateUrl: './transfer-sack.component.html',
  styleUrls: ['./transfer-sack.component.scss']
})

export class TransferSackComponent implements OnInit {
  @ViewChild('search') searchElement: ElementRef;

  constructor(public dialogRef: MatDialogRef<TransferSackComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData,
              private toastr: ToastrService,
              private api: PackageProviders,
  ) {

  }

  ngOnInit() {

  }

  closeDialog() {
    this.dialogRef.close('Pizza!');
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onYesClick() {
    const barcode = this.searchElement.nativeElement.value;
    if (barcode === null || barcode === undefined || barcode === '') {
      this.toastr.warning('Barkod Alanı Boş Bırakılamaz', 'UYARI', {
        timeOut: 3000
      });
      return;
    }

    this.api.getTransferSAck(+(this.data.sackId), barcode).subscribe(data=>{
      this.toastr.success('Transfer İşlemleri Gerçekleştirildi', 'Başarılı', {
        timeOut: 3000
      });
    })

    this.dialogRef.close("ok");
  }
}


//(keyup.enter)="keyDownFunction($event)"
