import {Component, OnInit, ViewChild} from '@angular/core';
import {DevExpressProviders} from "../../_services/devexpress.providers";
import {DxDataGridComponent} from "devextreme-angular";
import {exportDataGrid} from "devextreme/excel_exporter";
import * as ExcelJS from "exceljs/dist/exceljs.min.js";
import saveAs from 'file-saver';
import {endpoints} from "../../shared/endpoints";
import {BehaviorSubject} from "rxjs";
import {User} from "../../_models";

@Component({
  selector: 'app-in-outgoin-cupons-report',
  templateUrl: './in-outgoin-cupons-report.component.html',
  styleUrls: ['./in-outgoin-cupons-report.component.scss']
})
export class InOutgoinCuponsReportComponent implements OnInit {
  @ViewChild('targetDataGrid') dataGrid: DxDataGridComponent;

  dataSource: any;

  constructor(private devApi: DevExpressProviders) {
  }

  ngOnInit() {
    var currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));

    this.dataSource = this.devApi.readForDevX('', endpoints.report.getInOutgoinCuponsReport.path + '/' + currentUserSubject.value.role);
  }

  onToolbarPreparing(event) {
    event.toolbarOptions.items.unshift({},
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'fas fa-filter',
          title: 'Filtre Sıfırla',
          onClick: this.clearSearchPanel.bind(this)
        }
      });
  }

  clearSearchPanel() {
    this.dataGrid.instance.clearFilter();
  }

  onExporting(e) {

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('CountriesPopulation');
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      topLeftCell: {row: 2, column: 1}
    }).then(function (dataGridRange) {
      const headerRow = worksheet.getRow(1);
      worksheet.mergeCells(1, 1, 1, 10);
      headerRow.getCell(1).value = 'Pak-Ekizceli Boyahane Raporu 2';
      headerRow.getCell(1).font = {name: 'Segoe UI Light', size: 22};
      headerRow.getCell(1).alignment = {horizontal: 'center'};
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(new Blob([buffer], {type: 'application/octet-stream'}), 'PakEkizceliRapor.xlsx');
      });
    });
  }
}
