export class Cupon {
  id: number;
  code: string;
  metres: number;
  barcodeDetail?: string;
  isPrinted?: boolean;
  printCount?: number;
  rankNumber?: string;
  status?: string;
  printDate?: Date;
  orderDetailId?: number;
}

export class CuponDetail {
  id: number;
  color: string;
  floor: string;
  strike: string;
  rope: string;
  ropeThickness: string;
  pattern: string;
  arr: string;
  metres: number;
  code: string;
  client: string;
  machine: string;
  raport: string;
  barcodeDetail?: string;
  isPrinted?: boolean;
  printCount?: number;
  rankNumber?: string;
  printDate?: Date;
  orderDetailId?: number;
  orderdate?: Date;
  barcode?: string;
  compensation: string;
}
