import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CheckBarcodeDTO} from "../../_models/CheckBarcodeDTO";
import {DryVehicleComponentModel} from "../../_models/DryVehicleComponent.model";
import {DyehouseProviders} from "../../_services/dyehouse.providers";
import {DevExpressProviders} from "../../_services/devexpress.providers";
import {ToastrService} from "ngx-toastr";
import {servers} from "../../shared/configuration";
import {DryVehicleComponentDTOModel} from "../../_models/dyehouse/DryVehicleComponentDTO.model";

@Component({
  selector: 'app-send-dye-house-ready',
  templateUrl: './send-dye-house-ready.component.html',
  styleUrls: ['./send-dye-house-ready.component.scss']
})
export class SendDyeHouseReadyComponent implements OnInit {
  @ViewChild('inp') private inp: ElementRef;
  checkBarcode: CheckBarcodeDTO = new CheckBarcodeDTO();
  contents: DryVehicleComponentModel[] = [];
  isSearched = false;
  companyId: number;

  constructor(
    private dyeApi: DyehouseProviders,
    private devApi: DevExpressProviders,
    private toastr: ToastrService
  ) {


  }

  BarcodeAra(): void {
    this.dyeApi.sendToDyeConfirm(this.checkBarcode).subscribe(x => {
      this.isSearched = true;
      this.contents = x;
    })
  }

  gonder(): void {
    if (this.contents != null) {
      this.dyeApi.sendDyReady(+(this.checkBarcode.barcode)).subscribe(a => {
        this.toastr.success('Araç boyaheye gönderilmiştir..');
        this.checkBarcode = new CheckBarcodeDTO();
        this.contents = [];
      })
      // this.dyeApi.sendToDyeConfirm(data).subscribe(a => {
      //   this.toastr.success('Araç boyaheye gönderilmiştir..');
      //   this.contents = [];
      //   this.checkBarcode.barcode = null;
      //   this.isSearched = false;
      // });
    } else {
      this.toastr.warning('İçerik boş olamaz')
    }
  }

  ngOnInit() {
    this.inp.nativeElement.focus();
  }

  onKeydown($event: KeyboardEvent) {
    // @ts-ignore
    if (event.key === "Enter") {
      this.BarcodeAra();
    }
  }

}
