import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { POPUPTYPE } from '../../shared/popup';
import { OrderProviders } from 'src/app/_services/orders.providers';
import { ToastrService } from 'ngx-toastr';
import { PackageProviders } from 'src/app/_services/package.providers';

interface DialogData {
  id: string;
  popupType: string;
}

@Component({
  selector: 'app-remove-item',
  templateUrl: './remove-item.component.html',
  styleUrls: ['./remove-item.component.scss']
})
export class RemoveItemComponent implements OnInit {
  questionText: string = '';

  constructor(
    public dialogRef: MatDialogRef<RemoveItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private api: OrderProviders,
    private packageApi: PackageProviders,
    private toastr: ToastrService) {
  }

  ngOnInit() {
    this.setQuestions();
  }

  setQuestions() {
    switch (this.data.popupType) {
      case POPUPTYPE.ORDER:
        this.questionText = 'Bu siparişi silmek istiyor musunuz?';
        break;
      case POPUPTYPE.SUBORDER:
        this.questionText = 'Bu sipariş kalemini silmek istiyor musunuz?';
        break;
      case POPUPTYPE.TRANSFER:
        this.questionText = 'Bu siparişi tamamlamak istiyor musunuz?';
        break;
      case POPUPTYPE.SUBTRANSFER:
        this.questionText = 'Bu sipariş kalemini tamamlamak istiyor musunuz?';
        break;
      case POPUPTYPE.PACKAGE:
        this.questionText = 'Bu paketi silmek istiyor musunuz?';
    }
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onYesClick() {
    this.callDeleteAPI(this.data.id, this.data.popupType);
    this.dialogRef.close();
  }

  callDeleteAPI(id, popupType) {
    switch (popupType) {
      case POPUPTYPE.ORDER:
        //TODO order silen servis gelecek
        this.api.getOrdersIsActiveById(id).subscribe(data => {
          this.toastr.success('Sipariş Silme işlemi başarılı.', 'BAŞARILI', {
            timeOut: 3000
          });
        })
        break;
      case POPUPTYPE.SUBORDER:
        //TODO kalem silen servis gelecek
        this.api.getOrdersDetailIsActiveById(id).subscribe(data => {
          this.toastr.success('Sipariş Silme işlemi başarılı.', 'BAŞARILI', {
            timeOut: 3000
          });
        })
        break;
      case POPUPTYPE.TRANSFER:
        //TODO kalem silen servis gelecek
        // servisi değiştir
        this.api.getTransferForComplete(id).subscribe(data => {
          this.toastr.success('Sipariş Tamamlama işlemi başarılı.', 'BAŞARILI', {
            timeOut: 3000
          });
        })
        break;
        case POPUPTYPE.SUBTRANSFER:
        //TODO kalem silen servis gelecek
        // servisi değiştir
        this.api.getSubTransferForComplete(id).subscribe(data => {
          this.toastr.success('Sipariş Kalemini Tamamlama işlemi başarılı.', 'BAŞARILI', {
            timeOut: 3000
          });
        })
        break;
      case POPUPTYPE.PACKAGE:
        //TODO paket silen servis gelecek
        this.packageApi.inCorrectPackage(id).subscribe(data => {
          this.toastr.success('Paket Silme işlemi başarılı.', 'BAŞARILI', {
            timeOut: 3000
          });
        })
        break;
    }
  }
}
