import { Component, OnInit } from '@angular/core';
import {DevExpressProviders} from "../../_services/devexpress.providers";
import {servers} from "../../shared/configuration";

@Component({
  selector: 'app-mobile-devices',
  templateUrl: './mobile-devices.component.html',
  styleUrls: ['./mobile-devices.component.scss']
})
export class MobileDevicesComponent implements OnInit {

  dataSource : any;
  startEditAction: string = "click";
  selectTextOnEditStart: boolean = true;
  constructor(
    private devApi : DevExpressProviders
  ) {
    this.dataSource = this.devApi.readForDevX('id',servers.real+'/mobileDevices/devx');
  }

  ngOnInit() {
  }

}
