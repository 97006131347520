import {Component, OnInit} from '@angular/core';
import {CollectiveMethod} from "../../_models/CollectiveMethot.model";
import {allServiceProviders} from "../../_services/allService.provider";
import {endpoints} from "../../shared/endpoints";

@Component({
  selector: 'app-vehicle-weight',
  templateUrl: './vehicle-weight.component.html',
  styleUrls: ['./vehicle-weight.component.scss'],
  providers: [allServiceProviders]
})
export class VehicleWeightComponent implements OnInit {
  vehicleId: number;
  weight: number;

  constructor(private api: allServiceProviders) {
  }

  ngOnInit() {
  }

  async saveButton(): Promise<void> {
    let c = new CollectiveMethod();
    c.method = 2;
    c.path = endpoints.dryVehicle.setVehicleWeight.path
      + '?vehicleId=' + this.vehicleId + '&weight=' + this.weight;
    c.successMessage = 'Araç ağırlıgı başarı ile girildi.';
    c.errorMessage = 'Araç bulunamadı yada sistemde bir sorun var.'
    await this.api.collectiveV2(c);
    this.vehicleId = 0;
    this.weight = 0;
  }
}

