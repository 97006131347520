
import {servers} from './configuration';

export const endpoints = {
  secondQuality: {
    path: servers.real + '/SecondQuality',
    Reasons: {
      path: servers.real + '/SecondQuality/Reasons'
    },
    OrderDetails: {
      path: servers.real + '/SecondQuality/OrderDetails'
    },
    GetCompensationData: {
      path: servers.real + '/SecondQuality/GetCompensationData'
    },
    createCompensationCupon: {
      path: servers.real + '/SecondQuality/createCompensationCupon'
    },
    getCuponPrintDetail: {
      path: servers.real + '/SecondQuality/getCuponPrintDetail'
    },
    checkForSecondQualitySack: {
      path: servers.real + '/SecondQuality/checkForSecondQualitySack'
    },
    makeSecondQualitySack: {
      path: servers.real + '/SecondQuality/makeSecondQualitySack'
    },
    updateCuponAfterPrint: {
      path: servers.real + '/SecondQuality/updateCuponAfterPrint'
    },
  },
  users: {
    authenticate: {
      path: servers.real + '/Auth/login'
    },
    path: servers.postmanMock + '/users'

  },
  devxStorage: {
    bossScreenPivot: {
      path: servers.real + '/DevxPivotStorage/'
    },
    reportNames: {
      path: servers.real + '/DevxPivotStorage/reportNames/'
    },
    newAltReport: {
      path: servers.real + '/DevxPivotStorage/newAltReport/'
    },
  },
  orders: {
    getOrdersByStatus: {
      path: servers.real + '/orders/getOrdersByStatu?id='
    },
    getSubDetailCuponListById: {
      path: servers.real + '/orderDetails/getSubDetailCuponById'
    },
    createSubDetailCuponById: {
      path: servers.real + '/orderDetails/createSubDetailCuponById'
    },
    getCuponDetailByCuponId: {
      path: servers.real + '/orderDetails/getCuponDetailByCuponId'
    },
    getSubOrders: {
      path: servers.real + '/orderDetails/detail?id='
    },
    getMachines: {
      path: servers.real + '/machines'
    },
    getOrdersIsActiveById: {
      path: servers.real + '/orders/getOrdersIsActiveById' //Sipariş iptali için kullanılacak POST servisi
    },
    transferOrder: {
      path: servers.real + '/orders/setOrderStatusCompleted'
    },
  },
  orderDetails: {
    getOrderDetails: {
      path: servers.real + '/orderDetails'
    },
    getClients: {
      path: servers.real + '/orderDetails/getClients'
    },
    getAllCustomers: {
      path: servers.real + '/orderDetails/getClients'
    },
    getClientNotCompletedDetail: {
      path: servers.real + '/orderDetails/getByClientNotCompletedDetail'
    },
    getSubDetailCuponById: {
      path: servers.real + '/orderDetails/getSubDetailCuponById'
    },
    getOrdersDetailIsActiveById: {
      path: servers.real + '/orderDetails/getOrdersDetailIsActiveById'//Sipariş kalemlerinin iptali
    },
    transferSubOrder: {
      path: servers.real + '/orders/setOrderDetailStatusCompleted'
    },
  },
  orderSubDetails: {
    getSubDetailCuponById: {
      path: servers.real + '/orderSubDetails/getDetailCuponById' //Paketleme ekranındaki kuponlar
    },
    updateSubDetailPrintInfoById: {
      path: servers.real + '/orderSubDetails/UpdateSubDetailPrintInfoById'
    },
    getColorByCuponBarcode: {
      path: servers.real + '/orderSubDetails/GetColorByCuponBarcode'
    },
    checkCuponForOutgoingVehicle: {
      path: servers.real + '/orderSubDetails/checkCuponForOutgoingVehicle'
    },
    checkCuponForInComingVehicle: {
      path: servers.real + '/orderSubDetails/checkCuponForInComingVehicle'
    },
    checkCuponForReturnVehicle: {
      path: servers.real + '/orderSubDetails/checkCuponForReturnVehicle'
    }
  },
  vehiclesWeights: {
    getVehicleWeightsWithVehicle: {
      path: servers.real + '/vehicleWeights/getVehicles'
    },
    postVehicleWeight: {
      path: servers.real + '/vehicleWeights'
    },
    getVehiclesWeight: {
      path: servers.real + '/vehicleWeights/getVehiclesWeightDetail'
    }
  },
  outgoingVehicle: {
    postBarcodeOutgoing: {
      path: servers.real + '/orderSubDetails/barcodesOutGoing'
    },
    carBarcode: {
      path: servers.real + '/print/printVehicle'
    },
    carBarcodeTermal: {
      path: servers.real + '/printer/printerrandom/'
    },
    carBarcodeTermal2: {
      path: servers.real + '/printer/printerCarClose/'
    },
    barcodesInComing: {
      path: servers.real + '/outgoingVehicles/barcodesInComing'
    },
    updateNetWeight: {
      path: servers.real + '/outgoingVehicles/UpdateNetWeight'
    }

  },
  print: {
    printSackNo: {
      path: servers.real + '/print/printSackNo'
    },
    printSackTicket: {
      path: servers.real + '/print/printSackTicket'
    },
  },
  Vehicles: {
    getVehicles: {
      path: servers.real + '/vehicles'
    },
    postVehicles: {
      path: servers.real + '/vehicles'
    },
    getVehiclesById: {
      path: servers.real + '/vehicles/'
    }
  },
  sacks: {
    getSacks: {
      path: servers.real + '/sacks'
    },
    makeSack: {
      path: servers.real + '/sacks/makeSack'
    },
    transferSack: {
      path: servers.real + '/sacks/transferSack'
    },
    closeSack: {
      path: servers.real + '/sacks/closeSack'
    },
    getSacksClient: {
      path: servers.real + '/sacks/getSackClient'
    },
    getPackageByClientId: {
      path: servers.real + '/sacks/getPackageByClientId'
    },
    createSacks: {
      path: servers.real + '/sacks/createSack'
    },
    inCorrectPackage: {
      path: servers.real + '/sacks/inCorrectPackage'
    },
    checkCuponsForSackComponent: {
      path: servers.real + '/sacks/checkCuponsForSackComponent'
    },
    getSackByBarcode: {
      path: servers.real + '/sacks/getSackByBarcode'
    },
    updateSackWeight: {
      path: servers.real + '/sacks/UpdateSackWeight'
    }
  },
  color: {
    getColors: {
      path: servers.real + '/color'
    },
    getColorsById: {
      path: servers.real + '/color/id'
    }
  },

  package: {
    createPackage: {
      path: servers.real + '/packages/getCreatePackage'
    },
    checkMeter: {
      path: servers.real + '/packages/Checkmeter'
    },
    getReadMeters: {
      path: servers.real + '/readMeters/active'
    },
    printPackage: {
      path: servers.real + '/print/printPackage'
    },
    checkCuponsForPackageComponent: {
      path: servers.real + '/packages/checkCuponsForPackageComponent'
    }
  },
  sentOrders: {
    getSentOrderClients: {
      path: servers.real + '/sentOrders/getSentOrderClients'
    },
    getOrderByClientId: {
      path: servers.real + '/sentOrders/getOrderByClientId'
    },
    getSackByOrderId: {
      path: servers.real + '/sentOrders/getSackByOrderId'
    },
    createSentOrder: {
      path: servers.real + '/sentOrders/createSentOrder'
    }
  },
  readFromLogo: {
    path: servers.real + '/readFromLogo/getReadLogo'
  },
  readFromLogoOutgoingClients: {
    path: servers.real + '/clients/syncOutgoingClients'
  },
  clients: {
    path: servers.real + '/clients',

    getOutgoingClients: {
      path: servers.real + '/clients/getOutgoingCompanies'
    },
    syncOutgoingClients: {
      path: servers.real + '/clients/syncOutgoingClients'
    },
    getAllClientCodes: {
      path: servers.real + '/clients/GetAllClientCodes'
    },
    getAllCustomers: {
      path: servers.real + '/clients/GetAllCustomers'
    }
  },
  softSettings: {
    byDes: {
      path: servers.real + '/SoftSettings/byDes'
    }
  },
  report: {
    getSackRaport: {
      path: servers.real + '/report/getSackRaport'
    },
    getSacksinProgress: {
      path: servers.real + '/report/getSacksinProgress'
    },
    getDyhouseLogReport: {
      path: servers.real + '/report/DyhouseLogReport'
    },
    getGeneralRaport: {
      path: servers.real + '/report/getGeneralReport'
    },
    getOutGoingReport: {
      path: servers.real + '/report/getOutGoingReport'
    },
    getOutGoingReport2: {
      path: servers.real + '/report/OutgoingReport2'
    },
    getSackCompanies: {
      path: servers.real + '/report/GetSackCompanies/'
    },
    getSackCompaniesByDateAndCompanyId: {
      path: servers.real + '/report/GetSackRaportById/'
    },
    getNotGoingOutgoingReport: {
      path: servers.real + '/report/NotGoingOutgoingReport'
    },
    getInOutgoinCuponsReport: {
      path: servers.real + '/report/InOutgoinCuponsReport'
    },
    getBackFromOutgoinCuponsReport: {
      path: servers.real + '/report/BackFromOutgoinCuponsReport'
    },
    getOutgoinMissingReport: {
      path: servers.real + '/report/OutgoinMissingReport'
    },
    getReadyForShippingReport: {
      path: servers.real + '/report/ReadyForShippingReport'
    },
    postGeneralReport: {
      path: servers.real + '/EditRecord/printPackage'
    },
    postGeneralReportOpenSack: {
      path: servers.real + '/EditRecord/openSack'
    },
    postGeneralReportMeters: {
      path: servers.real + '/EditRecord/changePackageMeter'
    },
    postChangeSackNumber: {
      path: servers.real + '/EditRecord/changeSackNumber'
    },
    removeGeneralReport: {
      path: servers.real + '/EditRecord/RemovePackage'
    },
    removeGeneralReportSack: {
      path: servers.real + '/EditRecord/RemoveSack'
    },
    removePackageFromSack: {
      path: servers.real + '/EditRecord/removePackageFromSack'
    },
    CuponsByCustomersReport: {
      path: servers.real + '/report/CuponsByCustomersReport'
    },
    getPossibleSack: {
      path: servers.real + '/EditRecord/getPossibleSacks'
    },
    postTransferSackAndCloseOldOne: {
      path: servers.real + '/EditRecord/transferSackAndCloseOldOne'
    },
  },
  dryVehicle: {
    setVehicleWeight: {
      path: servers.real + '/DryVehicle/setVehicleWeight'
    }
  },
  controlDot: {
    UpdateCuponsCheckPoint1: {
      path: servers.real + '/orderSubDetails/UpdateCuponsCheckPoint1'
    },
    checkCuponForCheckPoint1: {
      path: servers.real + '/orderSubDetails/checkCuponForCheckPoint1'
    },
    checkBeforeDye: {
      path: servers.real + '/orderSubDetails/checkBeforeDye'
    },
    searchVehicleForDyehouse: {
      path: servers.real + '/DryVehicleComponent/searchVehicleForDyehouse'
    },
    outGoingVehiclesWithCar: {
      path: servers.real + '/orderSubdetails/outGoingVehiclesWithCar'
    },
    sendToDyeConfirm: {
      path: servers.real + '/DryVehicleComponent/getForSendDyHouse'
    }, sendDyReady: {
      path: servers.real + '/DryVehicle/sendDyHouse'
    },
  },
  sackAlgorithm: {
    sackAlgorithm: {
      path: servers.real + '/SackAlgorithm/devx'
    },
    getPattern: {
      path: servers.real + '/SackAlgorithm/getPattern'
    },
    getClients: {
      path: servers.real + '/SackAlgorithm/getClients'
    },
  }
};
