﻿import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ToastrModule} from 'ngx-toastr';
import {ErrorInterceptor, JwtInterceptor} from './_helpers';
import {AppComponent} from './app.component';
import {HomeComponent} from './home';
import {AdminComponent} from './admin';
import {LoginComponent} from './login';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AngularFontAwesomeModule} from 'angular-font-awesome';
import {AppRoutingModule} from './app.routing';
import {RequestListComponent} from './requests/request-list/request-list.component';
import {DatePipe, LocationStrategy, HashLocationStrategy} from '@angular/common';
import {CdkTableModule} from '@angular/cdk/table';
import {NavbarComponent} from './components/navbar/navbar.component';
import {SendDyhouseComponent} from './dyehouse/send-dyhouse/send-dyhouse.component';
import {ComeBackToDyeHouseComponent} from './dyehouse/come-back-to-dye-house/come-back-to-dye-house.component';
import {FillTheSackComponent} from './sack/fill-the-sack/fill-the-sack.component';
import {PackageComponent} from './sack/package/package.component';
import {CarWeightComponent} from './dyehouse/car-weight/car-weight.component';
import {OrderDetailComponent} from './requests/order-detail/order-detail.component';
import {DyehouseProviders} from './_services/dyehouse.providers';
import {DispatchComponent} from './dispatch/dispatch/dispatch.component';
import {OrderProviders} from './_services/orders.providers';
import {PrintProviders} from './_services/print.service';
import {PackageProviders} from './_services/package.providers';
import {DispatchProviders} from './_services/dispatch.providers';
import {MatDialogModule, MatFormFieldModule, MatButtonModule, MatInputModule} from '@angular/material';
import {RemoveItemComponent} from './components/remove-item/remove-item.component';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {DispatchRaportComponent} from './dispatch/dispatch-raport/dispatch-raport.component';
import {DevExtremeModule, DxChartModule, DxDataGridModule, DxTemplateModule} from "devextreme-angular";
import {DevExpressProviders} from "./_services/devexpress.providers";
import {ReportComponent} from './report/report.component'
  ;
import {SackWeightComponent} from './sack/sack-weight/sack-weight.component'
import {DyehouseReportModule} from './dyehouse/dyehouse-report/dyehouse-report.module'
import {DyehouseReport2Module} from './dyehouse/dyehouse-report-2/dyehouse-report-2.module'
import {FirstControlDotModule} from './control-dot/first-control-dot/first-control-dot.module'
import {LoadingComponent} from './components/loading/loading.component'
import {LoadingModule} from './components/loading/loading.module'
import {NotGoingOutgoingReportComponent} from './dyehouse/not-going-outgoing-report/not-going-outgoing-report.component'
import {InOutgoinCuponsReportComponent} from './dyehouse/in-outgoin-cupons-report/in-outgoin-cupons-report.component'
import {ReadyForShippingReportComponent} from './dispatch/ready-for-shipping-report/ready-for-shipping-report.component'
import {CuponsByCustomersReportComponent} from './cupons-by-customers-report/cupons-by-customers-report.component'
import {VehicleAlgorithmComponent} from './dyehouse/vehicle-algorithm/vehicle-algorithm.component'
import {BeforeDyehouseComponent} from './dyehouse/before-dyehouse/before-dyehouse.component'
import {BeforeDyeListComponent} from './dyehouse/before-dye-list/before-dye-list.component'
import {SearchVehicleForDyehouseComponent} from './dyehouse/search-vehicle-for-dyehouse/search-vehicle-for-dyehouse.component'
import {ReadMetersComponent} from './admin/read-meters/read-meters.component'
import {PrinterComponent} from './admin/printer/printer.component'
import {BossComponent} from "./admin/boss/boss.component"
import {SendDyeHouseReadyComponent} from './dyehouse/send-dye-house-ready/send-dye-house-ready.component'
import {MobileDevicesComponent} from './sofSettings/mobile-devices/mobile-devices.component'
import {MobileDeviceModulesComponent} from './sofSettings/mobile-device-modules/mobile-device-modules.component'
import {ClientComponent} from './sofSettings/client/client.component'
import {DyehouseLogComponent} from './dyehouse/dyehouse-log/dyehouse-log.component'
import {BackFromDyehouseComponent} from './dyehouse/back-from-dyehouse/back-from-dyehouse.component'
import {DyehouseMissingsReportComponent} from './dyehouse/dyehouse-missings-report/dyehouse-missings-report.component'
  ;
import {SackAlgorithmComponent} from './sofSettings/sack-algorithm/sack-algorithm.component'
  ;
import {MakeSackComponent} from './sack/make-sack/make-sack.component'
  ;
import {CloseSackComponent} from './sack/close-sack/close-sack.component'
  ;
import {SacksInProgressComponent} from './sack/sacks-in-progress/sacks-in-progress.component'
  ;
import {TransferSackComponent} from './sack/transfer-sack/transfer-sack.component'
  ;
import {GeneralRaportComponent} from './report/general-raport/general-raport.component'
  ;
import {MakeSecondQualityPopComponent} from './secondQuality/make-second-quality-pop/make-second-quality-pop.component'
import {SecondQualityReportComponent} from "./requests/second-quality-report/second-quality-report.component";
import {SecondQualityReportDetailComponent} from "./requests/second-quality-report-detail/second-quality-report-detail.component";

;
import {MakeSecondQualitySackComponent} from './sack/make-second-quality-sack/make-second-quality-sack.component'
  ;
import {VehicleWeightComponent} from './dyehouse/vehicle-weight/vehicle-weight.component'

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  exports: [
    CdkTableModule,
    ToastrModule,
    MatDialogModule, MatFormFieldModule, MatButtonModule, MatInputModule
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    PerfectScrollbarModule,
    BrowserAnimationsModule,
    AngularFontAwesomeModule,
    // Ng2SmartTableModule,
    FormsModule,
    NgxMatSelectSearchModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    DevExtremeModule,
    DxDataGridModule,
    DyehouseReportModule,
    DyehouseReport2Module,
    FirstControlDotModule,
    DxTemplateModule,
    LoadingModule,
    DxChartModule,
    MatDialogModule,
    ToastrModule.forRoot({
      timeOut: 2000
    }),

    // ToastrModule added
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    AdminComponent,
    LoginComponent,
    RequestListComponent,
    SidebarComponent,
    NavbarComponent,
    SendDyhouseComponent,
    ComeBackToDyeHouseComponent,
    PackageComponent,
    FillTheSackComponent,
    CarWeightComponent,
    OrderDetailComponent,
    DispatchComponent,
    RemoveItemComponent,
    DispatchRaportComponent,
    ReportComponent,
    SackWeightComponent,
    LoadingComponent,
    NotGoingOutgoingReportComponent,
    InOutgoinCuponsReportComponent,
    ReadyForShippingReportComponent,
    CuponsByCustomersReportComponent,
    VehicleAlgorithmComponent,
    BeforeDyehouseComponent,
    BeforeDyeListComponent,
    SearchVehicleForDyehouseComponent,
    ReadMetersComponent,
    PrinterComponent,
    BossComponent,
    SendDyeHouseReadyComponent,
    DyehouseLogComponent,
    MobileDevicesComponent,
    MobileDeviceModulesComponent,
    ClientComponent,
    InOutgoinCuponsReportComponent,
    SendDyeHouseReadyComponent,
    BossComponent,
    DyehouseLogComponent,
    BackFromDyehouseComponent,
    DyehouseMissingsReportComponent,
    SackAlgorithmComponent,
    MakeSackComponent,
    CloseSackComponent,
    SacksInProgressComponent,
    TransferSackComponent,
    GeneralRaportComponent,
    MakeSecondQualityPopComponent,
    SecondQualityReportComponent,
    SecondQualityReportDetailComponent,
    MakeSecondQualitySackComponent,
    VehicleWeightComponent,
  ],
  entryComponents: [
    RemoveItemComponent,
    LoadingComponent,
    CuponsByCustomersReportComponent,
    RemoveItemComponent,
    LoadingComponent,
    TransferSackComponent,
  ],


  providers: [
    OrderProviders,
    DatePipe,
    PrintProviders,
    DyehouseProviders,
    PackageProviders,
    DispatchProviders,
    DevExpressProviders,
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }

    // provider used to create fake backend
    // fakeBackendProvider
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})

export class AppModule {
}
