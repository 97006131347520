import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DyehouseReportComponent } from './dyehouse-report.component';
import { DxDataGridModule } from 'devextreme-angular';

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule
  ],
  declarations: [DyehouseReportComponent]
})
export class DyehouseReportModule { }
