export class OutgoingCompanies {
  id: number;
  clientCode: string;
  name: string;
  logicCreatedDate: Date;
  logicModifiedDate: Date;
  speCode: string;
  createTime: Date;
  createBy: number;
  updateTime: Date;
  updateBy: number;
  isActive: boolean;
}
