export class subOrder {
  id: number;
  code: string;
  color?: string;
  floor?: string;
  rope?: string;
  pattern?: string;
  strike?: string;
  amount: number;
  specode?: string;
  metres?: string;
  name?: string;
  remainingAmount?: number;
}

export class Meter {
  meter: string;
}
