import {Component, OnInit, ViewChild} from '@angular/core';
import {DxDataGridComponent} from "devextreme-angular/ui/data-grid";
import {exportDataGrid} from "devextreme/excel_exporter";
import * as ExcelJS from "exceljs/dist/exceljs.min.js";
import saveAs from 'file-saver';
import {DevExpressProviders} from "../../_services/devexpress.providers";
import {endpoints} from "../../shared/endpoints";
import {PackageProviders} from "../../_services/package.providers";
import {MatDialog} from "@angular/material";
import {TransferSackComponent} from "../transfer-sack/transfer-sack.component";
import {RemoveItemComponent} from "../../components/remove-item/remove-item.component";
import {POPUPTYPE} from "../../shared/popup";
import {LoadingComponent} from "../../components/loading/loading.component";
import {MatDialogConfig} from "@angular/material/dialog";

@Component({
  selector: 'app-sacks-in-progress',
  templateUrl: './sacks-in-progress.component.html',
  styleUrls: ['./sacks-in-progress.component.scss']
})
export class SacksInProgressComponent implements OnInit {
  @ViewChild('targetDataGrid') dataGrid: DxDataGridComponent;
  dataSource: any;
  popupVisible = false;


  constructor(
    private devApi: DevExpressProviders,
    private api: PackageProviders,
    public dialog: MatDialog,
  ) {
    this.printSackNo = this.printSackNo.bind(this);
    this.printSackTicket = this.printSackTicket.bind(this);
    this.transferSack = this.transferSack.bind(this);
  }

  ngOnInit() {
    this.dataSource = this.devApi.readForDevX('', endpoints.report.getSacksinProgress.path);
  }

  onExporting(e) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('CountriesPopulation');
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      topLeftCell: {row: 2, column: 1}
    }).then(function (dataGridRange) {
      const headerRow = worksheet.getRow(1);
      worksheet.mergeCells(1, 1, 1, 10);
      headerRow.getCell(1).value = 'Pak-Ekizceli İşlemdeki Çuvallar';
      headerRow.getCell(1).font = {name: 'Segoe UI Light', size: 22};
      headerRow.getCell(1).alignment = {horizontal: 'center'};
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(new Blob([buffer], {type: 'application/octet-stream'}), 'PakEkizceliRapor.xlsx');
      });
    });
  }

  onToolbarPreparing(event) {
    event.toolbarOptions.items.unshift({},
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'fas fa-filter',
          title: 'Filtre Sıfırla',
          onClick: this.clearSearchPanel.bind(this)
        }
      });
  }

  clearSearchPanel() {
    this.dataGrid.instance.clearFilter();
  }

  transferSack(data: any): void {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.closeOnNavigation=true;
    dialogConfig.width = '500px';
    dialogConfig.data= {sackId: data.row.data.id};
    const dialogRef = this.dialog.open(TransferSackComponent, dialogConfig);
      dialogRef.afterClosed().subscribe( data=>{
        if (data==='ok'){
          this.dataSource = this.devApi.readForDevX('', endpoints.report.getSacksinProgress.path);
        }
      });

  }

  printSackNo(data: any): void {
    this.api.printSackNo(data.row.data.id, data.row.data.sackNumber).subscribe()
  }

  printSackTicket(data: any): void {
    this.api.printSackTicket(data.row.data.id).subscribe()
  }

  test() {
    const dialogRef = this.dialog.open(LoadingComponent, {
      disableClose: true,
      width: '500px',
      data: {}
    });
  }
}
