import { Component, OnInit, ViewChild } from '@angular/core';
import { endpoints } from "../shared/endpoints";
import { DevExpressProviders } from "../_services/devexpress.providers";
import { DxDataGridComponent } from "devextreme-angular";
import * as ExcelJS from "exceljs/dist/exceljs.min.js";
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  @ViewChild('targetDataGrid') dataGrid: DxDataGridComponent;
  dataSource: any;

  storage: string = "storage";

  constructor(private devApi: DevExpressProviders) {
  }

  ngOnInit() {
    this.dataSource = this.devApi.readForDevX('id', endpoints.report.getGeneralRaport.path);
  }

  onToolbarPreparing(event) {
    event.toolbarOptions.items.unshift({},
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'fas fa-filter',
          title: 'Filtre Sıfırla',
          onClick: this.clearSearchPanel.bind(this)
        }
      });
  }

  clearSearchPanel() {
    this.dataGrid.instance.clearFilter();
  }

  onExporting(e) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('CountriesPopulation');
    exportDataGrid({ component: e.component, worksheet: worksheet, topLeftCell: { row: 2, column: 1 } }).then(function (dataGridRange) { const headerRow = worksheet.getRow(1); worksheet.mergeCells(1, 1, 1, 10); headerRow.getCell(1).value = 'Ekizceli Genel Rapor'; headerRow.getCell(1).font = { name: 'Segoe UI Light', size: 22 }; headerRow.getCell(1).alignment = { horizontal: 'center' }; }).then(function () { workbook.xlsx.writeBuffer().then(function (buffer) { saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'PakEkizceliRapor.xlsx'); }); });
  }
}
