import {Component, OnInit, ViewChild} from '@angular/core';
import {DxDataGridComponent} from "devextreme-angular/ui/data-grid";
import {DevExpressProviders} from "../../_services/devexpress.providers";
import {endpoints} from "../../shared/endpoints";
import {exportDataGrid} from "devextreme/excel_exporter";
import * as ExcelJS from "exceljs/dist/exceljs.min.js";
import saveAs from 'file-saver';
import {OrderProviders} from "../../_services/orders.providers";
import {DevxReporSelectorModel} from "../../_models/devxReporSelector.model";
import {SackListModel} from "../../_models/sackList.model";

@Component({
  selector: 'app-general-raport',
  templateUrl: './general-raport.component.html',
  styleUrls: ['./general-raport.component.scss']
})
export class GeneralRaportComponent implements OnInit {

  @ViewChild('targetDataGrid') dataGrid: DxDataGridComponent;
  dataSource: any;
  popupVisible = false;
  meter: number;
  suckNumber: number;
  rowId: number;
  meterStatus = false;
  suckStatus = false;
  subStatusId: number;
  packageId: number;
  sackId: number;
  buttonVisible = false;
  moveStatus = false;
  cuvalSource: any;
  sackList: SackListModel [] = [];
  sackModel: SackListModel = new SackListModel();
  suckId: number;

  constructor(
    private devApi: DevExpressProviders,
    private orderApi: OrderProviders
  ) {
    this.dataSource = this.devApi.readForDevX('id', endpoints.report.getGeneralRaport.path);
    this.openPopup = this.openPopup.bind(this);
  }

  ngOnInit() {

  }

  onExporting(e) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('CountriesPopulation');
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      topLeftCell: {row: 2, column: 1}
    }).then(function (dataGridRange) {
      const headerRow = worksheet.getRow(1);
      worksheet.mergeCells(1, 1, 1, 10);
      headerRow.getCell(1).value = 'Pak-Ekizceli Boyahane Raporu';
      headerRow.getCell(1).font = {name: 'Segoe UI Light', size: 22};
      headerRow.getCell(1).alignment = {horizontal: 'center'};
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(new Blob([buffer], {type: 'application/octet-stream'}), 'PakEkizceliRapor.xlsx');
      });
    });
  }

  onToolbarPreparing(event) {
    event.toolbarOptions.items.unshift({},
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'fas fa-filter',
          title: 'Filtre Sıfırla',
          onClick: this.clearSearchPanel.bind(this)
        }
      });
  }

  clearSearchPanel() {
    this.dataGrid.instance.clearFilter();
  }

  openPopup(a: any) {
    this.sackList = a.row.data.packageComponents.sack;
    this.popupVisible = true;
    this.subStatusId = a.row.data.subStatusId;
    this.rowId = a.row.data.packageComponents.packageId;
    this.meter = a.row.data.packageComponents.sumMetres;
    this.packageId = a.row.data.packageComponents.packageId;
    if (a.row.data.packageComponents.sack != null) {
      this.sackId = a.row.data.packageComponents.sack.sackId;
    }

  }

  postPopup() {
    this.orderApi.postGeneralRaportPopup(this.rowId).subscribe(l => {
        this.popupVisible = false
      }
    )
  }

  editMeterFn() {
    this.meterStatus = !this.meterStatus;
  }

  editSuckFn() {
    this.suckStatus = !this.suckStatus;
  }

  postEditMeter() {
    this.orderApi.postGeneralRaportMeterPopup(this.rowId, this.meter).subscribe(l => {
        this.popupVisible = false
        this.dataGrid.instance.refresh();
      }
    )
  }

  postOpenSack() {
    this.orderApi.postGeneralRaportOpenSack(this.sackId).subscribe(l => {
        this.popupVisible = false
        this.dataGrid.instance.refresh();
      }
    )
  }

  postChangeSackNumber() {
    this.orderApi.postChangeSackNumber(this.sackId, this.suckNumber).subscribe(l => {
        this.popupVisible = false
        this.dataGrid.instance.refresh();
      }
    )
  }

  removePackage() {
    this.orderApi.removeGeneralRaportPackage(this.rowId).subscribe(l => {
        this.popupVisible = false
        this.dataGrid.instance.refresh();
      }
    )
  }

  removeSack() {
    this.orderApi.removeGeneralRaportSack(this.sackId).subscribe(l => {
        this.popupVisible = false
        this.dataGrid.instance.refresh();
      }
    )
  }

  removePackageFromSack() {
    this.orderApi.removePackageFromSack(this.sackId, this.packageId).subscribe(l => {
        this.popupVisible = false
        this.dataGrid.instance.refresh();
      }
    )
  }

  buttonVisibleFn(e): boolean {
    return e.row.data.subStatusId == 3 || e.row.data.subStatusId == 4 || e.row.data.subStatusId == 5 || e.row.data.subStatusId == 6;
  }


  moveSuckFn() {
    this.orderApi.getSackId(this.sackId).subscribe(l => {
        this.cuvalSource = l;

      }
    )
    this.moveStatus = !this.moveStatus;
  }

  postSacks() {
    this.orderApi.postSacks(this.sackId, this.sackModel.sackId).subscribe(l => {
        this.popupVisible = false
        this.dataGrid.instance.refresh();
        this.moveStatus = false;
        this.sackModel = new SackListModel();
      }
    )
  }
}
