import {Component, OnInit, NgZone, ViewChild, ElementRef} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {PackageProviders} from 'src/app/_services/package.providers';
import {ToastrService} from 'ngx-toastr';
import {SackClient} from 'src/app/_models/sackClient.model';
import {Key} from 'src/app/_models/clients-package.model';
import {SendSack, SendingPackage} from 'src/app/_models/send-sack.model';
import {SackDetailKey, SackDetail} from 'src/app/_models/sack-detail.model';
import {PrintProviders} from 'src/app/_services/print.service';
import {MatDialog} from '@angular/material';
import {Barcode} from "../../_models/dyehouse/outgoingBarcode.model";
import {LoadingComponent} from 'src/app/components/loading/loading.component';

@Component({
  selector: 'app-fill-the-sack',
  templateUrl: './fill-the-sack.component.html',
  styleUrls: ['./fill-the-sack.component.scss']
})
export class FillTheSackComponent implements OnInit {
  @ViewChild('search') searchElement: ElementRef;
  clients: SackClient[] = [];
  selectedClient: SackClient;
  subOrders: Key[] = [];
  sendSackModel: SendSack;
  sendingPackageArray: SendingPackage[] = [];
  sackDetailModel: SackDetail = new SackDetail();
  weightArea: any;
  barcodeString = 'data:image/jpeg;base64,';
  isShowDetail = false;
  barcodeArray: Barcode[] = [];
  barcode: Barcode = new Barcode();
  getDataByService: any;
  ambargo = false;

  constructor(
    private fb: FormBuilder,
    private api: PackageProviders,
    private toastr: ToastrService,
    private printApi: PrintProviders,
    public dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.getSackClientAPI();
  }


  /***
   * Apiden Cari bilgisini çekmek için kullanılıyor:
   */
  getSackClientAPI() {
    this.api.getSackClient().subscribe(data => {
      this.clients = data;
    })
  }

  /***
   * Seçilen carinin id'sine erişmek için:
   */
  changeClientEvent(event) {
    this.selectedClient = new SackClient();
    this.selectedClient = this.findClientsPackage(event.target.value);
    this.getPackageByClientId(this.selectedClient.id);
  }

  findClientsPackage(clientID) {
    return this.clients.find(x => x.name === clientID);
  }

  /***
   * Seçilen carinin id'sine göre paketleri apiden çekmek için:
   * @param id: seçilen carinin id'si
   */
  getPackageByClientId(id) {
    this.api.getPackageByClientId(id).subscribe(data => {
      this.subOrders = data;
    })
  }

  /***
   * Çuval Oluştur butonuna basıldığında:
   mert*/
  clickCreateSack() {
    if (this.barcodeArray.length === 0 || this.selectedClient === null || this.selectedClient === undefined) {
      this.toastr.warning('Lütfen alanları eksiksiz doldurunuz.', 'UYARI', {
        timeOut: 3000
      });
    } else {
      this.setSackModelFor();
      this.createSackAPI();


    }

  }


  /***
   * Apiye post edilecek modelin doldurulması:
   */
  setSackModelFor() {
    this.sendSackModel = new SendSack();
    if (this.weightArea == "") {
      this.weightArea = 0;
    }
    this.sendSackModel.weight = this.weightArea;
    this.sendSackModel.clientId = this.selectedClient.id;
    this.sendSackModel.package = this.sendingPackageArray;
  }

  /***
   * Post etme işlemi için createSacksAPI çağrılıt. Return değeri olarak çuval detaylarını alır
   */
  createSackAPI() {

    this.api.createSacksAPI(this.sendSackModel).subscribe(data => {
      if (data != "x") {
        if (data == null) {
          this.ambargo = true;
          this.toastr.error('BU CARİDE AMBARGO VARDIR. ÇUVALLAMA YAPILAMAZ !!!', 'HATA!!', {
            timeOut: 3000
          });
        } else {
          this.sackDetailModel = data;
          this.isShowDetail = true;
          // this.sackDetailModel.key = data[0].key;
          this.barcodeString = this.barcodeString + data.arr;
          // this.
          this.showLoading();
          this.toastr.success('Çuvallama işlemi gerçekleştirilmiştir', 'BAŞARILI', {
            timeOut: 3000
          });

          this.sendingPackageArray.splice(0);
          this.weightArea = 0;
          this.barcodeArray.length = 0;
          this.printArray.length = 0;
          setTimeout(() => {
            this.printBarcode();
          }, 500);

        }

      }

      this.closeLoading();
    });
  }

  removeOnSackArea(sackArea) {
    // start :çuvallama alanına eklenen verileri  kaldırır
    const index = this.printArray.indexOf(sackArea, 0);
    if (index > -1) {
      this.barcodeArray.splice(index, 1);
      this.sendingPackageArray.splice(index, 1);
      this.printArray.splice(index, 1);
    }

  }

  printBarcode() {
    this.printApi.printBarcode('printBarcode');
  }

  /**
   *Her barkod okutulduğunda koşullara uygun olup olmadığını kontrol ediyor
   * @param event
   */

  printArray: string[] = [];

  keyDownFunction(event) {
    if (this.selectedClient != null && this.selectedClient != undefined) {
      this.barcode = new Barcode();
      const barcode = this.searchElement.nativeElement.value;

      if (this.barcodeArray.length != 0) {
        let isTrueOrFalse: boolean = true;
        this.barcodeArray.forEach(item => {
          if (barcode === item.BarcodeDetail) {
            this.toastr.warning('Bu kupon daha önceden okutulmuştur.', 'HATA', {
              timeOut: 3000
            });
            this.searchElement.nativeElement.value = '';
            isTrueOrFalse = false;
          }
        })
        if (isTrueOrFalse) {
          this.api.checkCuponsForSackComponent(barcode, this.selectedClient.id).subscribe(data => {
            this.getDataByService = data;
            if (this.getDataByService[0].toLocaleLowerCase() != 'ok') {
              this.toastr.warning(data, 'UYARI', {
                timeOut: 3000
              });
            }
            if (this.getDataByService[0].toLocaleLowerCase() === 'ok') {
              this.sendingPackageArray.push(this.getDataByService[1]);
              this.barcode.BarcodeDetail = barcode;
              this.barcodeArray.push(this.barcode);
              this.printArray.push(this.getDataByService[2]);
              this.searchElement.nativeElement.value = '';
            } else {
              this.searchElement.nativeElement.value = '';
            }
          });
        }
      } else {
        this.api.checkCuponsForSackComponent(barcode, this.selectedClient.id).subscribe(data => {
          this.getDataByService = data;
          if (this.getDataByService[0].toLocaleLowerCase() != 'ok') {
            this.toastr.warning(data, 'UYARI', {
              timeOut: 3000
            });
          }
          if (this.getDataByService[0].toLocaleLowerCase() === 'ok') {
            this.sendingPackageArray.push(this.getDataByService[1]);
            this.printArray.push(this.getDataByService[2]);
            this.barcode.BarcodeDetail = barcode;
            this.barcodeArray.push(this.barcode);
            this.searchElement.nativeElement.value = '';
          } else {
            this.searchElement.nativeElement.value = '';
          }
        });
      }
    } else {
      this.toastr.warning('Lütfen Firma Seçiniz!', 'UYARI', {
        timeOut: 3000
      });
    }
  }

  showLoading() {
    const dialogRef = this.dialog.open(LoadingComponent, {
      disableClose: true,
      width: '500px',
      data: {}
    });
  }

  closeLoading() {
    this.dialog.closeAll();
  };


}
