import {Injectable} from '@angular/core';
import {ROUTING} from '../../shared/routing';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  toggled = false;
  menus = [
    {
      title: 'İşlemler',
      type: 'header'
    },
    {
      title: 'Siparişlerim',
      icon: 'fa fa-edit',
      active: true,
      type: 'dropdown',
      submenus: [
        {
          title: 'Siparişleri Listele',
          routerLink: ROUTING.REQUEST_LIST
        },
        {
          title: '2. Kalite Raporu',
          routerLink: ROUTING.SECOND_QULITY_REPORT
        }
      ]
    },
    {
      title: 'Boyahane',
      icon: 'fas fa-brush',
      active: false,
      type: 'dropdown',
      submenus: [
        {
          title: 'Boyahane Öncesi',
          routerLink: ROUTING.BEFORE_DYEHOUSE
        },
        {
          title: 'Boyahane Öncesi Listesi',
          routerLink: ROUTING.BEFORE_DYEHOUSE_LIST
        },
        {
          title: 'Boyahane İçin Hazırlık',
          routerLink: ROUTING.SEARCH_VEHICLE_FOR_DYEHOUSE
        },{
          title: 'Boyahaneye Gönder',
          routerLink: ROUTING.SEND_DYE_HOUSE_READY
        },
        {
          title: 'Araç Ağırlığı',
          routerLink: ROUTING.VEHICLE_WEIGHT
        },
        {
          title: 'Sipariş Kabul',
          routerLink: ROUTING.COME_BACK_TO_DYEHOUSE
        },
        {
          title: 'Boyahane Raporu',
          routerLink: ROUTING.DYEHOUSE_REPORT2
        },
        {
          title: 'Boyahaneye Gitmeyenler',
          routerLink: ROUTING.NOT_GOING_OUTGOING_REPORT
        },
        {
          title: 'Boyahanedeki Kuponlar',
          routerLink: ROUTING.IN_OUTGOIN_CUPONS_REPORT
        },
        {
          title: 'Boyahane Hata Raporu',
          routerLink: ROUTING.DYEHOUSE_LOG
        },
        {
          title: 'Boyahaneden Dönenler',
          routerLink: ROUTING.BACK_FROM_DYEHOUSE
        },
        {
          title: 'Boyahane Durum Raporu',
          routerLink: ROUTING.DYEHOUSE_MISSINGS_REPORT
        },
      ]
    },
    {
      title: 'Paket / Çuval',
      icon: 'fas fa-box-open',
      active: false,
      type: 'dropdown',
      submenus: [
        {
          title: 'Siparişleri Paketle',
          routerLink: ROUTING.PACKAGE
        },
        {
          title: 'Siparişleri Çuvalla',
          routerLink: ROUTING.MAKE_SACK
          // routerLink: ROUTING.FILL_THE_SACK
        },
        {
          title: 'Çuval Kapat',
          routerLink: ROUTING.CLOSE_SACK
          // routerLink: ROUTING.FILL_THE_SACK
        },
        {
          title: 'İşlemdeki Çuvallar',
          routerLink: ROUTING.SACKS_IN_PROGRESSS
          // routerLink: ROUTING.FILL_THE_SACK
        },
        {
          title: 'Çuval Ağırlığı',
          routerLink: ROUTING.SACK_WEIGHT
        },
        {
          title: '2.Kalite Çuvalla',
          routerLink: ROUTING.MAKE_SECOND_QUALITY_SACK
        },
      ]
    },
    {
      title: 'Sevk',
      icon: 'fas fa-shipping-fast',
      active: false,
      type: 'dropdown',
      submenus: [
        {
          title: 'Sevk Et',
          routerLink: ROUTING.DISPATCH
        },
        {
          title: 'Sevk Raporu',
          routerLink: ROUTING.DISPATCH_RAPORT
        },
        {
          title: 'Sevke Hazır Kuponlar',
          routerLink: ROUTING.READYFOR_SHIPPING_REPORT
        }
      ]
    },
    {
      title: 'Kontrol Noktası',
      icon: 'fas fa-file',
      active: false,
      type: 'dropdown',
      submenus: [
        {
          title: 'İlk Kontrol Noktası',
          routerLink: ROUTING.FIRST_CONTROL_DOT
        },
      ]
    },
    {
      title: 'Rapor',
      icon: 'fas fa-file-alt',
      active: false,
      type: 'dropdown',
      submenus: [
        {
          title: 'Firma Kupon Raporu',
          routerLink: ROUTING.CUPONS_BY_CUSTOMERS_REPORT
        },
        {
          title: 'Patron Ekranı',
          routerLink: ROUTING.BOSS_SCREEN
        },
        {
          title: 'Genel Rapor',
          routerLink: ROUTING.GENERAL_RAPORT
        },
      ]
    },
    {
      title: 'Parametreler',
      icon: 'fa fa-edit',
      active: true,
      type: 'dropdown',
      submenus: [
        {
          title: 'Araç Algoritması',
          routerLink: ROUTING.VEHICLE_ALGORITHM
        },{
          title: 'Sevk Algoritması',
          routerLink: ROUTING.SACK_ALGORITM
        },
        {
          title: 'Metraj Okuma',
          routerLink: ROUTING.READ_METERS
        },
        {
          title: 'Yazıcılar',
          routerLink: ROUTING.PRINTER
        },
        {
          title: 'Mobil Cihazlar',
          routerLink: ROUTING.MOBILE_DEVICES
        },
        {
          title: 'Mobil Cihaz - Modüller',
          routerLink: ROUTING.MOBILE_DEVICE_MODULES
        },{
          title: 'Cariler',
          routerLink: ROUTING.CLIENT
        },
      ]
    },
  ];
  _hasBackgroundImage = true;

  get hasBackgroundImage() {
    return this._hasBackgroundImage;
  }

  set hasBackgroundImage(hasBackgroundImage) {
    this._hasBackgroundImage = hasBackgroundImage;
  }

  toggle() {
    this.toggled = !this.toggled;
  }

  getSidebarState() {
    return this.toggled;
  }

  setSidebarState(state: boolean) {
    this.toggled = state;
  }

  getMenuList() {
    return this.menus;
  }
}
