import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<LoadingComponent>,) { }

  ngOnInit() {
  }

  // const dialogRef = this.dialog.open(RemoveItemComponent, {
  //   width: '500px',
  //   data: { id: formData.data.id, popupType: POPUPTYPE.SUBTRANSFER }
  // });
  // dialogRef.afterClosed().subscribe(result => {
  //   this.getSubOrderDetail(this.orderId);
  // });

}
