import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { CarWeight } from '../_models/dyehouse/car-weight.model';
import { endpoints } from '../shared/endpoints';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { ToastrService } from 'ngx-toastr';
import { SendDyehouse } from '../_models/dyehouse/send-dyehouse.model';
import { OutgoingBarcode } from '../_models/dyehouse/outgoingBarcode.model';
import { OutgoingCompanies } from "../_models/dyehouse/outgoingCompanies.model";
import { ROUTING } from "../shared/routing";
import { Color } from "../_models/color.model";
import {CheckBarcodeDTO} from "../_models/CheckBarcodeDTO";
import {DryVechileDTOModel} from "../_models/dyehouse/DryVechileDTO.model";
import {DryVehicleComponentModel} from "../_models/DryVehicleComponent.model";
import {DryVehicleComponentDTOModel} from "../_models/dyehouse/DryVehicleComponentDTO.model";


@Injectable()
export class DyehouseProviders {
  header: HttpHeaders;
  private currentUserSubject;

  constructor(private http: HttpClient,
    private router: Router,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService) {
    this.setHeader();
  }

  setHeader() {
    this.currentUserSubject = (JSON.parse(localStorage.getItem('currentUser')));
    if (this.currentUserSubject) {
      this.header = new HttpHeaders()
        .set('Authorization', `Bearer ${(this.currentUserSubject.token)}`)
        .set('Content-Type', 'application/json');
    }

  }

  async getReports() {
    return await this.http.get(endpoints.report.getOutGoingReport2.path, { headers: this.header }).toPromise()
  }

  getVehicleWeightsWithVehicle() {
    return this.http.get(endpoints.vehiclesWeights.getVehicleWeightsWithVehicle.path,
      { headers: this.header }).pipe(
        map((data: CarWeight[]) => {
          return data;
        }), catchError(error => {
          this.toastr.error(error, 'HATA', {
            timeOut: 3000
          });
          return throwError('getVehicle hata!');
        })
      );
  }
  carBarcode(carCode :string,st : boolean) {
    return this.http.get(st ? endpoints.outgoingVehicle.carBarcodeTermal.path+carCode : endpoints.outgoingVehicle.carBarcodeTermal2.path+carCode,
      { headers: this.header}).pipe(
        map((data: CarWeight[]) => {
          this.toastr.success('Etiket Gönderildi', 'BAŞARILI', {
            timeOut: 3000
          });
          return data;
        }), catchError(error => {
          this.toastr.error(error, 'HATA', {
            timeOut: 3000
          });
          return throwError('getVehicle hata!');
        })
      );
  }

  postVehicleWeight(vehicle: CarWeight): Observable<CarWeight> {
    return this.http.post<CarWeight>(endpoints.vehiclesWeights.postVehicleWeight.path,
      vehicle,
      { headers: this.header })
      .pipe(
        catchError(error => {
          this.toastr.error(error, 'HATA', {
            timeOut: 3000
          });
          return throwError('postVehicleWeight hata!');
        })
      );
  }

  getVehicleWithWeights(): Observable<CarWeight[]> {
    return this.http.get(endpoints.vehiclesWeights.getVehiclesWeight.path,
      { headers: this.header }).pipe(
        map((data: CarWeight[]) => {
          return data;
        }), catchError(error => {
          this.toastr.error(error, 'HATA', {
            timeOut: 3000
          });
          return throwError('getVehicle hata!');
        })
      );
  }

  postVehicleBarcodes(vehicle: CarWeight): Observable<CarWeight> {
    return this.http.post<CarWeight>(endpoints.outgoingVehicle.barcodesInComing.path,
      vehicle,
      { headers: this.header })
      .pipe(
        catchError(error => {
          this.toastr.error(error, 'HATA', {
            timeOut: 3000
          });
          return throwError('postVehicleWeight hata!');
        })
      );
  }

  readFromLogoOutgoingClients() {
    return this.http.get(endpoints.readFromLogoOutgoingClients.path, { headers: this.header }).pipe(
      map((data) => {
        return data;
      }), catchError(error => {
        this.toastr.error('Beklenmedik bir hata ile karşılaşıldı', 'HATA', {
          timeOut: 3000
        });
        return throwError('readFromLogoOutgoingClients hata!');
      })
    );
  }


  getVehicle() {
    return this.http.get(endpoints.Vehicles.getVehicles.path, { headers: this.header }).pipe(
      map((data: SendDyehouse[]) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('getVehicle hata!');
      })
    );
  }

  getColor() {
    return this.http.get(endpoints.color.getColors.path, { headers: this.header }).pipe(
      map((data: Color[]) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('getVehicle hata!');
      })
    );
  }

  postOutgoingBarcode(outgoingBarcode: OutgoingBarcode) {
    let body: string = JSON.stringify(outgoingBarcode);
    return this.http.post(
      endpoints.outgoingVehicle.postBarcodeOutgoing.path,
      body,
      { headers: this.header }
    ).pipe(
      catchError(error => {
        this.toastr.error(error, 'Hata!', {
          timeOut: 3000
        });
        return "x";
        // return throwError('addNewTicket hata!');
      })
    );
  }

  postIncomingBarcode(outgoingBarcode: OutgoingBarcode): Observable<any> {
    return this.http.post<any>(
      endpoints.outgoingVehicle.barcodesInComing.path,
      outgoingBarcode,
      { headers: this.header }
    ).pipe(
      catchError(error => {
        this.toastr.error(error, 'Hata!', {
          timeOut: 3000
        });
        return throwError('addNewTicket hata!');
      })
    );
  }

  getOutgoingClients() {
    return this.http.get(endpoints.clients.getOutgoingClients.path, { headers: this.header }).pipe(
      map((data: OutgoingCompanies[]) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('getOutgoingClients hata!');
      })
    );
  }

  getColorByCuponBarcode(barcode) {
    return this.http.get(endpoints.orderSubDetails.getColorByCuponBarcode.path, {
      headers: this.header,
      params: { barcode: barcode }
    }).pipe(
      map((data: string) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('getOutgoingClients hata!');
      })
    );
  }

  checkCuponForOutgoingVehicle(barcode, color) {
    return this.http.get(endpoints.orderSubDetails.checkCuponForOutgoingVehicle.path, {
      headers: this.header,
      params: { barcode: barcode, color: color }
    }).pipe(
      map((data: string) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('getOutgoingClients hata!');
      })
    );
  }

  checkCuponForInComingVehicle(barcode) {
    return this.http.get(endpoints.orderSubDetails.checkCuponForInComingVehicle.path, {
      headers: this.header,
      params: { barcode: barcode }
    }).pipe(
      map((data: string) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('getOutgoingClients hata!');
      })
    );
  }

  checkCuponForReturnVehicle(barcode, color) {
    return this.http.get(endpoints.orderSubDetails.checkCuponForReturnVehicle.path, {
      headers: this.header,
      params: { barcode: barcode, color: color }
    }).pipe(
      map((data: string) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('getOutgoingClients hata!');
      })
    );
  }

  updateNetWeight(id, weight) {
    return this.http.get(endpoints.outgoingVehicle.updateNetWeight.path, {
      headers: this.header,
      params: { code: id, weight: weight }
    }).pipe(
      map((data: string) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('updateNetWeight hata!');
      })
    );
  }

  checkBarcodeForBefore(dto : CheckBarcodeDTO ){
    return this.http.post<DryVechileDTOModel>(endpoints.controlDot.checkBeforeDye.path,
      dto,
      { headers: this.header })
      .pipe(
        catchError(error => {
          this.toastr.error(error, 'HATA', {
            timeOut: 3000
          });
          return throwError('postVehicleWeight hata!');
        })
      );
  }

  searchVehicleForDyehouse(dto : CheckBarcodeDTO ){
    return this.http.post<DryVehicleComponentModel[]>(endpoints.controlDot.searchVehicleForDyehouse.path,
      dto,
      { headers: this.header })
      .pipe(
        catchError(error => {
          this.toastr.error(error, 'HATA', {
            timeOut: 3000
          });
          return throwError('postVehicleWeight hata!');
        })
      );
  }
  outGoingVehiclesWithCar(dto : DryVehicleComponentDTOModel ){
    return this.http.post<any>(endpoints.controlDot.outGoingVehiclesWithCar.path,
      dto,
      { headers: this.header })
      .pipe(
        catchError(error => {
          this.toastr.error(error, 'HATA', {
            timeOut: 3000
          });
          return throwError('postVehicleWeight hata!');
        })
      );
  }

  sendToDyeConfirm(dto : CheckBarcodeDTO ){
    return this.http.post<any>(endpoints.controlDot.sendToDyeConfirm.path,
      dto,
      { headers: this.header })
      .pipe(
        catchError(error => {
          this.toastr.error(error, 'HATA', {
            timeOut: 3000
          });
          return throwError('postVehicleWeight hata!');
        })
      );
  }
  sendDyReady (id : number){
    return this.http.get(endpoints.controlDot.sendDyReady.path + '?carCode='+id,
      { headers: this.header }).pipe(
      map((data: any[]) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('getVehicle hata!');
      })
    );
  }
}
