import {dxPivotGridSummaryCell} from "devextreme/ui/pivot_grid";

export class DevxPivotModel {
  allowCrossGroupCalculation?: boolean;
  allowExpandAll?: boolean;
  allowFiltering?: boolean;
  allowSorting?: boolean;
  allowSortingBySummary?: boolean;
  area?: string;
  areaIndex?: number;
  calculateCustomSummary?: { [key: string]: any };
  caption?: string;
  customizeText?: { [key: string]: any };
  dataField?: string;
  dataType?: DataType;
  displayFolder?: string;
  expanded?: boolean;
  filterType?: FilterType;
  filterValues?: any[];
  format?: string;
  groupIndex?: number;
  groupInterval?: number | GroupIntervalEnum;
  groupName?: string;
  headerFilter?: HeaderFilter;
  isMeasure?: boolean;
  name?: string;
  runningTotal?: RunningTotal;
  selector?: Function;
  showGrandTotals?: boolean;
  showTotals?: boolean;
  showValues?: boolean;
  sortBy?: SortBy;
  sortBySummaryField?: string;
  sortBySummaryPath?: Array<number | string>;
  sortingMethod?: { [key: string]: any };
  sortOrder?: SortOrder;
  summaryDisplayMode?: SummaryDisplayMode;
  summaryType?: string;
  visible?: boolean;
  width?: number;
  wordWrapEnabled?: boolean;
}

export enum DataType {
  Date = "date",
  Number = "number",
  String = "string",
}

export enum FilterType {
  Exclude = "exclude",
  Include = "include",
}

export enum GroupIntervalEnum {
  Day = "day",
  DayOfWeek = "dayOfWeek",
  Month = "month",
  Quarter = "quarter",
  Year = "year",
}

export interface HeaderFilter {
  allowSearch?: boolean;
  height?: number;
  width?: number;
}

export enum RunningTotal {
  Column = "column",
  Row = "row",
}

export interface Function {
  arguments: any;
  caller: Function;
  length: number;
  prototype: any;
}

export enum SortBy {
  DisplayText = "displayText",
  None = "none",
  Value = "value",
}

export enum SortOrder {
  Asc = "asc",
  Desc = "desc",
}

export enum SummaryDisplayMode {
  AbsoluteVariation = "absoluteVariation",
  PercentOfColumnGrandTotal = "percentOfColumnGrandTotal",
  PercentOfColumnTotal = "percentOfColumnTotal",
  PercentOfGrandTotal = "percentOfGrandTotal",
  PercentOfRowGrandTotal = "percentOfRowGrandTotal",
  PercentOfRowTotal = "percentOfRowTotal",
  PercentVariation = "percentVariation",
}
