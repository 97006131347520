import { Component, ElementRef, OnInit, ViewChild, NgZone } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { DyehouseProviders } from 'src/app/_services/dyehouse.providers';
import { ToastrService } from 'ngx-toastr';
import { SendDyehouse } from 'src/app/_models/dyehouse/send-dyehouse.model';
import { Barcode, OutgoingBarcode } from 'src/app/_models/dyehouse/outgoingBarcode.model';
import { Observable, ReplaySubject, Subject, throwError } from "rxjs";
import { catchError, map, startWith } from 'rxjs/operators';
import { OutgoingCompanies } from "../../_models/dyehouse/outgoingCompanies.model";
import { PrintProviders } from "../../_services/print.service";
import { Color } from "../../_models/color.model";
import { endpoints } from "../../shared/endpoints";
import { DyhouseBarcode } from 'src/app/_models/dyehouse/dyhouse-barcode-model';
import { LoadingComponent } from 'src/app/components/loading/loading.component';
import { MatDialog } from '@angular/material';
import { ErrorComponent } from 'src/app/components/error/error.component';


@Component({
  selector: 'app-send-dyhouse',
  templateUrl: './send-dyhouse.component.html',
  styleUrls: ['./send-dyhouse.component.scss']
})
export class SendDyhouseComponent implements OnInit {
  @ViewChild('search') searchElement: ElementRef;
  checkedScanBarkod = false;
  colorArray: Color[] = [];
  sendDyhouseModel: OutgoingBarcode;
  model: SendDyehouse;
  barcodeArray: Barcode[] = [];
  barcode: Barcode = new Barcode();
  carName: string = '';
  selectedVehicle: Color = new Color();
  vehicleControl = new FormControl();
  companiesControl = new FormControl();
  filteredVehicleName: Observable<Color[]>;
  filteredCompaniesName: Observable<OutgoingCompanies[]>;
  outgoingCompanies: OutgoingCompanies[] = [];
  selectedCompanies: OutgoingCompanies = new OutgoingCompanies();
  getColorByService: string;
  isShow: boolean = false;
  carColor: string;
  companyName: string;


  constructor(private fb: FormBuilder,
    private api: DyehouseProviders,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private zone: NgZone,
    private printApi: PrintProviders) {

  }

  ngOnInit() {
    this.getColor();
    this.getOutgoingClients();
    setTimeout(() => {
      this.filteredVehicleName = this.vehicleControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterV(value))
      );
      this.filteredCompaniesName = this.companiesControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterC(value))
      );
    }, 1000)

  }

  checkScanBarcode(e) {
    this.checkedScanBarkod = !this.checkedScanBarkod;
    if (e.target.checked) {
      this.searchElement.nativeElement.style.display = 'block';
      setTimeout(() => { // this will make the execution after the above boolean has changed
        this.searchElement.nativeElement.focus();
      }, 0);
    } else if (!e.target.checked) {
      setTimeout(() => { // this will make the execution after the above boolean has changed
        this.searchElement.nativeElement.style.display = 'none';
      }, 0);
    }
  }

  focusOutFunction() {
    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.searchElement.nativeElement.focus();
    }, 0);
  }

  search(e) {

  }

  keyDownFunction(event) {
    if (this.carColor != "") {
      if (this.isShow === false) { //BOYAHANEYE GÖNDERMEK İÇİN
        this.barcode = new Barcode();
        const barcode = this.searchElement.nativeElement.value;
        if (this.barcodeArray.length != 0) {
          let isTrueOrFalse: boolean = true;
          this.barcodeArray.forEach(item => {
            if (barcode === item.BarcodeDetail) {
              // this.toastr.warning('Bu kupon daha önceden okutulmuştur.', 'HATA', {
              //   timeOut: 3000
              // });
              /// ---------------- popup çıkart ------------------
              const dialogRef = this.dialog.open(ErrorComponent, {
                disableClose: true,
                width: '700px',
                panelClass: 'custom-modalbox',
                data: {
                  text: 'Bu Kupon Daha Önce Okutulmuştur!'
                }
              });
              this.searchElement.nativeElement.value = '';
              isTrueOrFalse = false;
            }
          })
          if (isTrueOrFalse) {
            this.api.checkCuponForOutgoingVehicle(barcode, this.colorName).subscribe(data => {
              this.getColorByService = data;
              if (this.getColorByService[0].toLocaleLowerCase() != 'ok') {
                // this.toastr.warning(data, 'UYARI', {
                //   timeOut: 3000
                // });
                const dialogRef = this.dialog.open(ErrorComponent, {
                  disableClose: true,
                  width: '700px',
                  panelClass: 'custom-modalbox',
                  data: {
                    text: data,
                  }
                });
              }
              if (this.getColorByService[0].toLocaleLowerCase() === 'ok') {
                this.barcode.BarcodeDetail = barcode;
                this.barcodeArray.push(this.barcode);
                this.searchElement.nativeElement.value = '';
              } else {
                this.searchElement.nativeElement.value = '';
              }
            });
          }
        } else {
          this.api.checkCuponForOutgoingVehicle(barcode, this.colorName).subscribe(data => {
            this.getColorByService = data;
            if (this.getColorByService[0].toLocaleLowerCase() != 'ok') {
              const dialogRef = this.dialog.open(ErrorComponent, {
                disableClose: true,
                width: '700px',
                panelClass: 'custom-modalbox',
                data: {
                  text: data,
                }
              });
              // this.toastr.warning(data, 'UYARI', {
              //   timeOut: 3000
              // });
            }
            if (this.getColorByService[0].toLocaleLowerCase() === 'ok') {
              this.barcode.BarcodeDetail = barcode;
              this.barcodeArray.push(this.barcode);
              this.searchElement.nativeElement.value = '';
            } else {
              this.searchElement.nativeElement.value = '';
            }
          });
        }
      } else { //BOYAHANE İADESİ İÇİN
        this.barcode = new Barcode();
        const barcode = this.searchElement.nativeElement.value;
        if (this.barcodeArray.length != 0) {
          let isTrueOrFalse: boolean = true;
          this.barcodeArray.forEach(item => {
            if (barcode === item.BarcodeDetail) {
              // this.toastr.warning('Bu kupon daha önceden okutulmuştur.', 'HATA', {
              //   timeOut: 3000
              // });
              const dialogRef = this.dialog.open(ErrorComponent, {
                disableClose: true,
                width: '700px',
                panelClass: 'custom-modalbox',
                data: {
                  text: 'Bu kupon daha önceden okutulmuştur.',
                }
              });
              this.searchElement.nativeElement.value = '';
              isTrueOrFalse = false;
            }
          })
          if (isTrueOrFalse) {
            this.api.checkCuponForReturnVehicle(barcode, this.colorName).subscribe(data => {
              this.getColorByService = data;
              if (this.getColorByService[0].toLocaleLowerCase() != 'ok') {
                // this.toastr.warning(data, 'UYARI', {
                //   timeOut: 3000
                // });
                const dialogRef = this.dialog.open(ErrorComponent, {
                  disableClose: true,
                  width: '700px',
                  panelClass: 'custom-modalbox',
                  data: {
                    text: data,
                  }
                });
              }
              if (this.getColorByService[0].toLocaleLowerCase() === 'ok') {
                this.barcode.BarcodeDetail = barcode;
                this.barcodeArray.push(this.barcode);
                this.searchElement.nativeElement.value = '';
              } else {
                this.searchElement.nativeElement.value = '';
              }
            });
          }
        } else {
          this.api.checkCuponForReturnVehicle(barcode, this.colorName).subscribe(data => {
            this.getColorByService = data;
            if (this.getColorByService[0].toLocaleLowerCase() != 'ok') {
              // this.toastr.warning(data, 'UYARI', {
              //   timeOut: 3000
              // });
              const dialogRef = this.dialog.open(ErrorComponent, {
                disableClose: true,
                width: '700px',
                panelClass: 'custom-modalbox',
                data: {
                  text: data,
                }
              });
            }
            if (this.getColorByService[0].toLocaleLowerCase() === 'ok') {
              this.barcode.BarcodeDetail = barcode;
              this.barcodeArray.push(this.barcode);
              this.searchElement.nativeElement.value = '';
            } else {
              this.searchElement.nativeElement.value = '';
            }
          });
        }
      }
    }
    else {
      this.toastr.warning("Rengi Seçmeden Kupon Ekleyemezsiniz.", 'UYARI', {
        timeOut: 3000
      });
    }
  }

  generateUniqeId() {
    var uniqeId = "";
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWYZ0123456789';
    const charactersLength = characters.length;
    for (var i = 0; i < 4; i++) {
      uniqeId += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return uniqeId
  }

  /**
   * SELECTBOX CHANGE DE ÇALIŞIR
   * @param event
   */

  readFromLogoOutgoingClients() {
    this.api.readFromLogoOutgoingClients().subscribe(data => {
      this.toastr.success('Boyahane Firmaları Çekildi.', 'BAŞARILI', {
        timeOut: 3000
      });
    })

    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.getOutgoingClients();
    }, 500);

    setTimeout(() => {
      this.filteredVehicleName = this.vehicleControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterV(value))
      );
      this.filteredCompaniesName = this.companiesControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterC(value))
      );
    }, 1000)
  }

  changeClientOptions(event) {
    this.selectedCompanies = this.outgoingCompanies.find(data => data.name === event.option.value);
    //this.selectedVehicleId = event.target.value;
  }

  getColor() {
    this.api.getColor().subscribe(data => {
      this.colorArray = data;
    });
  }

  getOutgoingClients() {
    this.api.getOutgoingClients().subscribe(data => {
      this.outgoingCompanies = data;
    });
  }

  dyhouseBarcode: any;
  onClickSubmit() {
    if (this.companyId && this.colorId && this.companyName != "" && this.carColor != "") {
      this.sendDyhouseModel = new OutgoingBarcode();
      this.sendDyhouseModel.vehicleId = this.colorId;
      this.sendDyhouseModel.clientId = this.companyId;
      this.sendDyhouseModel.orderSubDetails = this.barcodeArray;
      this.sendDyhouseModel.isReturned = this.isShow;

      if (this.barcodeArray.length > 0) {
        this.showLoading();
        this.api.postOutgoingBarcode(this.sendDyhouseModel).subscribe(data => {
          if (data != "x") {
            this.dyhouseBarcode = data;
            this.toastr.success('Kuponlar başarı ile araca yüklendi.', 'BAŞARILI', {
              timeOut: 3000
            });
            this.zone.runOutsideAngular(() => {
              //location.reload();
              this.carName = null;
              this.selectedVehicle.id = null;
              this.barcodeArray = [];
              this.isShow = false;
            })
            this.vehicleControl.setValue("");
            this.selectedVehicle.id = undefined;
            this.carName = '';
            setTimeout(() => {
              this.printBarcode()
            }, 500);
          }

          this.closeLoading();
        });
      } else {
        this.toastr.warning('Önce barkod okutmalısınız.', 'UYARI', {
          timeOut: 3000
        });
      }
    }
    else {
      this.toastr.warning('Tüm Gerekli Alanları Doldurmalısınız!.', 'UYARI', {
        timeOut: 3000
      });
    }

    this.getColor();
  }

  cleanBarcodeArray(item) {
    const index = this.barcodeArray.indexOf(item, 0);
    if (index > -1) {
      this.barcodeArray.splice(index, 1);
    }
  }

  color;
  private _filterV(value: string): Color[] {
    const filterValue = value.toLowerCase();
    this.color = value;
    return this.colorArray.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterC(value: string): OutgoingCompanies[] {
    const filterValue = value.toLowerCase();
    return this.outgoingCompanies.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  printBarcode() {
    this.printApi.printBarcode('printTable');
  }

  valueChange() {
    this.barcodeArray = [];
  }

  clickIsShow() {
    if (this.checkedScanBarkod === true) {
      this.checkedScanBarkod = false;
    }
    if (this.isShow) {
      this.isShow = false;
    } else {
      this.isShow = true;
    }
  }

  colorName;
  colorId;
  vehicleValidation() {
    let myData = this.carColor
    let datas;
    let bool;
    this.filteredVehicleName.subscribe(data => {
      datas = data;
    })
    datas.forEach(element => {
      if (myData == element.name) {
        bool = true
        this.colorName = element.name;
        this.colorId = element.id;
      }
    });

    if (bool != true) {
      this.vehicleControl.setValue("");
      this.colorName = undefined;
      this.colorId = undefined;
    }
  }

  companyId;
  companyValidation() {
    let myData = this.companyName
    let datas;
    let bool;
    this.filteredCompaniesName.subscribe(data => {
      datas = data;
    })
    datas.forEach(element => {
      if (myData == element.name) {
        bool = true
        this.companyId = element.id;
      }
    });

    if (bool != true) {
      this.companiesControl.setValue("");
      this.companyId = undefined;
    }
  }

  showLoading() {
    const dialogRef = this.dialog.open(LoadingComponent, {
      disableClose: true,
      width: '700px',
      data: {}
    });
  }

  closeLoading() {
    this.dialog.closeAll();
  };


}
