import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {DevExpressProviders} from "../../_services/devexpress.providers";
import {servers} from "../../shared/configuration";
import {DxChartComponent, DxDataGridComponent, DxPivotGridComponent} from "devextreme-angular";
import {DataType, DevxPivotModel, FilterType, SortOrder} from "../../_models/devxPivot.model";
import {DevxReporSelectorModel} from "../../_models/devxReporSelector.model";
import {endpoints} from "../../shared/endpoints";
import {AuthenticationService} from "../../_services";
import {DxoStateStoringComponent} from "devextreme-angular/ui/nested";

@Component({
  selector: 'app-boss',
  templateUrl: './boss.component.html',
  styleUrls: ['./boss.component.scss']
})
export class BossComponent implements AfterViewInit {
  @ViewChild('loader') loader: DxoStateStoringComponent;

  reportNames: DevxReporSelectorModel[] = [];
  selectedReport: DevxReporSelectorModel = new DevxReporSelectorModel();
  sReport: DevxReporSelectorModel = new DevxReporSelectorModel();
  drillDownDataSource: any;
  salesPopupVisible = false;
  salesPopupTitle = "";
  searchModeOption: string = "contains";
  searchExprOption: any = "reportName";
  searchTimeoutOption: number = 200;
  minSearchLengthOption: number = 0;
  newSelectReport: DevxReporSelectorModel = new DevxReporSelectorModel();
  stateDevx: any;
  // @ts-ignore
  @ViewChild(DxPivotGridComponent, {static: false}) pivotGrid: DxPivotGridComponent;
  // @ts-ignore
  @ViewChild(DxChartComponent, {static: false}) chart: DxChartComponent;
  dataSource: any;
  fields: DevxPivotModel[] = [];
  @ViewChild("drillDownDataGrid") drillDownDataGrid: DxDataGridComponent;

  constructor(
    private devApi: DevExpressProviders,
    private authenticationService: AuthenticationService
  ) {
    this.fieldGenetor();
    this.dataSource = this.devApi.readForDevXPivot('id', servers.real + '/orderSubDetails/boss', this.fields);
    this.customizeTooltip = this.customizeTooltip.bind(this);
    // this.loadState = this.loadState.bind(this);
    // this.selectBoxChange = this.selectBoxChange.bind(this);
    this.getReportNames();
  }

  getReportNames() {
    let l: DevxReporSelectorModel = new DevxReporSelectorModel();
    l.reportName = "Varsayılan Rapor";
    this.reportNames[0] = l;
    this.devApi.readAllModels(endpoints.devxStorage.reportNames.path + 'bossScreenPivot/' + this.authenticationService.currentUserValue.id).subscribe(a => {
      this.reportNames = a;
      this.sReport = this.reportNames[0];
    });
  }

  fieldGenetor() {
    let f: DevxPivotModel = new DevxPivotModel();
    f.area = "row";
    f.dataField = "orderDetail.order.client.name";
    f.sortOrder = SortOrder.Desc;
    f.caption = "Cari";
    f.expanded = true;
    this.fields.push(f);
    /* let f4: DevxPivotModel = new DevxPivotModel();
     f4.area = "column";
     f4.dataField = "orderDetail.order.orderdate";
     f4.caption = "Sipariş Tarihi";
     f4.dataType = DataType.Date;
     f4.expanded = true;
     this.fields.push(f4);*/
    let f2: DevxPivotModel = new DevxPivotModel();
    f2.area = "column";
    f2.dataField = "subStatus.name";
    f2.caption = "Durum";
    f2.expanded = true;
    f2.filterType = FilterType.Exclude;
    f2.filterValues = ['Sevk Edildi'];
    this.fields.push(f2);
    let f3: DevxPivotModel = new DevxPivotModel();
    f3.area = "data";
    f3.dataField = "id";
    f3.caption = "Adet";
    f3.expanded = true;
    f3.summaryType = "count";
    this.fields.push(f3);
    let f6: DevxPivotModel = new DevxPivotModel();
    f6.area = "data";
    f6.dataField = "metres";
    f6.caption = "Metraj";
    f6.expanded = true;
    f6.summaryType = "sum";
    this.fields.push(f6);
    let f5: DevxPivotModel = new DevxPivotModel();
    f5.area = "filter";
    f5.dataField = "orderDetail.order.orderdate";
    f5.caption = "Sipariş Tarihi";
    f5.dataType = DataType.Date;
    f5.expanded = true;
    this.fields.push(f5);
    let f7: DevxPivotModel = new DevxPivotModel();
    f7.dataField = "machine.name";
    f7.caption = "Makina";
    this.fields.push(f7);
    let f8: DevxPivotModel = new DevxPivotModel();
    f8.dataField = "orderDetail.amount";
    f8.caption = "Sipariş Kalem Miktarı";
    this.fields.push(f8);
    let f9: DevxPivotModel = new DevxPivotModel();
    f9.dataField = "orderDetail.cuponStatus";
    f9.caption = "Kupon Durumu";
    this.fields.push(f9);
    let f10: DevxPivotModel = new DevxPivotModel();
    f10.dataField = "orderDetail.item.altFloor";
    f10.caption = "Alt Zemin";
    this.fields.push(f10);
    let f11: DevxPivotModel = new DevxPivotModel();
    f11.dataField = "orderDetail.item.color";
    f11.caption = "Zemin Renkleri";
    this.fields.push(f11);
    let f12: DevxPivotModel = new DevxPivotModel();
    f12.dataField = "orderDetail.item.floor";
    f12.caption = "Zemin";
    this.fields.push(f12);
    let f13: DevxPivotModel = new DevxPivotModel();
    f13.dataField = "orderDetail.item.name";
    f13.caption = "Stok Adı";
    this.fields.push(f13);
    let f14: DevxPivotModel = new DevxPivotModel();
    f14.dataField = "orderDetail.item.pattern";
    f14.caption = "Desen";
    this.fields.push(f14);
    let f15: DevxPivotModel = new DevxPivotModel();
    f15.dataField = "orderDetail.item.rope";
    f15.caption = "İp";
    this.fields.push(f15);
    let f16: DevxPivotModel = new DevxPivotModel();
    f16.dataField = "orderDetail.item.ropeThickness";
    f16.caption = "Halat Kalınlığı";
    this.fields.push(f16);
    let f17: DevxPivotModel = new DevxPivotModel();
    f17.dataField = "orderDetail.item.strike";
    f17.caption = "Vuruş";
    this.fields.push(f17);
    let f18: DevxPivotModel = new DevxPivotModel();
    f18.dataField = "orderDetail.order.client.clientCode";
    f18.caption = "Müşteri Kodu";
    this.fields.push(f18);
    let f19: DevxPivotModel = new DevxPivotModel();
    f19.dataField = "orderDetail.order.client.speCode";
    f19.caption = "Firma No";
    this.fields.push(f19);
    let f20: DevxPivotModel = new DevxPivotModel();
    f20.dataField = "orderDetail.order.client.speCode2";
    f20.caption = "Firma Kargo No";
    this.fields.push(f20);
    let f21: DevxPivotModel = new DevxPivotModel();
    f21.dataField = "orderDetail";
    f21.caption = "Sipariş Detayları";
    this.fields.push(f21);
    let f22: DevxPivotModel = new DevxPivotModel();
    f22.dataField = "orderDetail.item";
    f22.caption = "Stok Sipariş Detayları";
    this.fields.push(f22);
    let f23: DevxPivotModel = new DevxPivotModel();
    f23.dataField = "machine";
    f23.caption = "Makine";
    this.fields.push(f23);
    let f24: DevxPivotModel = new DevxPivotModel();
    f24.dataField = "orderDetail.status.statuName";
    f24.caption = "Sipariş Detayı Durum Adı";
    this.fields.push(f24);
  }

  private sort(field: string): number {
    return 1;
  }

  customizeTooltip(args) {
    const valueText = (args.seriesName.indexOf("Total") != -1)
      ? new Intl.NumberFormat('en-EN', {style: 'currency', currency: 'USD'}).format(args.originalValue)
      : args.originalValue;

    return {
      html: args.seriesName + "<div class='currency'>" + valueText + "</div>"
    };
  }

  ngAfterViewInit() {
    this.pivotGrid.instance.bindChart(this.chart.instance, {
      dataFieldsDisplayMode: "splitPanes",
      alternateDataFields: false
    });
  }

  onPopupShown() {
    this.drillDownDataGrid.instance.updateDimensions();
  }

  onPivotCellClick(e) {
    if (e.area == "data") {
      var rowPathLength = e.cell.rowPath.length,
        rowPathName = e.cell.rowPath[rowPathLength - 1];

      this.drillDownDataSource = this.dataSource.createDrillDownDataSource(e.cell);
      this.salesPopupTitle = (rowPathName ? rowPathName : "Total") + " Drill Down Data";
      this.salesPopupVisible = true;
    }
  }

  loadState = () => {
    if (this.selectedReport.reportName === undefined) {
      this.selectedReport.reportName = "Varsayılan Rapor";
    }
    // @ts-ignore
    return this.devApi.sendStorageRequest("bossScreenPivot", "json", "Get", this.selectedReport.reportName);
  }

  saveState = (state) => {
    this.stateDevx = state;
    if (this.selectedReport.reportName === undefined) {
      this.selectedReport.reportName = "Varsayılan Rapor";
    }
    this.devApi.sendStorageRequest("bossScreenPivot", "text", "Post", this.selectedReport.reportName, state);
  }

  click($event: any) {
    this.newSelectReport.pivotName = 'bossScreenPivot';
    this.newSelectReport.userId = this.authenticationService.currentUserValue.id;
    this.newSelectReport.json = this.stateDevx;
    this.devApi.postOne(endpoints.devxStorage.newAltReport.path, this.newSelectReport).subscribe(l => {
        this.reportNames = l;
        this.newSelectReport = new DevxReporSelectorModel();
        this.sReport = l[l.length - 1];
        this.selectedReport = this.sReport;
      }
    )
  }

  selectBoxChange() {
    this.loadState().then(a => {
      this.pivotGrid.instance.getDataSource().state(c => {
        c = a;
      });
    });
  }
}
