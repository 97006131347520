import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {DevExpressProviders} from "../../_services/devexpress.providers";
import {endpoints} from "../../shared/endpoints";
import {exportDataGrid} from "devextreme/excel_exporter";
import * as ExcelJS from "exceljs/dist/exceljs.min.js";
import saveAs from 'file-saver';
import {DxDataGridComponent} from "devextreme-angular/ui/data-grid";
import {ROUTING} from "../../shared/routing";
import {CollectiveMethod} from "../../_models/CollectiveMethot.model";
import {allServiceProviders} from "../../_services/allService.provider";
import {ToastrService} from "ngx-toastr";
import {PrintProviders} from "../../_services/print.service";

@Component({
  selector: 'app-second-quality-report-detail',
  templateUrl: './second-quality-report-detail.component.html',
  styleUrls: ['./second-quality-report-detail.component.scss'],
  providers: [DevExpressProviders, allServiceProviders, PrintProviders]
})
export class SecondQualityReportDetailComponent implements OnInit {
  private orderDetailId: number;
  dataSource: any;
  @ViewChild('targetDataGrid') dataGrid: DxDataGridComponent;
  popVisible: boolean;
  machines: any;
  cuponProps: any;
  printCompensationModel: any;
  selectedMachine: any;
  selectedMachineCompensation: any;
  popPrintVisible: boolean = false;
  CompensationDisable: boolean = true;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private devx: DevExpressProviders,
              private api: allServiceProviders,
              private toas: ToastrService,
              private printApi: PrintProviders,) {
    this.orderDetailId = +this.route.snapshot.paramMap.get('orderDetailId');
    this.dataSource = this.devx.readForDevX('id', endpoints.secondQuality.OrderDetails.path + '?orderDetailId=' + this.orderDetailId);
    this.getCompensation = this.getCompensation.bind(this);
    this.getForPrintCompensation = this.getForPrintCompensation.bind(this);
  }

  ngOnInit() {
  }

  clearSearchPanel() {
    this.dataGrid.instance.clearFilter();
  }

  onToolbarPreparing(event) {
    event.toolbarOptions.items.unshift({},
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'fas fa-filter',
          title: 'Filtre Sıfırla',
          onClick: this.clearSearchPanel.bind(this)
        }
      });
  }

  onExporting(e) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('CountriesPopulation');
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      topLeftCell: {row: 2, column: 1}
    }).then(function (dataGridRange) {
      const headerRow = worksheet.getRow(1);
      worksheet.mergeCells(1, 1, 1, 10);
      headerRow.getCell(1).value = 'Pak-Ekizceli Boyahane Raporu';
      headerRow.getCell(1).font = {name: 'Segoe UI Light', size: 22};
      headerRow.getCell(1).alignment = {horizontal: 'center'};
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(new Blob([buffer], {type: 'application/octet-stream'}), 'PakEkizceliRapor.xlsx');
      });
    });
  }

  clickBackToPage() {
    const url = '/' + ROUTING.SECOND_QULITY_REPORT;

    this.router.navigateByUrl(url).then(r => {
    });

  }

  btnVisibleCompensation(e: any): boolean {
    let data = e.row.data;
    if (data.isSecondQuality && data.compensationCupon == null) {
      return true;
    } else {
      return false;
    }
  }

  btnVisiblePrint(e: any): boolean {
    let data = e.row.data;
    if (data.isCompensation) {
      return true;
    } else {
      return false;
    }
  }

  async getForPrintCompensation(e: any): Promise<void> {
    let c = new CollectiveMethod();
    c.method = 1;
    c.path = endpoints.orders.getMachines.path
    this.machines = await this.api.collectiveV2(c);
    c = new CollectiveMethod()
    c.method = 1
    c.path = endpoints.secondQuality.getCuponPrintDetail.path + '?cuponId=' + e.row.data.id;
    console.log(c);
    this.printCompensationModel = await this.api.collectiveV2(c);
    this.popPrintVisible = true;
  }

  async printCompensation(): Promise<void> {
    if (this.printCompensationModel != undefined) {
      this.printApi.printBarcode('printBarcode2');
      let c = new CollectiveMethod();
      c.method = 1;
      c.path = endpoints.secondQuality.updateCuponAfterPrint.path + '?orderSubId=' + this.printCompensationModel.id + '&machineId=' + this.selectedMachineCompensation.id;
      await this.api.collectiveV2(c);
      this.popPrintVisible=false;
      this.selectedMachineCompensation=null
    } else {
      this.toas.error("Ürün Bilgilerine Ulaşılamadı")
    }

  }

  async getCompensation(e: any): Promise<void> {
    let c = new CollectiveMethod();
    c.method = 1;
    c.path = endpoints.orders.getMachines.path
    this.machines = await this.api.collectiveV2(c);
    c = new CollectiveMethod()
    c.method = 1;
    c.path = endpoints.secondQuality.GetCompensationData.path
      + '?orderDetailId=' + e.row.data.orderDetailId + '&orderSubId=' + e.row.data.id
    this.cuponProps = await this.api.collectiveV2(c);
    this.popVisible = true;
  }

  selectedMachineChange(e: any) {
    this.selectedMachine = e;
  }

  selectedMachineChangeCompensationPop(e: any) {
    this.selectedMachineCompensation = e;
    this.CompensationDisable = false;
  }

  async sendCompesation(): Promise<void> {
    if (this.selectedMachine == undefined) {
      this.toas.error('Makine seçilmesi zorunludur.')
      return;
    }
    this.cuponProps.machineId = this.selectedMachine.id;
    let c = new CollectiveMethod();
    c.method = 2;
    c.path = endpoints.secondQuality.createCompensationCupon.path;
    c.jsonData = this.cuponProps;
    c.successMessage = "Telafi Kuponu Başarı İle Oluştu";
    this.cuponProps = await this.api.collectiveV2(c);

    if (this.cuponProps != null || this.cuponProps != undefined) {
      this.printApi.printBarcode('printBarcode');
      this.dataGrid.instance.refresh();
      this.popVisible = false;
      this.cuponProps = undefined;
      this.selectedMachine = undefined;
    }
  }

  popClose() {
    this.popVisible = false;
    this.cuponProps = undefined;
    this.selectedMachine = undefined;
  }
}
