import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {PackageProviders} from "../../_services/package.providers";
import {closeSackDto} from "../../_models/DTO/closeSack.dto";

@Component({
  selector: 'app-close-sack',
  templateUrl: './close-sack.component.html',
  styleUrls: ['./close-sack.component.scss']
})
export class CloseSackComponent implements OnInit {
  @ViewChild('search') searchElement: ElementRef;
  closeSackdto: closeSackDto;

  constructor(
    private toastr: ToastrService,
    private api: PackageProviders,
  ) {
  }

  ngOnInit() {

  }

  keyDownFunction($event: any): void {
    const barcode = this.searchElement.nativeElement.value;
    if (barcode === null || barcode === undefined || barcode === '') {
      this.toastr.warning('Barkod Alanı Boş Bırakılamaz', 'UYARI', {
        timeOut: 3000
      });
      return
    }
    this.api.postCloseSack(barcode).subscribe(data => {
      this.closeSackdto = data;
      if (this.closeSackdto.msg != "ok") {
        this.toastr.warning(this.closeSackdto.msg, 'UYARI', {
          timeOut: 3000
        });
        this.searchElement.nativeElement.value = '';
        return

      } else {
        this.toastr.success("Çuval Kapatıldı.", 'Başarılı', {
          timeOut: 3000
        });
        this.closeSackdto=null;
        this.searchElement.nativeElement.value = '';
        this.searchElement.nativeElement.focus();
      }
    })
  }
}
