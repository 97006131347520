import {Component, OnInit, NgZone} from '@angular/core';
// import { LocalDataSource } from 'ng2-smart-table';
import {DatePipe} from '@angular/common';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../_services';
import {OrderProviders} from '../../_services/orders.providers';
import {Order} from '../../_models/order.model';
import {MatDialog} from '@angular/material';
import {RemoveItemComponent} from '../../components/remove-item/remove-item.component';
import {POPUPTYPE} from '../../shared/popup';
import {ToastrService} from 'ngx-toastr';
import {BehaviorSubject, Observable} from "rxjs";
import {User} from "../../_models";
import {DevExpressProviders} from 'src/app/_services/devexpress.providers';
import {PrinterService} from "../../printer.service";

@Component({
  selector: 'app-request-list',
  templateUrl: './request-list.component.html',
  styleUrls: ['./request-list.component.scss'],
  providers: [PrinterService]
})
export class RequestListComponent implements OnInit {
  // Request list params
  clickNewRequest = true;
  clickProgressRequest = false;
  clickCompletedRequest = false;
  clickCancelledRequest = false;
  clickProducedRequest = false;
  orders: Order[] = [];
  roleId: number;
  typeId: number = 1;
  source: any;
  public currentRequestId: Observable<any>;
  private currentReqSubject: BehaviorSubject<any>;
  newOrderSettings = {
    hideSubHeader: true,
    actions: {
      position: 'right',
      columnTitle: '',
      custom: this.getCustomButtonForOrderStatus(),
      add: false,
      edit: false,
      delete: false
    },
    pager: {
      perPage: 20
    },
    columns: {
      ficheNo: {
        title: 'Fiş No',
        filter: false,
        width: '25%'
      },
      clientCode: {
        title: 'Cari Kodu',
        filter: false,
        width: '25%'
      },
      name: {
        title: 'Cari Adı',
        filter: false,
        width: '25%'

      },
      createTime: {
        title: 'Oluşturulma',
        filter: false,
        valuePrepareFunction: (date) => {
          const raw = new Date(date);
          const formatted = this.datepipe.transform(raw, 'HH:mm  dd/MM/yyyy');
          return formatted;
        }
      },
      orderdate: {
        title: 'Sipariş Tarihi',
        filter: false,
        valuePrepareFunction: (date) => {
          const raw = new Date(date);
          const formatted = this.datepipe.transform(raw, 'HH:mm  dd/MM/yyyy');
          return formatted;
        }
      }
    }
  };
  processSettings = {
    hideSubHeader: true,
    actions: {
      position: 'right',
      columnTitle: '',
      classToApply: 'transfer',
      custom: this.getCustomButtonForProcessStatus(),
      add: false,
      edit: false,
      delete: false
    },
    pager: {
      perPage: 20
    },
    columns: {
      ficheNo: {
        title: 'Fiş No',
        filter: false,
        width: '25%'
      },
      clientCode: {
        title: 'Cari Kodu',
        filter: false,
        width: '25%'
      },
      name: {
        title: 'Cari Adı',
        filter: false,
        width: '25%'

      },
      createTime: {
        title: 'Oluşturulma',
        filter: false,
        valuePrepareFunction: (date) => {
          const raw = new Date(date);
          const formatted = this.datepipe.transform(raw, 'HH:mm  dd/MM/yyyy');
          return formatted;
        }
      },
      orderdate: {
        title: 'Sipariş Tarihi',
        filter: false,
        valuePrepareFunction: (date) => {
          const raw = new Date(date);
          const formatted = this.datepipe.transform(raw, 'HH:mm  dd/MM/yyyy');
          return formatted;
        }
      }
    }
  };
  settings = {
    hideSubHeader: true,
    actions: {
      position: 'right',
      columnTitle: '',
      add: false,
      edit: false,
      delete: false
    },
    pager: {
      perPage: 20
    },
    columns: {
      ficheNo: {
        title: 'Fiş No',
        filter: false,
        width: '25%'
      },
      clientCode: {
        title: 'Cari Kodu',
        filter: false,
        width: '25%'
      },
      name: {
        title: 'Cari Adı',
        filter: false,
        width: '25%'

      },
      createTime: {
        title: 'Oluşturulma',
        filter: false,
        valuePrepareFunction: (date) => {
          const raw = new Date(date);
          const formatted = this.datepipe.transform(raw, 'HH:mm  dd/MM/yyyy');
          return formatted;
        }
      },
      orderdate: {
        title: 'Sipariş Tarihi',
        filter: false,
        valuePrepareFunction: (date) => {
          const raw = new Date(date);
          const formatted = this.datepipe.transform(raw, 'HH:mm  dd/MM/yyyy');
          return formatted;
        }
      }
    }
  };
  closeResult: string;

  constructor(public datepipe: DatePipe,
              private router: Router,
              public apiOrder: OrderProviders,
              public authApi: AuthenticationService,
              public dialog: MatDialog,
              public devApi: DevExpressProviders,
              private toastr: ToastrService,
              private zone: NgZone,
              private printer: PrinterService
  ) {

    // printer.getPrinters().subscribe(a => {
    //   console.log(a)
    // });
    this.currentReqSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('reqTypeId')));
    this.currentRequestId = this.currentReqSubject.asObservable();
  }

  ngOnInit() {
    this.getOrdersByStatus(1);
    this.roleId = this.authApi.getroleId();
    if (localStorage.getItem('reqTypeId') === "1") {
      this.clickRequestButton('new')
    } else if (localStorage.getItem('reqTypeId') === "2") {
      this.clickRequestButton('progress')
    } else if (localStorage.getItem('reqTypeId') === "3") {
      this.clickRequestButton('completed')
    } else if (localStorage.getItem('reqTypeId') === "4") {
      this.clickRequestButton('cancelled')
    } else {
      localStorage.setItem('reqTypeId', JSON.stringify(this.typeId));
    }
  }

  getOrdersByStatus(id) {
    this.source = this.devApi.getOrders('id', id);
  }

  onSearch(query: string = '') {
    // if (query != '') {
    //   this.source.setFilter([
    //     // fields we want to include in the search
    //     {
    //       field: 'id',
    //       search: query
    //     },
    //     {
    //       field: 'ficheNo',
    //       search: query
    //     },
    //     {
    //       field: 'name',
    //       search: query
    //     },
    //     {
    //       field: 'statuName',
    //       search: query
    //     },
    //     {
    //       field: 'orderdate',
    //       search: query
    //     }
    //   ], false);
    // }
    // else {
    //   this.source.setFilter([])
    // }
    // second parameter specifying whether to perform 'AND' or 'OR' search
    // (meaning all columns should contain search query or at least one)
    // 'AND' by default, so changing to 'OR' by setting false here
  }

  clickRequestButton(requestType) {
    switch (requestType) {
      case 'new': {
        this.typeId = 1;
        this.clickNewRequest = true;
        this.clickCompletedRequest = false;
        this.clickProgressRequest = false;
        this.clickProducedRequest = false;
        this.clickCancelledRequest = false;
        this.getOrdersByStatus(this.typeId);
        localStorage.setItem('reqTypeId', JSON.stringify(this.typeId));
        break;
      }
      case 'progress': {
        this.typeId = 2;
        this.clickNewRequest = false;
        this.clickCompletedRequest = false;
        this.clickProgressRequest = true;
        this.clickProducedRequest = false;
        this.clickCancelledRequest = false;
        this.getOrdersByStatus(this.typeId);
        localStorage.setItem('reqTypeId', JSON.stringify(this.typeId));
        break;
      }
      case 'completed': {
        this.typeId = 3;
        this.clickNewRequest = false;
        this.clickCompletedRequest = true;
        this.clickProgressRequest = false;
        this.clickProducedRequest = false;
        this.clickCancelledRequest = false;
        this.getOrdersByStatus(this.typeId);
        localStorage.setItem('reqTypeId', JSON.stringify(this.typeId));
        break;
      }
      case 'cancelled': {
        this.typeId = 4;
        this.clickNewRequest = false;
        this.clickCompletedRequest = false;
        this.clickProgressRequest = false;
        this.clickProducedRequest = false;
          this.clickCancelledRequest = true;
        this.getOrdersByStatus(this.typeId);
        localStorage.setItem('reqTypeId', JSON.stringify(this.typeId));
        break;
      }
      case 'produced': {
        this.typeId = 5;
        this.clickNewRequest = false;
        this.clickCompletedRequest = false;
        this.clickProgressRequest = false;
        this.clickProducedRequest = true;
        this.clickCancelledRequest = false;
        this.getOrdersByStatus(this.typeId);
        localStorage.setItem('reqTypeId', JSON.stringify(this.typeId));
        break;
      }
    }
  }

  onUserRowSelect(event) {
      const orderId = event.data.id;
       const isOrderStatuNew = this.clickNewRequest === true ? true : false;
        const url = '/order-detail/' + orderId + '/' + isOrderStatuNew;
        this.router.navigateByUrl(url);


  }

  getCustomButtonForOrderStatus() {
    return [
      {
        name: 'deleteAction',
        title: '<i class="fas fa-trash-alt" title="Siparişi Sil"></i>'
      }
    ];
  }

  getCustomButtonForProcessStatus() {
    return [
      {
        name: 'finalAction',
        title: '<i class="fas fa-exchange-alt transfer" style="background-color: green !important;" title="Siparişi Tamamla"></i>'
      }
    ];
  }

  onCustomAction(event) {
    switch (event.action) {
      case 'deleteAction':
        this.deleteAction(event.data);
        break;
      case 'finalAction':
        this.finalAction(event.data);
        break;
    }
  }

  public finalAction(formData: any) {

    const dialogRef = this.dialog.open(RemoveItemComponent, {
      width: '500px',
      data: {id: formData.data.id, popupType: POPUPTYPE.TRANSFER}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.typeId = 2;
      this.getOrdersByStatus(2);
      this.clickNewRequest = false;
      this.clickCompletedRequest = true;
      this.clickProgressRequest = false;
      this.clickCancelledRequest = false;
      localStorage.setItem('reqTypeId', JSON.stringify(this.typeId));
    });
  }

  public deleteAction(formData: any) {

    const dialogRef = this.dialog.open(RemoveItemComponent, {
      width: '500px',
      data: {id: formData.data.id, popupType: POPUPTYPE.ORDER}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.typeId = 1;
      this.getOrdersByStatus(1);
      this.clickNewRequest = true;
      this.clickCompletedRequest = false;
      this.clickProgressRequest = false;
      this.clickCancelledRequest = false;
      localStorage.setItem('reqTypeId', JSON.stringify(this.typeId));
    });
  }


  readFromLogoAPI() {
    this.apiOrder.readFromLogo().subscribe(data => {
      this.toastr.success('Siparişler Çekildi.', 'BAŞARILI', {
        timeOut: 3000
      });
      this.typeId = 1;
      this.getOrdersByStatus(1);
      this.clickNewRequest = true;
      this.clickCompletedRequest = false;
      this.clickProgressRequest = false;
      this.clickCancelledRequest = false;
      localStorage.setItem('reqTypeId', JSON.stringify(this.typeId));
    })

  }

}
