import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {CarWeight} from 'src/app/_models/dyehouse/car-weight.model';
import {ToastrService} from 'ngx-toastr';
import {DyehouseProviders} from 'src/app/_services/dyehouse.providers';
import {Barcode, OutgoingBarcode} from '../../_models/dyehouse/outgoingBarcode.model';

@Component({
  selector: 'app-come-back-to-dye-house',
  templateUrl: './come-back-to-dye-house.component.html',
  styleUrls: ['./come-back-to-dye-house.component.scss']
})
export class ComeBackToDyeHouseComponent implements OnInit {
  @ViewChild('search') searchElement: ElementRef;
  checkedScanBarkod = false;
  modelArray: CarWeight[] = [];
  model: CarWeight;
  barcodeArray: Barcode[] = [];
  barcode: Barcode = new Barcode();
  tareWeight = '';
  comeDyhouseModel: OutgoingBarcode;
  carName = '';
  getColorByService: any;
  popupVisible: boolean = false;

  constructor(private fb: FormBuilder,
              private api: DyehouseProviders,
              private toastr: ToastrService) {
  }

  ngOnInit() {
    this.getVehicleWithWeights();
  }

  checkScanBarcode(e) {
    this.checkedScanBarkod = !this.checkedScanBarkod;
    if (e.target.checked) {
      this.searchElement.nativeElement.style.display = 'block';
      setTimeout(() => { // this will make the execution after the above boolean has changed
        this.searchElement.nativeElement.focus();
      }, 0);
    } else if (!e.target.checked) {
      setTimeout(() => { // this will make the execution after the above boolean has changed
        this.searchElement.nativeElement.style.display = 'none';
      }, 0);
    }
  }


  keyDownFunction() {

    this.barcode = new Barcode();
    const barcode = this.searchElement.nativeElement.value;
    if (this.barcodeArray.length != 0) {
      let isTrueOrFalse: boolean = true;
      this.barcodeArray.forEach(item => {
        if (barcode === item.BarcodeDetail) {
          this.toastr.warning('Bu kupon daha önceden okutulmuştur.', 'UYARI', {
            timeOut: 3000
          });
          this.searchElement.nativeElement.value = '';
          isTrueOrFalse = false;
        }
      })
      if (isTrueOrFalse) {
        this.api.checkCuponForInComingVehicle(barcode).subscribe(data => {
          this.getColorByService = data;
          if (this.getColorByService[0].toLocaleLowerCase() != 'ok') {
            this.toastr.warning(data, 'UYARI', {
              timeOut: 3000
            });
          }
          if (this.getColorByService[0].toLocaleLowerCase() === 'ok') {
            this.barcode.BarcodeDetail = barcode;
            this.barcodeArray.push(this.barcode);
            this.searchElement.nativeElement.value = '';
          } else {
            this.searchElement.nativeElement.value = '';
          }
        });
      }
    } else {
      this.api.checkCuponForInComingVehicle(barcode).subscribe(data => {
        this.getColorByService = data;
        if (this.getColorByService[0].toLocaleLowerCase() != 'ok') {
          this.toastr.warning(data, 'UYARI', {
            timeOut: 3000
          });
        }
        if (this.getColorByService[0].toLocaleLowerCase() === 'ok') {
          this.barcode.BarcodeDetail = barcode;
          this.barcodeArray.push(this.barcode);
          this.searchElement.nativeElement.value = '';
        } else {
          this.searchElement.nativeElement.value = '';
        }
      });
    }
  }

  getVehicleWithWeights() {
    this.api.getVehicleWithWeights().subscribe(data => {
      this.modelArray = data;
    });
  }

  setFormValueToModel() {
    this.comeDyhouseModel = new OutgoingBarcode();
    this.comeDyhouseModel.code = this.carName;
    this.comeDyhouseModel.orderSubDetails = this.barcodeArray;
  }

  onClickSubmit() {
    this.setFormValueToModel();
    this.searchElement.nativeElement.focus();
    if (this.barcodeArray.length > 0) {
      this.api.postIncomingBarcode(this.comeDyhouseModel).subscribe(data => {
        this.toastr.success('Boyahaneden gelen araçlar kabul edildi.', 'BAŞARILI', {
          timeOut: 3000
        });
        this.ngOnInit();
        this.barcodeArray = [];

      });
    } else {
      this.toastr.warning('Önce barkod okutmalısınız.', 'UYARI', {
        timeOut: 3000
      });
    }

  }

  cleanBarcodeArray(barcode) {
    const index = this.barcodeArray.indexOf(barcode, 0);
    if (index > -1) {
      this.barcodeArray.splice(index, 1);
    }
  }

  openPopUp(): void {
    this.popupVisible = true;
  }

  afterClosePop(e: boolean): void {
    this.popupVisible = e;
  }
}
