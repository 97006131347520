import {Barcode} from './barcode.model';

export class CarWeight {
  code: string;
  fullWeight?: string;
  netWeight?: string;
  emptyWeight?: string;
  tareWeight?: string;
  orderSubDetailsId?: Barcode[];
}
