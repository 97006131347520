import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {OrderProviders} from '../../_services/orders.providers';
import {subOrder} from '../../_models/sub-order.model';
import {Machine} from '../../_models/machine.model';
import {PrintProviders} from '../../_services/print.service';
import {Cupon, CuponDetail} from '../../_models/cupon.model';
import {ROUTING} from '../../shared/routing';
import {RemoveItemComponent} from '../../components/remove-item/remove-item.component';
import {POPUPTYPE} from '../../shared/popup';
import {MatDialog} from '@angular/material';
import {ToastrService} from 'ngx-toastr';
import {DevExpressProviders} from 'src/app/_services/devexpress.providers';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit {
  orderId: number;
  isOrderStatuNew: boolean;
  isClickedSubOrders = true;
  showDetailContainer = false;
  source: any; // add a property to the component
  subOrderModel: subOrder = new subOrder();
  cuponDetailModel: CuponDetail = new CuponDetail();
  cuponDetailModelArray: CuponDetail[] = [];
  showCuponDetailArea = false;
  machines: Machine[] = [];
  cuponList: Cupon[] = [];
  selectedMachine = 0;
  barcode: string;
  minQuantity: number;
  maxQuantity: number;
  printList: number[] = [];
  subDetailId: number[] = [];

  isProcess: boolean = false;

  selectedMachineName: string;

  settings = {
    hideSubHeader: true,
    actions: {
      position: 'right',
      columnTitle: '',
      add: false,
      edit: false,
      delete: false
    },
    columns: {
      pattern: {
        title: 'Desen',
        filter: false
      },
      amount: {
        title: 'Miktar(m)',
        filter: false
      },
      remainingAmount: {
        title: 'Kalan Miktar(m)',
        filter: false
      },
      id: {
        title: 'Kalem No',
        filter: false
      },
      name: {
        title: 'Cari',
        filtre: false
      },
      code: {
        title: 'Ürün Kodu',
        filter: false
      },
      color: {
        title: 'Renk',
        filtre: false
      },
      floor: {
        title: 'Zemin',
        filter: false,

      },
      rope: {
        title: 'İp',
        filter: false,
      },

      strike: {
        title: 'Vuruş',
        filter: false
      },
    },
    pager: {
      perPage: 5
    }
  };
  processSettings = {
    hideSubHeader: true,
    actions: {
      position: 'right',
      columnTitle: '',
      custom: this.getCustomButtonForProcessStatus(),
      add: false,
      edit: false,
      delete: false
    },
    columns: {
      id: {
        title: 'Kalem No',
        filter: false
      },
      name: {
        title: 'Cari',
        filtre: false
      },
      code: {
        title: 'Ürün Kodu',
        filter: false
      },
      color: {
        title: 'Renk',
        filtre: false
      },
      floor: {
        title: 'Zemin',
        filter: false,

      },
      rope: {
        title: 'İp',
        filter: false,
      },
      pattern: {
        title: 'Desen',
        filter: false
      },
      strike: {
        title: 'Vuruş',
        filter: false
      },
      amount: {
        title: 'Miktar(m)',
        filter: false
      }
    },
    pager: {
      perPage: 5
    }
  };

  newOrderSettings = {
    hideSubHeader: true,
    actions: {
      position: 'right',
      columnTitle: '',
      custom: this.getCustomButtonForOrderStatus(),
      add: false,
      edit: false,
      delete: false
    },
    columns: {
      id: {
        title: 'Kalem No',
        filter: false
      },
      name: {
        title: 'Cari',
        filtre: false
      },
      code: {
        title: 'Ürün Kodu',
        filter: false
      },
      color: {
        title: 'Renk',
        filtre: false
      },
      floor: {
        title: 'Zemin',
        filter: false,

      },
      rope: {
        title: 'İp',
        filter: false,
      },
      pattern: {
        title: 'Desen',
        filter: false
      },
      strike: {
        title: 'Vuruş',
        filter: false
      },
      amount: {
        title: 'Miktar(m)',
        filter: false
      }
    },
    pager: {
      perPage: 5
    }
  };

  constructor(private router: Router,
              private route: ActivatedRoute,
              private apiOrder: OrderProviders,
              private printApi: PrintProviders,
              public devApi: DevExpressProviders,
              public dialog: MatDialog,
              private toastr: ToastrService) {
    this.orderId = +this.route.snapshot.paramMap.get('orderId');
    this.isOrderStatuNew = (this.route.snapshot.paramMap.get('isOrderStatuNew') === 'true');
    this.getSubOrderDetail(this.orderId);
    if (this.cuponDetailModel !== undefined) {
      this.showCuponDetailArea = false;
    }
  }

  ngOnInit() {
  }

  getSubOrderDetail(orderId) {
    // this.apiOrder.getSubOrders(orderId).subscribe(data => {
    //   this.source = new LocalDataSource(data); // create the source
    // });


    this.source = this.devApi.getOrderDetail('id', orderId);

  }

  onUserRowSelect(event) {
    this.showDetailContainer = true;
    this.bindSubOrderDetailContainer(event);
    this.getMachines();
    this.getSubDetailCuponListById(this.subOrderModel.id);

  }

  bindSubOrderDetailContainer(event) {

    this.subOrderModel.amount = event.data.amount;
    this.subOrderModel.code = event.data.code;
    this.subOrderModel.color = event.data.color;
    this.subOrderModel.floor = event.data.floor;
    this.subOrderModel.id = event.data.id;
    this.subOrderModel.name = event.data.name;
    this.subOrderModel.pattern = event.data.pattern;
    this.subOrderModel.rope = event.data.rope;
    this.subOrderModel.strike = event.data.strike;
  }

  getMachines() {
    this.apiOrder.getMachines().subscribe(data => {
      this.machines = data;
    });
  }

  clickCreateCuponButton() {
    this.apiOrder.createSubDetailCuponById(this.subOrderModel.id, +this.selectedMachine).subscribe(data => {
      this.toastr.success('Kuponlar oluşturuldu.', 'BAŞARILI', {
        timeOut: 3000
      });
      this.getSubDetailCuponListById(this.subOrderModel.id);
    });
  }

  clickCuponRow(id: any[]) {
    this.cuponDetailModelArray = [];
    this.showCuponDetailArea = false;
    this.barcode = 'data:image/jpeg;base64,';
    this.apiOrder.getCuponDetailByCuponId(id).subscribe(data => {
        //this.cuponDetailModelArray = data;
        data.forEach(item => {
          this.cuponDetailModelArray.push(item);
          // this.cuponDetailModelArray.push(item);
        });
        this.barcode = this.barcode + this.cuponDetailModel.arr;
        this.showCuponDetailArea = true;
      }
    );
  }

  getSubDetailCuponListById(subOrderId) {
    this.apiOrder.getSubDetailCuponListById(subOrderId).subscribe(data => {
      this.cuponList = data;
    });
  }

  printBarcode() {
    if (this.selectedMachine != 0) {
      this.printApi.printBarcode('printBarcode');
      this.getSubDetailCuponListById(this.subOrderModel.id);

      this.apiOrder.updateSubDetailPrintInfoById(this.printList, this.selectedMachine).subscribe(data => {
      });

      setTimeout(() => {
        for (var i = this.pmin; i <= this.pmax; i++) {
          let element = this.cuponList[i - 1];
          element.isPrinted = true;
          this.cuponList[i - 1] = element;
        }
      }, 500);
    } else {
      this.toastr.warning('Makine girilmesi Zorunludur', 'UYARI', {
        timeOut: 3000
      });
    }
  }

  /***
   * Bir önceki sayfaya dönmemizi sağlar.
   */
  clickBackToPage() {
    const url = '/' + ROUTING.REQUEST_LIST;
    this.router.navigateByUrl(url);
  }


  getCustomButtonForOrderStatus() {
    return [
      {
        name: 'deleteAction',
        title: '<i class="fas fa-trash-alt" title="Kalemi sil"></i>'
      }
    ];
  }

  delete(id) {

  }

  transfer(id) {

  }


  getCustomButtonForProcessStatus() {
    return [
      {
        name: 'finalAction',
        title: '<i class="fas fa-exchange-alt transfer" [hidden]="" style="background-color: green !important;" title="Siparişi Tamamla"></i>'
      }
    ];
  }

  onCustomAction(event) {
    switch (event.action) {
      case 'deleteAction':
        this.deleteAction(event.data);
        break;
      case 'finalAction':
        this.finalAction(event.data);
        break;
    }
  }

  public deleteAction(formData: any) {

    const dialogRef = this.dialog.open(RemoveItemComponent, {
      width: '500px',
      data: {id: formData.data.id, popupType: POPUPTYPE.SUBORDER}
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.getSubOrderDetail(this.orderId); // statüsü yeni olanları tekrar çağırıp tabloyu refreshliyorum
    });
  }

  public finalAction(formData: any) {

    const dialogRef = this.dialog.open(RemoveItemComponent, {
      width: '500px',
      data: {id: formData.data.id, popupType: POPUPTYPE.SUBTRANSFER}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getSubOrderDetail(this.orderId);
    });
  }

  pmax;
  pmin;

  clickPrint() {

    this.machines.forEach(data => {
      if (data.id == this.selectedMachine) {
        this.selectedMachineName = data.name;
      }
    })
    this.printList.length = 0;
    if (this.selectedMachine != 0) {
      if (this.minQuantity > this.maxQuantity) {
        this.toastr.warning('Kupon başlangıcı bitişten küçük olmalıdır.', 'UYARI', {
          timeOut: 3000
        });
      } else if (this.maxQuantity > this.cuponList.length) {
        this.toastr.warning('Oluşturulacak maksimum kupon, kupon boyutundan fazla olamaz.', 'UYARI', {
          timeOut: 3000
        });
      } else if (!this.minQuantity && this.maxQuantity) {
        this.toastr.warning('Başlangıç değeri giriniz', 'UYARI', {
          timeOut: 3000
        });
      } else if (this.minQuantity && !this.maxQuantity) {
        this.toastr.warning('Bitiş değeri giriniz', 'UYARI', {
          timeOut: 3000
        });
      } else if (!this.minQuantity && !this.maxQuantity) {
        this.toastr.warning('Başlangıç ve Bitiş değeri giriniz', 'UYARI', {
          timeOut: 3000
        });
      } else {
        this.pmin = this.minQuantity;
        this.pmax = this.maxQuantity;
        for (var i = this.minQuantity; i <= this.maxQuantity; i++) {
          this.printList.push(this.cuponList[i - 1].id);
        }
        this.clickCuponRow(this.printList);
        setTimeout(() => {
          this.printBarcode();
        }, 500);
      }
    } else {
      this.toastr.warning('Makine girilmesi Zorunludur', 'UYARI', {
        timeOut: 3000
      });
    }
  }

  valueChange(name) {
    this.selectedMachineName = name;
  }
}
