export class SackDetailKey {
  key: SackDetail = new SackDetail;
}

export class SackDetail {
  sackId: number;
  weight: number;
  arr: string;
  length: number;
  clientName: string;
  name: string;
  count: number;
  sackNumber;
  address;
  spcode;
  barcode: string;
}

export class sackWeightModel {
  id: number;
  weight?: number;
}

