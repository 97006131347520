export class Package {
  definition: string;
  meter: number;
  orderSubDetails: SubDetail[];
}

export class SubDetail {
  id: number;
  additionalMeters: string;
}

export class PackageDetailModel {
  client: number;
  clintName: string;
  pattern: string;
  sarim: string;
  arr: string;
  defination: string;
  meter: string;
  floor: string;
  color: string;
  barcode: string;
}

export class Key {
  clientId: number;
  clintName: string;
  pattern: string;
  specode: string;
  arr: string;
  definiton: string;
}

export class ComportMeters {
  meters: number;
}
