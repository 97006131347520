import { Component, OnInit, NgZone } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DispatchProviders } from 'src/app/_services/dispatch.providers';
import { DispatchClients } from 'src/app/_models/clients-dispatch.model';
import { DispatchOrder } from 'src/app/_models/orderId-dispatch.model';
import { DispatchKey, DispatchSack } from 'src/app/_models/dispatch-sack.model';
import { SendDispatch, SendDispatchSacks } from 'src/app/_models/send-dispatch.model';
import { LoadingComponent } from 'src/app/components/loading/loading.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-dispatch',
  templateUrl: './dispatch.component.html',
  styleUrls: ['./dispatch.component.scss']
})
export class DispatchComponent implements OnInit {
  dispatchClients: DispatchClients[] = [];

  selectedClient: DispatchClients;
  selectedClient2: DispatchClients = new DispatchClients();
  dispatchOrders: DispatchOrder[] = [];
  selectedOrder: DispatchOrder;
  dispatchSacks: DispatchSack[] = [];
  dispatchAreaModelArray: DispatchSack[] = [];
  sendingDispatch: SendDispatch;
  sendingDispatchSack: SendDispatchSacks;
  sendingDispatchSackArray: SendDispatchSacks[] = [];

  constructor(
    private api: DispatchProviders,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private zone: NgZone) {
  }

  ngOnInit() {
    this.getSentOrderClientsAPI();
  }

  getSentOrderClientsAPI() {
    this.api.getSentOrderClients().subscribe(data => {
      this.dispatchClients = data;
    })
  }

  changeClientEvent(event) {
    this.selectedClient = new DispatchClients();
    this.selectedClient = this.findClientsDispatch(event.target.value);
    this.getOrderByClientIdAPI(this.selectedClient.id);
    this.dispatchSacks.length = 0;
    this.dispatchAreaModelArray.length = 0;
  }

  findClientsDispatch(clientID) {
    return this.dispatchClients.find(x => x.name === clientID);
  }

  getOrderByClientIdAPI(id) {
    this.api.getOrderByClientId(id).subscribe(data => {
      this.dispatchOrders = data;
    })
  }

  changeOrderEvent(event) {
    // this.selectedOrder = new DispatchOrder();
    // this.selectedOrder = this.findDispatchOrderId(event.target.value);
    this.sendingDispatchSackArray.splice(0);
    this.dispatchAreaModelArray.splice(0);
    this.selectedClient = new DispatchClients();
    this.selectedClient.id = event.target.value;
    //this.selectedClient = this.findClientsDispatch(event.target.value);
    if(this.dispatchClients.filter(a=>a.id == event.target.value &&  a.embargo).length > 0){
      this.toastr.warning('Bu caride ambargo vardır', 'UYARI', {
        timeOut: 3000
      });
      this.dispatchSacks = [];
    } else {
      this.getSackByOrderIdAPI(event.target.value);

    }
    // this.dispatchSacks=null;
  }

  findDispatchOrderId(orderID) {
    return this.dispatchOrders.find(x => x.ficheNo === orderID);
  }

  getSackByOrderIdAPI(id) {

    this.api.getSackByOrderId(id).subscribe(data => {

      this.dispatchSacks = data;
    })
  }

  toggle(dispatchSack) {
    // start :sevk alanına eklenen verileri listeden kaldırır
    const index = this.dispatchSacks.indexOf(dispatchSack, 0);
    if (index > -1) {
      this.dispatchSacks.splice(index, 1);
    }
    // end :sevk alanına eklenen verileri listeden kaldırır
    this.dispatchAreaModelArray.push(dispatchSack);
  }

  removeOnDispatchArea(dispatchArea) {
    // start :sevk alanına eklenen verileri listeden kaldırır
    const index = this.dispatchAreaModelArray.indexOf(dispatchArea, 0);
    if (index > -1) {
      this.dispatchAreaModelArray.splice(index, 1);
    }
    // end :sevk alanına eklenen verileri listeden kaldırır
    this.dispatchSacks.push(dispatchArea);
  }

  clickCreateDispatch() {
    if (this.dispatchAreaModelArray.length === 0) {
      this.toastr.warning('Lütfen önce çuval seçiniz.', 'UYARI', {
        timeOut: 3000
      });
    } else {
      this.setDispatchModelForAPI();
      this.createDispatchAPI();
      this.sendingDispatchSackArray.splice(0);
      this.dispatchAreaModelArray.splice(0);

    }

  }

  setDispatchModelForAPI() {

    this.sendingDispatch = new SendDispatch();
    this.sendingDispatch.clientId = this.selectedClient.id;
    this.dispatchAreaModelArray.forEach(item => {
      this.sendingDispatchSack = new SendDispatchSacks();
      this.sendingDispatchSack.id = item.sackId;
      this.sendingDispatchSackArray.push(this.sendingDispatchSack);
    });
    this.sendingDispatch.sack = this.sendingDispatchSackArray;
  }

  createDispatchAPI() {
    this.showLoading();
    this.api.createDispatchAPI(this.sendingDispatch).subscribe(data => {
      if (data != "x") {
        this.toastr.success('Sevk işlemi başarılı şekilde gerçekleşmiştir.', 'BAŞARILI', {
          timeOut: 3000
        });
        this.getSentOrderClientsAPI();
      }
      this.closeLoading();
    })
  }

  showLoading() {
    const dialogRef = this.dialog.open(LoadingComponent, {
      disableClose: true,
      width: '500px',
      data: {}
    });
  }

  closeLoading() {
    this.dialog.closeAll();
  };


}

