import {Component, OnInit, ViewChild} from '@angular/core';
import {DxDataGridComponent} from "devextreme-angular/ui/data-grid";
import {exportDataGrid} from "devextreme/excel_exporter";
import * as ExcelJS from "exceljs/dist/exceljs.min.js";
import saveAs from 'file-saver';
import {endpoints} from "../../shared/endpoints";
import {DevExpressProviders} from "../../_services/devexpress.providers";
import {Router} from "@angular/router";
import {ROUTING} from "../../shared/routing";

@Component({
  selector: 'app-second-quality-report',
  templateUrl: './second-quality-report.component.html',
  styleUrls: ['./second-quality-report.component.scss'],
  providers: [DevExpressProviders]
})
export class SecondQualityReportComponent implements OnInit {
  @ViewChild('targetDataGrid') dataGrid: DxDataGridComponent;
  dataSource: any;

  constructor(private devApi: DevExpressProviders,
              private router: Router,) {
    this.goOrderDetail = this.goOrderDetail.bind(this);
  }

  ngOnInit() {
    this.dataSource = this.devApi.readForDevX('id', endpoints.secondQuality.path);
  }

  clearSearchPanel() {
    this.dataGrid.instance.clearFilter();
  }

  onToolbarPreparing(event) {
    event.toolbarOptions.items.unshift({},
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'fas fa-filter',
          title: 'Filtre Sıfırla',
          onClick: this.clearSearchPanel.bind(this)
        }
      });
  }

  onExporting(e) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('CountriesPopulation');
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      topLeftCell: {row: 2, column: 1}
    }).then(function (dataGridRange) {
      const headerRow = worksheet.getRow(1);
      worksheet.mergeCells(1, 1, 1, 10);
      headerRow.getCell(1).value = 'Pak-Ekizceli Boyahane Raporu';
      headerRow.getCell(1).font = {name: 'Segoe UI Light', size: 22};
      headerRow.getCell(1).alignment = {horizontal: 'center'};
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(new Blob([buffer], {type: 'application/octet-stream'}), 'PakEkizceliRapor.xlsx');
      });
    });
  }

  goOrderDetail(a: any): void {
    // const url = '/second-quality-report-detail/' + a.row.data.orderDetailId;
    const url = '/'+ ROUTING.SECOND_QULITY_REPORT_DETAIL +'/' + a.row.data.orderDetailId;
    console.log(url)
    this.router.navigateByUrl(url).then( );
  }
}
