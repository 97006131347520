import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import 'rxjs/add/operator/map';
import {catchError, map} from 'rxjs/operators';
import {endpoints} from '../shared/endpoints';
import {Order} from '../_models/order.model';
import {subOrder} from '../_models/sub-order.model';
import {Machine} from '../_models/machine.model';
import {Cupon, CuponDetail} from '../_models/cupon.model';
import {ToastrService} from 'ngx-toastr';
import {Router} from "@angular/router";
import {ROUTING} from "../shared/routing";


@Injectable()
export class OrderProviders {
  header: HttpHeaders;
  private currentUserSubject: any;

  constructor(private http: HttpClient,
              private router: Router,
              private toastr: ToastrService) {
    this.setHeader();
  }

  setHeader() {
    this.currentUserSubject = (JSON.parse(localStorage.getItem('currentUser')));
    if (this.currentUserSubject) {
      this.header = new HttpHeaders()
        .set('Authorization', `Bearer ${(this.currentUserSubject.token)}`)
        .set('Content-Type', 'application/json');
    } else {
      this.router.navigateByUrl(ROUTING.LOGIN);
    }

  }

  createSubDetailCuponById(orderDetailIdParam, machineIdParam): Observable<any> {
    let model = {
      orderDetailId: orderDetailIdParam,
      machineId: machineIdParam
    };

    return this.http.get(endpoints.orders.createSubDetailCuponById.path,
      {headers: this.header, params: model}).pipe(
      map((data: Order[]) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('createSubDetailCuponById hata!');
      })
    );
  }


  getCuponDetailByCuponId(value: number[]): Observable<CuponDetail[]> {
    return this.http.post<CuponDetail[]>(endpoints.orders.getCuponDetailByCuponId.path, value,
      {headers: this.header}).pipe(
      map((data: CuponDetail[]) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('getCuponDetailByCuponId hata!');
      })
    );
  }

  postGeneralRaportPopup(value: number): Observable<CuponDetail[]> {
    return this.http.post<CuponDetail[]>(endpoints.report.postGeneralReport.path + "?id=" + value,
      {headers: this.header}).pipe(
      map((data: CuponDetail[]) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('postGeneralRaportPopup hata!');
      })
    );
  }

  postGeneralRaportOpenSack(value: number): Observable<CuponDetail[]> {
    return this.http.post<CuponDetail[]>(endpoints.report.postGeneralReportOpenSack.path + "?id=" + value,
      {headers: this.header}).pipe(
      map((data: CuponDetail[]) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('postGeneralRaportPopup hata!');
      })
    );
  }

  postGeneralRaportMeterPopup(value: number, value2: number): Observable<CuponDetail[]> {
    return this.http.post<CuponDetail[]>(endpoints.report.postGeneralReportMeters.path + "?packageId=" + value + "&meter=" + value2,
      {headers: this.header}).pipe(
      map((data: CuponDetail[]) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('postGeneralRaportPopup hata!');
      })
    );
  }

  postChangeSackNumber(value: number, value2: number): Observable<CuponDetail[]> {
    return this.http.post<CuponDetail[]>(endpoints.report.postChangeSackNumber.path + "?sackId=" + value + "&number=" + value2,
      {headers: this.header}).pipe(
      map((data: CuponDetail[]) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('postGeneralRaportPopup hata!');
      })
    );
  }

  removeGeneralRaportPackage(value: number): Observable<CuponDetail[]> {
    return this.http.post<CuponDetail[]>(endpoints.report.removeGeneralReport.path + "?packageId=" + value,
      {headers: this.header}).pipe(
      map((data: CuponDetail[]) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('postGeneralRaportPopup hata!');
      })
    );
  }

  removeGeneralRaportSack(value: number): Observable<CuponDetail[]> {
    return this.http.post<CuponDetail[]>(endpoints.report.removeGeneralReportSack.path + "?sackId=" + value,
      {headers: this.header}).pipe(
      map((data: CuponDetail[]) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('postGeneralRaportPopup hata!');
      })
    );
  }

  removeGeneralRaportSuck(value: number, value2: number): Observable<CuponDetail[]> {
    return this.http.post<CuponDetail[]>(endpoints.report.removePackageFromSack.path + "?sackId=" + value + "&packageId=" + value2,
      {headers: this.header}).pipe(
      map((data: CuponDetail[]) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('postGeneralRaportPopup hata!');
      })
    );
  }

  removePackageFromSack(value: number, value2: number): Observable<CuponDetail[]> {
    return this.http.post<CuponDetail[]>(endpoints.report.removePackageFromSack.path + "?sackId=" + value + "&packageId=" + value2,
      {headers: this.header}).pipe(
      map((data: CuponDetail[]) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('postGeneralRaportPopup hata!');
      })
    );
  }

  postSacks(value: number, value2: number): Observable<CuponDetail[]> {
    return this.http.post<CuponDetail[]>(endpoints.report.postTransferSackAndCloseOldOne.path + "?sackId=" + value + "&transferedId=" + value2,
      {headers: this.header}).pipe(
      map((data: CuponDetail[]) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('postGeneralRaportPopup hata!');
      })
    );
  }

  updateSubDetailPrintInfoById(value: number[], value2: number): Observable<CuponDetail[]> {
    return this.http.post<CuponDetail[]>(endpoints.orderSubDetails.updateSubDetailPrintInfoById.path + "?machineId=" + value2, value,
      {headers: this.header}).pipe(
      map((data: CuponDetail[]) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('getCuponDetailByCuponId hata!');
      })
    );
  }

  getSubDetailCuponListById(value): Observable<Cupon[]> {
    return this.http.get(endpoints.orders.getSubDetailCuponListById.path,
      {headers: this.header, params: {id: value}}).pipe(
      map((data: Cupon[]) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('getSubDetailCuponListById hata!');
      })
    );
  }

  getSubOrders(value): Observable<subOrder[]> {
    return this.http.get(endpoints.orders.getSubOrders.path,
      {headers: this.header, params: {id: value}}).pipe(
      map((data: subOrder[]) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('getSubOrders hata!');
      })
    );
  }

  getMachines(): Observable<Machine[]> {
    return this.http.get(endpoints.orders.getMachines.path,
      {headers: this.header}).pipe(
      map((data: Machine[]) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('getMachines hata!');
      })
    );
  }

  getOrdersIsActiveById(id): Observable<any> {
    return this.http.post(endpoints.orders.getOrdersIsActiveById.path,
      id, {headers: this.header}).pipe(
      map((data: any) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('getOrdersIsActiveById hata!');
      })
    );
  }

  getOrdersDetailIsActiveById(id): Observable<any> {
    return this.http.post(endpoints.orderDetails.getOrdersDetailIsActiveById.path,
      null, {headers: this.header,params: {id: id}}).pipe(
      map((data: any) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('getOrdersIsActiveById hata!');
      })
    );
  }

  getTransferForComplete(id): Observable<any> {
    return this.http.post(endpoints.orders.transferOrder.path,
      null, {headers: this.header,params: {id: id}}).pipe(
      map((data: any) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('getTransferForComplete hata!');
      })
    );
  }

  getSubTransferForComplete(id): Observable<any> {
    return this.http.post(endpoints.orderDetails.transferSubOrder.path,
      null, {headers: this.header, params: {id: id}}).pipe(
      map((data: any) => {
        return data;
      }), catchError(error => {
        this.toastr.error(error, 'HATA', {
          timeOut: 3000
        });
        return throwError('getSubTransferForComplete hata!');
      })
    );
  }

  readFromLogo() {
    return this.http.get(endpoints.readFromLogo.path, {headers: this.header}).pipe(
      map((data) => {
        return data;
      }), catchError(error => {
        this.toastr.error('Beklenmedik bir hata ile karşılaşıldı', 'HATA', {
          timeOut: 3000
        });
        return throwError('readFromLogo hata!');
      })
    );
  }

  getSackId(value: number) {
    return this.http.get(endpoints.report.getPossibleSack.path + '?sackId=' + value, {headers: this.header}).pipe(
      map((data) => {
        return data;
      }), catchError(error => {
        this.toastr.error('Beklenmedik bir hata ile karşılaşıldı', 'HATA', {
          timeOut: 3000
        });
        return throwError('readFromLogo hata!');
      })
    );
  }
}
