import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {DyehouseProviders} from "../../_services/dyehouse.providers";
import {DevExpressProviders} from "../../_services/devexpress.providers";
import {servers} from "../../shared/configuration";
import {CheckBarcodeDTO} from "../../_models/CheckBarcodeDTO";
import {DryVechileDTOModel} from "../../_models/dyehouse/DryVechileDTO.model";
import {DryVehicleComponentModel} from "../../_models/DryVehicleComponent.model";
import {DryVehicleComponentDTOModel} from "../../_models/dyehouse/DryVehicleComponentDTO.model";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-search-vehicle-for-dyehouse',
  templateUrl: './search-vehicle-for-dyehouse.component.html',
  styleUrls: ['./search-vehicle-for-dyehouse.component.scss']
})
export class SearchVehicleForDyehouseComponent implements OnInit {
  @ViewChild('inp') private inp: ElementRef;
  checkBarcode: CheckBarcodeDTO = new CheckBarcodeDTO();
  companySource: any;
  contents: DryVehicleComponentModel[] = [];
  isSearched = false;
  companyId: number;

  constructor(
    private dyeApi: DyehouseProviders,
    private devApi: DevExpressProviders,
    private toastr: ToastrService
  ) {

    this.companySource = this.devApi.readForDevX('id', servers.real + '/clients/getOutgoingCompaniesDevx');
  }

  BarcodeAra(): void {
    this.dyeApi.searchVehicleForDyehouse(this.checkBarcode).subscribe(x => {
      this.isSearched = true;
      this.contents = x;
    })
  }

  gonder(): void {
    if (this.companyId != null && this.contents != null) {
      const data: DryVehicleComponentDTOModel = new DryVehicleComponentDTOModel();
      data.dryVehicleComponents = this.contents;
      data.clientId = this.companyId;
      this.dyeApi.outGoingVehiclesWithCar(data).subscribe(a => {
        this.toastr.success('Araç kapatılmıştır.');
        this.contents = [];
        this.checkBarcode.barcode = null;
        this.isSearched = false;
      });
    } else {
      this.toastr.warning('Lütfen boyahane firmasını seçiniz...')
    }
  }

  ngOnInit() {
    this.inp.nativeElement.focus();
  }

  onKeydown($event: KeyboardEvent) {
    // @ts-ignore
    if (event.key === "Enter") {
      this.BarcodeAra();
    }
  }

}
